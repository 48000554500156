import React from 'react';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core';
import Routes from './components/Routes';

const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: '#000413',
  },
}));

const theme = createTheme({
  root: {
    height: '100vh',
    backgroundColor: '#000413',
  },
  spacing: 4,
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    primary: {
      main: '#FF0155',
      dark: '#738292',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#5533FF',
      contrastText: '#000000',
    },
    grey: {
      main: '#738292',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
    ].join(','),
    h4: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      marginBottom: '1rem',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '700',
    },
    h6: {
      fontSize: '1.15rem',
      lineHeight: '1.375rem',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '700',
    },
  },
  button: {
    padding: '0.75rem 2rem',
    marginTop: '3rem',
    color: 'white',
    borderRadius: '4px',
  },
  buttonLabel: {
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
  },
});

theme.typography.h1 = {
  fontSize: '4.375rem',
  lineHeight: '4.063rem',
  marginBottom: '2rem',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: '800',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    lineHeight: '3rem',
  },
};
theme.typography.h2 = {
  fontSize: '3rem',
  lineHeight: '3.5rem',
  marginBottom: '2rem',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: '700',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
};
theme.typography.h3 = {
  fontSize: '2rem',
  lineHeight: '3rem',
  marginBottom: '2rem',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: '700',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    marginBottom: '0rem',
  },
};
theme.typography.h4 = {
  fontSize: '1.5rem',
  lineHeight: '2rem',
  marginBottom: '1rem',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: '700',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginBottom: '0rem',
  },
};
theme.typography.h5 = {
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: '700',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '1.75rem',
  },
};
theme.typography.body1 = {
  // color: 'white',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: '400',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
};
theme.typography.body2 = {
  // color: 'white',
  fontSize: '0.9rem',
  lineHeight: '1.4rem',
  fontFamily: 'Montserrat, sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  }
}

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Routes />
      </div>
    </ThemeProvider>
  );
}

export default App;
