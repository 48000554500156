import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import RedesSociais from '../pages/RedesSociais';
import TrafegoPago from '../pages/TrafegoPago';
import LancamentoSemDesculpas from '../pages/LancamentoSemDesculpas';
import NegociosLocais from '../pages/NegociosLocais';
import ProtocoloConcessionarias from '../pages/ProtocoloConcessionarias';
import Portfolio from '../pages/Portfolio';
import BrasilAutomoveis from '../pages/BrasilAutomoveis';
import Fhm from '../pages/Fhm';
import PvnVilar from '../pages/PvnVilar';
import Locafacil from '../pages/Locafacil';
import MoveSaude from '../pages/MoveSaude';
import Amparo from '../pages/Amparo';
import Alamo from '../pages/Alamo';
import Apvs from '../pages/Apvs';

const Rotas = () => (
   <BrowserRouter>
      <Switch>
         <Route component={Home} path='/' exact />
         <Route component={RedesSociais} path='/redes-sociais' />
         <Route component={TrafegoPago} path='/trafego-pago' />
         <Route component={LancamentoSemDesculpas} path='/lancamento-sem-desculpas' />
         <Route component={NegociosLocais} path='/negocios-locais' />
         <Route component={Portfolio} path='/portfolio' exact />
         <Route component={BrasilAutomoveis} path='/portfolio/brasil-automoveis' />
         <Route component={Fhm} path='/portfolio/fhm' />
         <Route component={PvnVilar} path='/portfolio/pvn' />
         <Route component={Locafacil} path='/portfolio/locafacil' />
         <Route component={MoveSaude} path='/portfolio/move-saude' />
         <Route component={Amparo} path='/portfolio/amparo' />
         <Route component={Alamo} path='/portfolio/alamo' />
         <Route component={Apvs} path='/portfolio/apvs' />
         <Route component={ProtocoloConcessionarias} path='/concessionarias' />
      </Switch>
   </BrowserRouter>
);

export default Rotas;