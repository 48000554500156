import React, { Component } from 'react';
import Slider from 'react-slick';
import { withStyles, Box } from '@material-ui/core';
import "../assets/styles/slick-artes.css"

import Arte1 from '../assets/amparo-arte-1.webp';
import Arte2 from '../assets/amparo-arte-2.webp';
import Arte3 from '../assets/amparo-arte-3.webp';
import Arte4 from '../assets/amparo-arte-4.webp';
import Arte5 from '../assets/amparo-arte-5.webp';
import Arte6 from '../assets/amparo-arte-6.webp';
import Arte7 from '../assets/amparo-arte-7.webp';
import Arte8 from '../assets/amparo-arte-8.webp';
import Arte9 from '../assets/amparo-arte-9.webp';
import Arte10 from '../assets/amparo-arte-10.webp';

const useStyles = theme => ({

});

class CarouselAmparo extends Component {
   render() {
      const settings = {
         className: "slider-artes",
         centerMode: true,
         dots: true,
         arrows: true,
         infinite: true,
         centerPadding: "60px",
         slidesToShow: 3,
         speed: 500,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2,
                  dots: false
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: false
               }
            }
         ]
      };
      // const { classes } = this.props;
      return (
         <Slider {...settings}>
            <Box px={4}><img src={Arte3} alt='Arte 3' width='100%' /></Box>
            <Box px={4}><img src={Arte1} alt='Arte 1' width='100%' /></Box>
            <Box px={4}><img src={Arte2} alt='Arte 2' width='100%' /></Box>
            <Box px={4}><img src={Arte4} alt='Arte 4' width='100%' /></Box>
            <Box px={4}><img src={Arte5} alt='Arte 5' width='100%' /></Box>
            <Box px={4}><img src={Arte6} alt='Arte 6' width='100%' /></Box>
            <Box px={4}><img src={Arte7} alt='Arte 7' width='100%' /></Box>
            <Box px={4}><img src={Arte8} alt='Arte 8' width='100%' /></Box>
            <Box px={4}><img src={Arte9} alt='Arte 9' width='100%' /></Box>
            <Box px={4}><img src={Arte10} alt='Arte 10' width='100%' /></Box>
         </Slider>
      );
   }
}
export default withStyles(useStyles)(CarouselAmparo);