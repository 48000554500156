import React from 'react';
import { Grid, makeStyles, Typography, Box, Container, Button } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import CardBorder from '../components/CardBorder';

// Images
import CwaVector from '../assets/cwa-vector-white.webp';
import Notebook from '../assets/fhm-note.webp';
import Site from '../assets/fhm-site.webp';
import Tour from '../assets/fhm-tour.webp';
// import Seo from '../assets/fhm-seo.webp';
// import Bg from '../assets/port-fhm.webp';


const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 0,
      backgroundColor: '#000413',
   },
   section: {
      paddingTop: '4rem',
      position: 'relative',
   },
   primary: {
      color: '#004281',
   },
   white: {
      color: theme.palette.common.white,
   },
   appBarColor: {
      background: 'linear-gradient(135deg, rgba(0,66,129,1) 0%, rgba(0,50,97,1) 50%, rgba(1,41,78,1) 75%, rgba(1,33,62,1) 100%);',
   },
   header: {
      background: 'linear-gradient(135deg, rgba(0,66,129,1) 0%, rgba(0,50,97,1) 50%, rgba(1,41,78,1) 75%, rgba(1,33,62,1) 100%);',
      minHeight: '500px',
      [theme.breakpoints.down('sm')]: {
         minHeight: '350px',
      },
   },
   bgVector: {
      backgroundImage: `url(${CwaVector})`,
      minHeight: '500px',
      [theme.breakpoints.down('sm')]: {
         minHeight: '350px',
      },
   },
   buttonOutline: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: '#5533FF',
      borderRadius: '4px',
      padding: '1rem',
      color: '#5533FF',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '1rem',
      '&:hover': {
         backgroundColor: '#5533FF',
         color: 'white',
         boxShadow: '0px 6px 30px #5533FF',
      }
   },
   buttonLink: {
      color: '#004281',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '1.15rem',
      padding: '0 0 0 5px',
   },
   highlighter: {
      color: '#004281',
      marginBottom: '0.5rem',
   },
   highlighter2: {
      color: '#004281',
      fontSize: '1.5rem',
      marginBottom: '1rem',
      lineHeight: '2rem',
   },
   classTop: {
      display: 'none',
   },
   customizeBox: {
      height: '120px !important',
   },
   box: {
      backgroundColor: '#fff',
      borderRadius: '4px',
      position: 'relative',
      height: '100%',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      textAlign: 'center',
   },
}));


export default function BrasilAutomoveis() {
   const classes = useStyles();
   return (
      <div className={classes.root}>
         <TopBar appBarColor={classes.appBarColor} />

         {/* Header */}
         <Box className={`${classes.header}`}>
            <Box className={`${classes.bgVector}`} px={{ xs: 4, md: 8 }} pt={{ xs: 36, md: 48 }}>
               <Container>
                  <Grid container alignItems='center' justifyContent='center' alignContent='center'>
                     <Grid item md='8'>
                        <Box color='white' textAlign={{ xs: 'center', sm: 'center', }} mb={4}>
                           <Typography component='h1' variant='h2' className={classes.white}>
                              Fundação Homem do Mar
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </Box>

         {/* Notebook */}
         <Grid container alignContent='center' justifyContent='center'>
            <Grid item>
               <Box mt={{ xs: -22, md: -48 }}>
                  <img src={Notebook} alt='Brasil Automóveis' width='100%' />
               </Box>
            </Grid>
         </Grid>

         {/* Sobre o projeto */}
         <Grid container>
            <Container>
               <Box pt={20} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 15 }} textAlign='left'>
                  <Grid container alignContent='center' direction='column'>
                     <Grid item md={6}>
                        <Typography component='h2' variant='h3' className={classes.white}>
                           <Box mb={2}>Sobre o projeto</Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={6}>
                        <Typography variant='body1' className={classes.white} gutterBottom='true'>
                           A Fundação Homem do Mar atua diretamente na qualificação de profissionais do setor aquaviário e
                           são uma referência global na consultoria e qualificação profissional no setor aquaviário e portuário.
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         {/* SEO / SEM / Marketing de Conteúdo */}
         <Grid container justifyContent='space-evenly' alignItems='center'>
            <Box pt={20} mt={{ xs: 0, lg: 20 }} px={{ xs: 6, md: 8 }} pb={8} mb={2} textAlign={{ xs: 'left', md: 'center' }} width='100%'>
               <Typography variant='h3' className={classes.white}>
                  SEO / SEM / Marketing de Conteúdo
               </Typography>
            </Box>
            <Grid item md={7}>
               <Box px={{ xs: 6, md: 0 }} mb={{ xs: 20, md: 0 }} width='100%'>
                  <Grid container alignContent='center' justifyContent='space-between' spacing={8}>
                     <CardBorder
                        col='6'
                        cardBorder={classes.cardBorder}
                        classTop={classes.classTop}
                        classHighlighter={classes.highlighter2}
                        highlighter='1º Lugar'
                        text='orgânico no Google em todos os cursos.'
                     />
                     <CardBorder
                        col='6'
                        cardBorder={classes.cardBorder}
                        classTop={classes.classTop}
                        classHighlighter={classes.highlighter2}
                        highlighter='+ acesso ao site'
                        text='Grande aumento no acesso ao site.'
                     />
                     <CardBorder
                        col='6'
                        cardBorder={classes.cardBorder}
                        classTop={classes.classTop}
                        classHighlighter={classes.highlighter2}
                        highlighter='Eficiência'
                        text='eficiência na conversão em vendas.'
                     />
                     <CardBorder
                        col='6'
                        cardBorder={classes.cardBorder}
                        classTop={classes.classTop}
                        classHighlighter={classes.highlighter2}
                        highlighter='Marketing de conteúdo'
                        text='resultando em mais envolvimento com nosso público-alvo.'
                     />
                  </Grid>
               </Box>
            </Grid>
            <Grid item md={3}>
               <Box px={{ xs: 6, md: 0 }} textAlign='center'>
                  <img src={Site} alt='SEO da Fundação Homem do Mar' width='100%' />
               </Box>
            </Grid>
         </Grid>

         {/* Tour Virtual */}
         <Grid container>
            <Box pt={{ xs: 36, md: 48 }} px={{ xs: 6, md: 8 }} pb={{ xs: 10, md: 15 }} textAlign='left'>
               <Grid container alignContent='center' direction='column'>
                  <Grid item md={6}>
                     <Typography component='h2' variant='h3' className={classes.white}>
                        <Box mb={2}>Tour Virtual interativo 360º</Box>
                     </Typography>
                  </Grid>
                  <Grid item md={6}>
                     <Typography variant='body1' className={classes.white} gutterBottom='true'>
                        <p>Especialmente idealizado por Oficiais da Marinha Mercante Brasileira, o Centro de Simulação Aquaviária - CSA,
                           é fundamental na qualificação profissional e pesquisas científicas para o setor aquaviário, sendo assim,
                           referenciado como um dos mais avançados centros de simulação no mundo.</p>
                        <p>Desenvolvemos um tour virtual dinâmico que possibilita total interação e entendimento das ferramentas
                           disponíveis aos alunos da Fundação Homem do Mar.</p>
                        <p>
                           Confira nosso trabalho do Tour virtual 
                           <Button
                              variant='container'
                              className={classes.buttonLink}
                              href='http://www.csaq.org.br/tour/'
                              target='_blank'>
                              Clicando aqui
                           </Button>
                        </p>
                     </Typography>
                  </Grid>
               </Grid>
            </Box>
            <Grid item>
               <Box mt={{ xs: 0, md: 20 }} textAlign='center'>
                  <img src={Tour} alt='Tour virtual da Fundação Homem do Mar' width='100%' />
               </Box>
            </Grid>
         </Grid>

         {/* Pronto para impulsionar suas vendas? */}
         <Grid container className={`${classes.section} ${classes.bg1}`}>
            <Container>
               <Box pt={36} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 25 }} textAlign={{ xs: 'left', md: 'center' }}>
                  <Grid container justifyContent='center' alignItems='center' direction='column'>
                     <Grid item md={8}>
                        <Typography variant='h4' className={classes.white}>
                           <Box mb={2}>Pronto para impulsionar suas vendas?</Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={4}>
                        <Typography variant='body2' className={classes.white} gutterBottom='true'>
                           <Box mb={8}>
                              Fale agora com um dos nossos especialistas
                              e nos conte sobre seu negócio.
                           </Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={5}>
                        <Button variant='outlined' className={classes.buttonOutline} href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>Começar agora</Button>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         <Footer />
      </div >
   );
}