import React from 'react';
import { Grid, makeStyles, Typography, Box, Container, Button } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';

//Images
import CwaVector from '../assets/cwa-vector-white.webp';
import ImageFHM from '../assets/port-fhm.webp';
import ImagePVN from '../assets/port-pvn.webp';
import ImageLocafacil from '../assets/port-locafacil.webp';
import ImageBrasil from '../assets/port-brasil.webp';
import ImageApvs from '../assets/port-apvs.webp';
import ImageMove from '../assets/port-move.webp';
import ImageAmparo from '../assets/port-amparo.webp';
import ImageAlamo from '../assets/port-alamo.webp';
// import ImageDayane from '../assets/port-dayane.webp';
// import ImageThuanne from '../assets/port-thuanne.webp';

//Logos
import LogoBrasil from '../assets/port-logo-brasil.webp';
import LogoPVN from '../assets/port-logo-pvn.webp';
import LogoFHM from '../assets/port-logo-fhm.webp';
import LogoLocafacil from '../assets/port-logo-locafacil.webp';
import LogoApvs from '../assets/port-logo-apvs.webp';
import LogoMove from '../assets/port-logo-move.webp';
import LogoAmparo from '../assets/port-logo-amparo.webp';
// import LogoAlamo from '../assets/port-logo-alamo.webp';
// import LogoDayane from '../assets/port-logo-dayane.webp';
// import LogoThuanne from '../assets/port-logo-thuanne.webp';

// Icons
import { ArrowRightAltOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 0,
      backgroundColor: '#000413',
   },
   primary: {
      color: '#5533FF',
   },
   white: {
      color: theme.palette.common.white,
   },
   appBarColor: {
      background: 'linear-gradient(135deg, rgba(85,51,255,1) 0%, rgba(76,46,229,1) 50%, rgba(71,43,216,1) 75%, rgba(66,40,201,1) 100%);',
   },
   header: {
      background: 'linear-gradient(135deg, rgba(85,51,255,1) 0%, rgba(76,46,229,1) 50%, rgba(71,43,216,1) 75%, rgba(66,40,201,1) 100%);',
      minHeight: '450px',
   },
   bgVector: {
      backgroundImage: `url(${CwaVector})`,
      minHeight: '450px',
   },
   tag: {
      color: '#fff',
      marginTop: '0.5rem',
      marginRight: '0.75rem',
      marginBottom: '0.5rem',
      padding: '0.5rem 1.5rem',
      border: '1px dashed #fff',
      borderRadius: '25px',
      fontSize: '0.75rem',
      display: 'inline-block',
      opacity: '0.85',
   },
   portBg: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '400px',
      position: 'relative',
   },
   portFHM: {
      backgroundImage: `url(${ImageFHM})`,
   },
   portPVN: {
      backgroundImage: `url(${ImagePVN})`,
      backgroundPosition: 'center 0 !important',
      [theme.breakpoints.down('sm')]: {
         backgroundPosition: 'center 0rem !important',
      },
   },
   portLocafacil: {
      backgroundImage: `url(${ImageLocafacil})`,
   },
   portBrasil: {
      backgroundImage: `url(${ImageBrasil})`,
      [theme.breakpoints.down('sm')]: {
         backgroundPosition: 'left 0rem !important',
      },
   },
   // portDayane: {
   //    backgroundImage: `url(${ImageDayane})`,
   //    backgroundPosition: 'center 0 !important',
   //    [theme.breakpoints.down('sm')]: {
   //       backgroundPosition: 'center 0rem !important',
   //    },
   // },
   // portThuanne: {
   //    backgroundImage: `url(${ImageThuanne})`,
   //    backgroundPosition: 'center 0 !important',
   //    [theme.breakpoints.down('sm')]: {
   //       backgroundPosition: 'center 0rem !important',
   //    },
   // },
   portApvs: {
      backgroundImage: `url(${ImageApvs})`,
      backgroundPosition: 'center 0rem !important',
   },
   portMove: {
      backgroundImage: `url(${ImageMove})`,
      backgroundPosition: 'center 0rem !important',
   },
   portAmparo: {
      backgroundImage: `url(${ImageAmparo})`,
      backgroundPosition: 'center 0rem !important',
   },
   portAlamo: {
      backgroundImage: `url(${ImageAlamo})`,
      backgroundPosition: 'center 0rem !important',
   },
   portGrid: {
      zIndex: 1,
      position: 'relative',
      height: '100%',
      minHeight: '400px',
   },
   buttonOutline: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: '#5533FF',
      borderRadius: '4px',
      padding: '1rem',
      color: '#5533FF',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '1rem',
      '&:hover': {
         backgroundColor: '#5533FF',
         color: 'white',
         boxShadow: '0px 6px 30px #5533FF',
      }
   },
   buttonNotline: {
      borderRadius: '4px',
      padding: '1rem',
      color: '#ffffff',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '1rem',
      '&:hover': {
         backgroundColor: '#FF0155',
         color: 'white',
         boxShadow: '0px 6px 30px #FF0155',
      }
   },
}));

export default function Portfolio() {
   const classes = useStyles();
   return (
      <div className={classes.root}>
         <TopBar appBarColor={classes.appBarColor} />

         {/* Header */}
         <Box className={`${classes.header}`}>
            <Box className={`${classes.bgVector}`} px={{ xs: 4, md: 8 }} pt={48}>
               <Container>
                  <Grid container alignItems='center' justifyContent='center' alignContent='center'>
                     <Grid item md='9'>
                        <Box color='white' textAlign={{ xs: 'left', sm: 'center', }} mb={4}>
                           <Typography variant='h3' className={classes.white}>
                              O sucesso do seu negócio é a nossa maior publicidade.
                           </Typography>
                        </Box>
                        <Box color='white' textAlign={{ xs: 'left', sm: 'center', }}>
                           <Typography variant='body1' className={classes.white}>
                              Conheça alguns dos nossos projetos.
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </Box>

         {/* Cases */}

         {/* Brasil Automóveis */}
         <Box className={`${classes.portBg} ${classes.portBrasil}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={4} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{ xs: 'center', md: 'left' }}>
                        <a href='/portfolio/brasil-automoveis'>
                           <img src={LogoBrasil} alt='Brasil Automóveis' width='180px' />
                        </a>
                     </Box>
                  </Grid>
                  <Grid container md={4} justifyContent='flex-end'>
                     <Box width='100%' textAlign={{ xs: 'center', md: 'right' }} mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }}>
                        <span className={classes.tag}>Redes sociais</span>
                        <span className={classes.tag}>Gestão de Tráfego</span>
                        <span className={classes.tag}>Identidade visual</span>
                        <span className={classes.tag}>Site</span>
                        <span className={classes.tag}>Vídeos</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs: 'center', md: 'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/brasil-automoveis'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box>

         {/* PVN */}
         <Box className={`${classes.portBg} ${classes.portPVN}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={5} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{ xs: 'center', md: 'left' }}>
                        <a href='/portfolio/pvn'>
                           <img src={LogoPVN} alt='Projeto Vida Nova de Vilar' width='220px' />
                        </a>
                     </Box>
                  </Grid>
                  <Grid container md={4} justifyContent='flex-end'>
                     <Box width='100%' textAlign={{ xs: 'center', md: 'right' }} mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }}>
                        <span className={classes.tag}>Eventos</span>
                        <span className={classes.tag}>Vídeos</span>
                        <span className={classes.tag}>Site</span>
                        <span className={classes.tag}>Redes sociais</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs: 'center', md: 'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/pvn'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box>

         {/* FHM */}
         <Box className={`${classes.portBg} ${classes.portFHM}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={5} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{ xs: 'center', md: 'left' }}>
                        <a href='/portfolio/fhm'>
                           <img src={LogoFHM} alt='Fundação Homem do Mar' width='100px' />
                        </a>
                     </Box>
                  </Grid>
                  <Grid container md={5} justifyContent='flex-end'>
                     <Box width='100%' textAlign={{ xs: 'center', md: 'right' }} mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }}>
                        <span className={classes.tag}>Site</span>
                        <span className={classes.tag}>Tour Virtual</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs: 'center', md: 'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/fhm'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box>

         {/* Locafacil */}
         <Box className={`${classes.portBg} ${classes.portLocafacil}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={5} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{ xs: 'center', md: 'left' }}>
                        <a href='/portfolio/locafacil'>
                           <img src={LogoLocafacil} alt='Locafacil Aluguel de veículos' width='180px' />
                        </a>
                     </Box>
                  </Grid>
                  <Grid container md={4} justifyContent='flex-end'>
                     <Box width='100%' textAlign={{ xs: 'center', md: 'right' }} mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }}>
                        <span className={classes.tag}>Redes sociais</span>
                        <span className={classes.tag}>Gestão de Tráfego</span>
                        <span className={classes.tag}>Identidade Visual</span>
                        <span className={classes.tag}>Site</span>
                        <span className={classes.tag}>Vídeos</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs: 'center', md: 'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/locafacil'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box>

         {/* APVS */}
         <Box className={`${classes.portBg} ${classes.portApvs}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={5} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{xs:'center', md:'left'}}>
                        <img src={LogoApvs} alt='APVS' width='90px' />
                     </Box>
                  </Grid>
                  <Grid container md={4} justifyContent='flex-end'>
                     <Box width='100%' textAlign='right' mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }} display={{ xs: 'none', md: 'block' }}>
                        <span className={classes.tag}>Redes sociais</span>
                        <span className={classes.tag}>Impressos</span>
                        <span className={classes.tag}>Site</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs:'center', md:'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/apvs'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box>
         
         {/* Move Saúde */}
         <Box className={`${classes.portBg} ${classes.portMove}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={5} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{xs:'center', md:'left'}}>
                        <img src={LogoMove} alt='Move Saúde' width='180px' />
                     </Box>
                  </Grid>
                  <Grid container md={4} justifyContent='flex-end'>
                     <Box width='100%' textAlign='right' mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }} display={{ xs: 'none', md: 'block' }}>
                        <span className={classes.tag}>Redes sociais</span>
                        <span className={classes.tag}>Impressos</span>
                        <span className={classes.tag}>Site</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs:'center', md:'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/move-saude'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box>
         
         {/* Amparo Seguros */}
         <Box className={`${classes.portBg} ${classes.portAmparo}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={5} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{xs:'center', md:'left'}}>
                        <img src={LogoAmparo} alt='Amparo Seguros' width='180px' />
                     </Box>
                  </Grid>
                  <Grid container md={4} justifyContent='flex-end'>
                     <Box width='100%' textAlign='right' mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }} display={{ xs: 'none', md: 'block' }}>
                        <span className={classes.tag}>Redes sociais</span>
                        <span className={classes.tag}>Impressos</span>
                        <span className={classes.tag}>Site</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs:'center', md:'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/amparo'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box>
         
         {/* Alamo */}
         {/* <Box className={`${classes.portBg} ${classes.portAlamo}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={5} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{xs:'center', md:'left'}}>
                        <img src={LogoAlamo} alt='Alamo Proteção Veicular' width='180px' />
                     </Box>
                  </Grid>
                  <Grid container md={4} justifyContent='flex-end'>
                     <Box width='100%' textAlign='right' mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }} display={{ xs: 'none', md: 'block' }}>
                        <span className={classes.tag}>Redes sociais</span>
                        <span className={classes.tag}>Impressos</span>
                        <span className={classes.tag}>Site</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs:'center', md:'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/alamo'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box> */}

         {/* Dayane Vitório Semijoias */}
         {/* <Box className={`${classes.portBg} ${classes.portDayane}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={5} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{xs:'center', md:'left'}}>
                        <img src={LogoDayane} alt='Dayane Vitório Semijoias' width='180px' />
                     </Box>
                  </Grid>
                  <Grid container md={4} justifyContent='flex-end'>
                     <Box width='100%' textAlign='right' mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }} display={{ xs: 'none', md: 'block' }}>
                        <span className={classes.tag}>E-commerce</span>
                        <span className={classes.tag}>Redes sociais</span>
                        <span className={classes.tag}>Identidade Visual</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs:'center', md:'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/dayane'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box> */}

         {/* Thuanne Rodrigues */}
         {/* <Box className={`${classes.portBg} ${classes.portThuanne}`}>
            <Box px={{ xs: 8, md: 20 }}>
               <Grid container className={classes.portGrid} alignItems='center' alignContent='space-around' justifyContent='space-between'>
                  <Grid item md={5} xs={12}>
                     <Box color='white' mb={{ xs: 2, md: 8 }} px={{ xs: 6, md: 0 }} pt={{ xs: 8, md: 0 }} textAlign={{xs:'center', md:'left'}}>
                        <img src={LogoThuanne} alt='Thuanne Rodrigues' width='220px' />
                     </Box>
                  </Grid>
                  <Grid container md={4} justifyContent='flex-end'>
                     <Box width='100%' textAlign='right' mb={4} px={{ xs: 6, md: 4 }} pt={{ xs: 2, md: 0 }} display={{ xs: 'none', md: 'block' }}>
                        <span className={classes.tag}>Lançamentos</span>
                        <span className={classes.tag}>Cursos</span>
                        <span className={classes.tag}>Vídeos</span>
                     </Box>
                     <Box mb={{ xs: 10, md: 4 }} mt={{ xs: 0, md: 0 }} px={{ xs: 0, md: 0 }} mx={{ xs: 'auto', md: '0' }} width='100%' textAlign={{ xs:'center', md:'right' }}>
                        <Button
                           variant='container'
                           className={classes.buttonNotline}
                           href='/portfolio/thuanne'>
                           Ver case completo <Box compontent='span' display='flex' ml={3} ><ArrowRightAltOutlined /></Box>
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box> */}

         {/* Divulgue seus serviços e produtos para a pessoa certa */}
         <Grid container className={`${classes.section} ${classes.bg1}`}>
            <Container>
               <Box pt={25} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 25 }} textAlign={{ xs: 'left', md: 'center' }}>
                  <Grid container justifyContent='center' alignItems='center' direction='column'>
                     <Grid item md={8}>
                        <Typography variant='h4' className={classes.white}>
                           <Box mb={2}>Pronto para impulsionar suas vendas?</Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={5}>
                        <Typography variant='body2' className={classes.white} gutterBottom='true'>
                           <Box mb={8}>
                              Fale agora com um dos nossos especialistas
                              e nos conte sobre seu negócio.
                           </Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={5}>
                        <Button variant='outlined' className={classes.buttonOutline} href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>Começar agora</Button>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         <Footer />
      </div >
   );
}

