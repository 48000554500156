import React, { Component } from 'react';
import Slider from 'react-slick';
import { withStyles, Box } from '@material-ui/core';
import "../assets/styles/slick-artes.css"

import Arte1 from '../assets/rs-arte-1.webp';
import Arte3 from '../assets/rs-arte-3.webp';
import Arte4 from '../assets/rs-arte-4.webp';
import Arte5 from '../assets/rs-arte-5.webp';
import Arte6 from '../assets/rs-arte-6.webp';
import Arte7 from '../assets/rs-arte-7.webp';
import Arte8 from '../assets/rs-arte-8.webp';
import Arte11 from '../assets/rs-arte-11.webp';
import Arte12 from '../assets/rs-arte-12.webp';
import Arte13 from '../assets/rs-arte-13.webp';
import Arte14 from '../assets/rs-arte-14.webp';
import Arte15 from '../assets/rs-arte-15.webp';

const useStyles = theme => ({

});

class CarouselArtes extends Component {
   render() {
      const settings = {
         className: "slider-artes",
         centerMode: true,
         dots: true,
         arrows: true,
         infinite: true,
         centerPadding: "60px",
         slidesToShow: 3,
         speed: 500,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2,
                  dots: false
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: false
               }
            }
         ]
      };
      // const { classes } = this.props;
      return (
         <Slider {...settings}>
            <Box px={4}><img src={Arte1} alt='Arte 1' width='100%' /></Box>                    
            <Box px={4}><img src={Arte3} alt='Arte 3' width='100%' /></Box>            
            <Box px={4}><img src={Arte4} alt='Arte 4' width='100%' /></Box>            
            <Box px={4}><img src={Arte5} alt='Arte 5' width='100%' /></Box>            
            <Box px={4}><img src={Arte6} alt='Arte 6' width='100%' /></Box>            
            <Box px={4}><img src={Arte7} alt='Arte 7' width='100%' /></Box>            
            <Box px={4}><img src={Arte8} alt='Arte 8' width='100%' /></Box>            
            <Box px={4}><img src={Arte11} alt='Arte 11' width='100%' /></Box>            
            <Box px={4}><img src={Arte12} alt='Arte 12' width='100%' /></Box>            
            <Box px={4}><img src={Arte13} alt='Arte 13' width='100%' /></Box>            
            <Box px={4}><img src={Arte14} alt='Arte 14' width='100%' /></Box>            
            <Box px={4}><img src={Arte15} alt='Arte 15' width='100%' /></Box>            
         </Slider>
      );
   }
}
export default withStyles(useStyles)(CarouselArtes);