import React from 'react';
import Lottie from 'lottie-react';
import { Button, makeStyles, Typography, Container, Grid, Box } from '@material-ui/core';
import Mouse from '../assets/animations/mouse.json';

const useStyles = makeStyles(theme => ({
   section: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left bottom',
      backgroundColor: '#000413',
      padding: '1rem 0 0rem 0',
      [theme.breakpoints.down('sm')]: {
         padding: '0rem 0 4rem 0',
      },
   },
   container: {
      height: '100vh',
      zIndex: '1',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   button: {
      padding: '0.75rem 4rem',
      marginTop: '3rem',
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
         padding: '0.75rem 1.5rem',
         marginTop: '1rem',
      },
   },
   buttonLabel: {
      textTransform: 'none',
      fontWeight: 'bold',
   },
   mouse: {
      width: '45px',
   },
}));

function Header(props) {
   const classes = useStyles();
   return (
      <Grid className={`${classes.section} ${props.bgVector}`}>
         <Container maxWidth="xl">
            <Box mx={{ xs: 4, md: 12 }}>
               <Grid container className={classes.container} direction='row' justifyContent='space-between' alignItems='center' alignContent='center'>
                  <Grid item lg='5' className={props.classBoxText}>
                     <Box mt={{ xs: 12, md: 0 }} pl={{ xs: 4 }} pr={{ xs: 4, md: 18 }}>
                        <Typography variant='h1'>
                           <Box color='white' className={props.classTitle}>{props.title}</Box>
                           <span className={props.classTitleFontReverse}>{props.titleFontReverse}</span>
                        </Typography>
                        <Typography variant='h2' className={props.classSubtitle}>
                           <Box color='white'>{props.subtitle}</Box>
                        </Typography>
                        <Typography variant='body1'>
                           <Box color='white' pb={{ xs: 4 }}>{props.sup}</Box>
                        </Typography>
                        <Typography variant='body1' className={props.classDescription}>
                           <Box color='white' pb={{ xs: 4 }}>{props.description}</Box>
                        </Typography>
                        <Typography variant='body1'>
                           <Box color='white' pb={{ xs: 4 }}>{props.sub}</Box>
                        </Typography>
                        <Button className={props.button} color='secondary' href={props.linkButton}
                           classes={{
                              root: classes.button,
                              label: classes.buttonLabel,
                           }}>{props.buttonLabel}
                        </Button>
                     </Box>
                  </Grid>
                  <Grid item lg='7' className={props.classBoxImage}>
                     <Box mt={{ xs: 16, md: 0 }} pl={4} pr={{ xs: 4, md: 0 }}>
                        <img src={props.image} alt={props.imageAlt} className={props.classImage} width='100%' p={{ xs: '2rem' }} />
                     </Box>
                  </Grid>
                  <Grid item xs='12' md='12'>
                     <Box className={classes.mouse} mt={{ xs: 8, md: 16 }} mb={{ xs: 8, md: 16 }} mx='auto'>
                        <Lottie animationData={Mouse} />
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>
         <Box className={props.bgAfter}></Box>
      </Grid>
   );
}

export default Header;