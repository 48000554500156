import React from 'react';
import Lottie from 'lottie-react';
import { Button, makeStyles, Typography, Container, Grid, Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
   section: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left bottom',
      backgroundColor: '#000413',
   },
   container: {
      minHeight: '100vh',
      paddingTop: '96px',
   },
   button: {
      padding: '0.75rem 4rem',
      marginTop: '3rem',
      color: 'white',
      borderRadius: '4px',
   },
   buttonLabel: {
      textTransform: 'none',
      fontWeight: 'bold',
   },
}));

function Header(props) {
   const classes = useStyles();
   return (
      <Grid className={`${classes.section} ${props.bgVector}`}>
         <Container maxWidth="xl">
            <Box mx={{ xs: 4, md: 12 }}>
               <Grid container className={classes.container} direction='row' justifyContent='space-between' alignItems='center' alignContent='center'>
                  <Grid item lg='5' className={props.classBoxText}>
                     <Box mt={{ xs: 12, md: 0 }} pl={{ xs:4 }} pr={{ xs:4, md:18 }}>
                        <Typography variant='h1'>
                           <Box color='white' className={props.classTitle}>{props.title}</Box>
                           <span className={props.classTitleFontReverse}>{props.titleFontReverse}</span>
                        </Typography>
                        <Typography variant='h2' className={props.classSubtitle}>
                           <Box color='white'>{props.subtitle}</Box>
                        </Typography>
                        <Typography variant='body1'>
                           <Box color='white' pb={{ xs:4 }}>{props.sup}</Box>
                        </Typography>
                        <Typography variant='body1' className={props.classDescription}>                        
                           <Box color='white' pb={{ xs:4 }}>{props.description}</Box>
                        </Typography>
                        <Typography variant='body1'>
                           <Box color='white' pb={{ xs:4 }}>{props.sub}</Box>
                        </Typography>
                        <Button className={props.button} color='secondary' href={props.linkButton}
                           classes={{
                              root: classes.button,
                              label: classes.buttonLabel,
                           }}>{props.buttonLabel}
                        </Button>
                     </Box>
                  </Grid>
                  <Grid item lg='7' className={props.classBoxImage}>
                     <Box mt={{ xs: 16, md: 0 }} pl={4} pr={{ xs: 4, md: 0 }}>
                        <img src={props.image} alt={props.imageAlt} className={props.classImage} width='100%' p={{xs:'2rem'}} />
                        <Lottie animationData={props.animation} />
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>
      </Grid>
   );
}

export default Header;