import React, { Component } from 'react';
import Slider from 'react-slick';
import { withStyles, Box } from '@material-ui/core';
import "../assets/styles/slick-artes.css"

const useStyles = theme => ({

});

class CarouselBrasil extends Component {
   render() {
      const settings = {
         className: "slider-video",
         centerMode: false,
         dots: true,
         arrows: false,
         infinite: true,
         centerPadding: "60px",
         slidesToShow: 1,
         speed: 500,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true
               }
            }
         ]
      };
      return (
         <Slider {...settings}>
            <Box px={4}>
               <iframe width="100%" src="https://www.youtube.com/embed/MuyjB-NB1ss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
            </Box>
            <Box px={4}>
               <iframe width="100%" src="https://www.youtube.com/embed/FkMj7-Zu64k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Box>
            <Box px={4}>
               <iframe width="100%" src="https://www.youtube.com/embed/zX59cLlxakw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Box>
         </Slider>
      );
   }
}
export default withStyles(useStyles)(CarouselBrasil);