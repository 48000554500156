import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, Grid, Box, Typography, Tabs, Tab } from '@material-ui/core';

import ImageSites from '../assets/h-sites.webp';
import ImageDesign from '../assets/h-design.webp';
import ImageVideos from '../assets/h-videos.webp';
import ImageTrafego from '../assets/h-trafego.webp';
import ImageInbound from '../assets/h-inbound.webp';
import ImageRedesSociais from '../assets/h-redes-sociais.webp';

const useStyles = makeStyles((theme) => ({
   tabAppBar: {
      color: '#fff',
      backgroundColor: 'transparent',
   },
   tag: {
      color: '#738292',
      marginRight: '0.75rem',
      marginBottom: '1rem',
      padding: '0.5rem 1.5rem',
      border: '1px dashed #738292',
      borderRadius: '25px',
      fontSize: '0.85rem',
      display: 'inline-block',
   },
   primary: {
      color: theme.palette.primary.main,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   tabPanel: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
         marginLeft: theme.spacing(0),
         marginRight: theme.spacing(0),
      }
   }
}));

// Tabs dos Serviços
function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-auto-tabpanel-${index}`}
         aria-labelledby={`scrollable-auto-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box py={3}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}
TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
};
function a11yProps(index) {
   return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
   };
}
const StyledTabs = withStyles((theme) => ({
   indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
         //  maxWidth: 40,
         width: '100%',
         backgroundColor: theme.palette.primary.main,
      },
   },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
   root: {
      textTransform: 'none',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(18),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
      borderBottom: '1px dashed #738292',
      // opacity: 0.85,
      '&:hover': {
         borderWidth: '1px',
         borderStyled: 'solid',
         borderColor: theme.palette.primary.main,
      },
      '&:focus, &:active': {
         opacity: 1,
      },
      [theme.breakpoints.down('sm')]: {
         padding: '0 2rem 1.5rem 2rem',
      },
   },
}))((props) => <Tab disableRipple {...props} />);

function TabServicos() {
   const classes = useStyles();

   // Tabs dos serviços
   const [value, setValue] = React.useState(0);
   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <div>
         <Box className={classes.tabAppBar} my={16}>
            <StyledTabs
               value={value}
               onChange={handleChange}
               indicatorColor="primary"
               textColor="primary"
               variant="scrollable"
               scrollButtons="on"
               aria-label="scrollable auto tabs"
            >
               <StyledTab label="Site" {...a11yProps(0)} />
               <StyledTab label="Design" {...a11yProps(1)} />
               <StyledTab label="Redes sociais" {...a11yProps(2)} />
               <StyledTab label="Tráfego" {...a11yProps(3)} />
               <StyledTab label="Marketing" {...a11yProps(4)} />
               <StyledTab label="EAD" {...a11yProps(5)} />
            </StyledTabs>
         </Box>

         {/* Desenvolvimento */}
         <TabPanel value={value} className={classes.tabPanel} index={0}>
            <Grid container alignItems='center' justifyContent='space-between'>
               <Grid item sm='12' md='6'>
                  <Grid container justifyContent='center'>
                     <img src={ImageSites} alt='Imagem Sites' width='100%' />
                  </Grid>
               </Grid>
               <Grid item sm='12' md='5'>
                  <Box color='white' mt={{ xs: 8, sm: 8 }} mb={8}>
                     <Typography variant='h4' className={classes.white}>
                        Desenvolvimento de Sites, E-Commerce e Landing Pages
                     </Typography>
                  </Box>
                  <Box color='white' mb={4}>
                     <Typography variant='body2'>
                        <Box mb={4}>
                           Desenvolvemos o seu site do zero, do layout às linhas de código.
                           Nada de templates prontos ou construtores que, hora ou outra,
                           poderão te deixar na mão.
                           <Typography variant='h6' component="span" className={classes.secondary}> Nós temos programadores. </Typography>
                        </Box>
                        <Box mb={4}>
                           Na hora de criar seu site é preciso entender que alguns fatores são fundamentais para que ele obtenha a
                           <Typography variant='h6' component="span" className={classes.secondary}> melhor performance nos mecanismos
                              de buscas, nas vendas e anúncios. </Typography>
                           Nossos desenvolvedores pensam em todos os detalhes
                           a cada linha de código. Nossos designers desenham o layout perfeito para sua marca e nosso Marketing vai
                           te assessorar nos melhores conteúdos para você crescer na web.
                        </Box>
                     </Typography>
                  </Box>
                  <Box mt={8}>
                     <span className={classes.tag}>Wordpress</span>
                     <span className={classes.tag}>UX/UI</span>
                     <span className={classes.tag}>SEO</span>
                     <span className={classes.tag}>JavaScript</span>
                     <span className={classes.tag}>Mobile</span>
                  </Box>
               </Grid>
            </Grid>
         </TabPanel>

         {/* Design e Identidade Visual */}
         <TabPanel value={value} className={classes.tabPanel} index={1}>
            <Grid container alignItems='center' justifyContent='space-between'>
               <Grid item md='6'>
                  <Grid container justifyContent='center'>
                     <img src={ImageDesign} alt='Imagem Design' width='100%' />
                  </Grid>
               </Grid>
               <Grid item md='5'>
                  <Box color='white' mt={{ xs: 8, md: 8 }} mb={8}>
                     <Typography variant='h4' className={classes.white}>
                        Design e Identidade Visual
                     </Typography>
                  </Box>
                  <Box color='white' mb={4}>
                     <Typography variant='body2'>
                        <Box mb={4}>
                           Todos nós somos bombardeados diariamente por milhares de anúncios, ofertas e propagandas dos mais variados tipos.
                           Para nos destacarmos em meio a elas 
                           <Typography variant='h6' component="span" className={classes.secondary}> precisamos unir beleza à 
                           funcionalidade - é aqui que começamos a falar sobre design. </Typography>
                        </Box>
                        <Box mb={4}>
                           Elemento fundamental em cada tomada de decisão do cliente, o design une ainda experiência
                           à usabilidade para tornar toda a jornada de consumo do seu público ainda mais natural e fluida. 
                           <Typography variant='h6' component="span" className={classes.secondary}> Beleza é fundamental, e colocar
                            seu design em primeiro lugar é o primeiro passo para por sua empresa no topo. </Typography>
                        </Box>
                     </Typography>
                  </Box>
                  <Box mt={8}>
                     <span className={classes.tag}>Photoshop</span>
                     <span className={classes.tag}>Illustrator</span>
                     <span className={classes.tag}>UX/UI</span>
                     <span className={classes.tag}>Projeto</span>
                  </Box>
               </Grid>
            </Grid>
         </TabPanel>

         {/* Gestão de Redes Sociais */}
         <TabPanel value={value} className={classes.tabPanel} index={2}>
            <Grid container alignItems='center' justifyContent='space-between'>
               <Grid item md='6'>
                  <Grid container justifyContent='center'>
                     <img src={ImageRedesSociais} alt='Imagem Redes Sociais' width='100%' />
                  </Grid>
               </Grid>
               <Grid item md='5'>
                  <Box color='white' mt={{ xs: 8, md: 8 }} mb={8}>
                     <Typography variant='h4' className={classes.white}>
                        Gestão de Redes Sociais
                     </Typography>
                  </Box>
                  <Box color='white' mb={4}>
                     <Typography variant='body2'>
                        Seus clientes passam mais de
                        <Typography variant='h6' component="span" className={classes.secondary}> 3 horas por dia nas redes sociais. </Typography>
                        A forma como você se comunica e relaciona com eles por lá faz toda a diferença nos seus resultados.
                        Desenvolvemos toda a estratégia de comunicação e conteúdo para sua marca nas redes sociais, gerando
                        relacionamento, engajamento e com seus clientes e trazendo resultados expressivos para o seu negócio. </Typography>
                  </Box>
                  <Box mt={8}>
                     <span className={classes.tag}>InBound</span>
                     <span className={classes.tag}>Instagram</span>
                     <span className={classes.tag}>Facebook</span>
                     <span className={classes.tag}>Linkedin</span>
                     <span className={classes.tag}>Pinterest</span>
                     <span className={classes.tag}>Anúncios</span>
                  </Box>
               </Grid>
            </Grid>
         </TabPanel>

         {/* Gestão de tráfego */}
         <TabPanel value={value} className={classes.tabPanel} index={3}>
            <Grid container alignItems='center' justifyContent='space-between'>
               <Grid item md='6'>
                  <Grid container justifyContent='center'>
                     <img src={ImageTrafego} alt='Imagem Tráfego' width='100%' />
                  </Grid>
               </Grid>
               <Grid item md='5'>
                  <Box color='white' mt={{ xs: 8, md: 8 }} mb={8}>
                     <Typography variant='h4' className={classes.white}>
                        Gestão de tráfego
                     </Typography>
                  </Box>
                  <Box color='white' mb={4}>
                     <Box mb={4}>
                        <Typography variant='body2'>
                           Anunciar na internet é a forma mais eficaz para acelerar os seus resultados.
                           A gestão de tráfego tem o poder de entregar os anúncios, conteúdos e ofertas certas,
                           para as pessoas certas no momento certo.
                           <Typography variant='h6' component="span" className={classes.secondary}> Os resultados da gestão de tráfego 
                           pago vão além das vendas e leads, você ganha um ativo ainda mais importante 
                           sobre seu potencial cliente - informação. </Typography>
                        </Typography>
                     </Box>
                     <Typography variant='body2'>
                        Fale com a gente e desenvolveremos a estratégia perfeita para seu negócio crescer na web.
                     </Typography>
                  </Box>
                  <Box mt={8}>
                     <span className={classes.tag}>Google Ads</span>
                     <span className={classes.tag}>Facebook Ads</span>
                     <span className={classes.tag}>Linkedin Ads</span>
                     <span className={classes.tag}>Leads</span>
                     <span className={classes.tag}>Conversão</span>
                     <span className={classes.tag}>CPM</span>
                     <span className={classes.tag}>CTR</span>
                  </Box>
               </Grid>
            </Grid>
         </TabPanel>

         {/* Consultoria em Marketing */}
         <TabPanel value={value} className={classes.tabPanel} index={4}>
            <Grid container alignItems='center' justifyContent='space-between'>
               <Grid item md='6'>
                  <Grid container justifyContent='center'>
                     <img src={ImageInbound} alt='Imagem Inbound Marketing' width='100%' />
                  </Grid>
               </Grid>
               <Grid item md='5'>
                  <Box color='white' mt={{ xs: 8, md: 8 }} mb={8}>
                     <Typography variant='h4' className={classes.white}>
                        Consultoria em Marketing
                     </Typography>
                  </Box>
                  <Box color='white' mb={4}>
                     <Typography variant='body2'>
                        Nossos especialistas estarão ao seu lado lhes auxiliando na transformação digital da sua empresa.
                        São mais de 10 anos e centenas de projetos desenvolvidos que nos dão o
                        entendimento perfeito sobre cada cenário. Tudo para
                        <Typography variant='h6' component="span" className={classes.secondary}> analisar e validar suas 
                        estratégias sempre visando o ROI mais alto </Typography>
                        nos ambientes digitais.
                     </Typography>
                  </Box>
                  <Box mt={8}>
                     <span className={classes.tag}>Estratégia</span>
                     <span className={classes.tag}>Planejamento</span>
                     <span className={classes.tag}>Persona</span>
                     <span className={classes.tag}>Analytics</span>
                     <span className={classes.tag}>Growth</span>
                  </Box>
               </Grid>
            </Grid>
         </TabPanel>

         {/* Plataforma EAD */}
         <TabPanel value={value} className={classes.tabPanel} index={5}>
            <Grid container alignItems='center' justifyContent='space-between'>
               <Grid item md='6'>
                  <Grid container justifyContent='center'>
                     <img src={ImageVideos} alt='Imagem Videos' width='100%' />
                  </Grid>
               </Grid>
               <Grid item md='5'>
                  <Box color='white' mt={{ xs: 8, md: 8 }} mb={8}>
                     <Typography variant='h4' className={classes.white}>
                        Plataforma EAD
                     </Typography>
                  </Box>
                  <Box color='white' mb={4}>
                     <Box mb={4}>
                        <Typography variant='body2'>
                           Uma plataforma de ensino à distância é uma solução incrível não apenas para instituições de ensino,
                           mas também para empresas ministrarem seus treinamentos e manterem seus profissionais sempre atualizados.
                           <Typography variant='h6' component="span" className={classes.secondary}> Desenvolvemos uma Plataforma EAD 
                           na medida da sua necessidade e respeitando as peculiaridades do seu negócio. </Typography>
                        </Typography>
                     </Box>
                     <Typography variant='body2'>
                        Fale com a gente e saiba mais sobre nossas soluções.
                     </Typography>
                  </Box>
                  <Box mt={8}>
                     <span className={classes.tag}>Educação</span>
                     <span className={classes.tag}>Treinamento</span>
                     <span className={classes.tag}>Lançamentos</span>
                  </Box>
               </Grid>
            </Grid>
         </TabPanel>
         {/* <TabPanel value={value} className={classes.tabPanel} index={6}>
            <Grid container alignItems='center' justifyContent='space-between'>
               <Grid item md='6'>
                  <Grid container justifyContent='center'>
                     <img src={ImageVideos} alt='Imagem Vídeos' width='100%' />
                  </Grid>
               </Grid>
               <Grid item md='5'>
                  <Box color='white' mt={{ xs: 8, md: 8 }} mb={8}>
                     <Typography variant='h4' className={classes.white}>
                        Produção e edição de vídeos
                     </Typography>
                  </Box>
                  <Box color='white' mb={4}>
                     <Typography variant='body2'>
                        Um planejamento de marketing completo abrange todos os canais de comunicação e, neste caso, os vídeos merecem um cuidado especial.
                        Em 2020, 85% de todo o tráfego na internet foi por consumo de vídeos.
                        91% dos consumidores já assistiram algum vídeo explicativo sobre um produto ou serviço.
                        84% dos consumidores já foram convencidos a comprar um produto através de um vídeo. Vídeos vendem!
                     </Typography>
                  </Box>
               </Grid>
            </Grid>
         </TabPanel> */}
      </div>

   );
}

export default TabServicos;