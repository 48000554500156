import React from 'react';
import { Grid, makeStyles, Typography, Box, Container, Button } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import CarouselLocafacil from '../components/CarouselLocafacil';
import CarouselLocafacilVideos from '../components/CarouselLocafacilVideos';
import CardBorder from '../components/CardBorder';

// Images
import CwaVector from '../assets/cwa-vector-white.webp';
import Notebook from '../assets/loc-note.webp';
import Bg from '../assets/port-locafacil.webp';
import LogoAntes from '../assets/loc-logo-antes.webp';
import LogoDepois from '../assets/loc-logo-depois.webp';
import Material1 from '../assets/loc-material-1.webp';
import Material2 from '../assets/loc-material-2.webp';
import Material3 from '../assets/loc-material-3.webp';
import Material4 from '../assets/loc-material-4.webp';
import Site from '../assets/loc-site.webp';

// Icons
// import { ThumbUpAlt, Language, PlayCircleFilled, Brush, TrendingUp, Description } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 0,
      backgroundColor: '#000413',
      overflowX: 'hidden',
   },
   section: {
      paddingTop: '4rem',
      position: 'relative',
   },
   primary: {
      color: '#00ce4d',
   },
   white: {
      color: theme.palette.common.white,
   },
   appBarColor: {
      background: 'linear-gradient(135deg, rgba(3,52,158,1) 0%, rgba(2,129,118,1) 50%, rgba(1,168,98,1) 75%, rgba(0,206,77,1) 100%);',
   },
   header: {
      background: 'linear-gradient(135deg, rgba(3,52,158,1) 0%, rgba(2,129,118,1) 50%, rgba(1,168,98,1) 75%, rgba(0,206,77,1) 100%);',
      minHeight: '500px',
      [theme.breakpoints.down('sm')]: {
         minHeight: '350px',
      },
   },
   bgVector: {
      backgroundImage: `url(${CwaVector})`,
      minHeight: '500px',
      [theme.breakpoints.down('sm')]: {
         minHeight: '350px',
      },
   },
   buttonOutline: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: '#5533FF',
      borderRadius: '4px',
      padding: '1rem',
      color: '#5533FF',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '1rem',
      '&:hover': {
         backgroundColor: '#5533FF',
         color: 'white',
         boxShadow: '0px 6px 30px #5533FF',
      }
   },
   highlighter: {
      color: '#fff',
      marginBottom: '0.5rem',
   },
   highlighter2: {
      color: '#00ce4d',
      fontSize: '2rem',
      marginBottom: '1rem',
   },
   cardBorder: {
      textAlign: 'center',
      width: '100%',
   },
   classIcon: {
      display: 'none',
   },
   customizeBox: {
      height: '120px !important',
   },
   box: {
      backgroundColor: '#fff',
      borderRadius: '4px',
      position: 'relative',
      height: '100%',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      textAlign: 'center',
   },
}));


export default function BrasilAutomoveis() {
   const classes = useStyles();
   return (
      <div className={classes.root}>
         <TopBar appBarColor={classes.appBarColor} />

         {/* Header */}
         <Box className={`${classes.header}`}>
            <Box className={`${classes.bgVector}`} px={{ xs: 4, md: 8 }} pt={{ xs: 36, md: 48 }}>
               <Container>
                  <Grid container alignItems='center' justifyContent='center' alignContent='center'>
                     <Grid item md='8'>
                        <Box color='white' textAlign={{ xs: 'left', sm: 'center', }} mb={4}>
                           <Typography component='h1' variant='h2' className={classes.white}>
                              Locafacil
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </Box>

         {/* Notebook */}
         <Grid container alignContent='center' justifyContent='center'>
            <Grid item>
               <Box mt={{ xs: -32, md: -48 }}>
                  <img src={Notebook} alt='Locafacil aluguel de automóveis' width='100%' />
               </Box>
            </Grid>
         </Grid>

         {/* Sobre o projeto */}
         <Grid container>
            <Container>
               <Box pt={20} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 15 }} textAlign='left'>
                  <Grid container alignContent='center' direction='column'>
                     <Grid item md={6}>
                        <Box mb={2}>
                           <Typography component='h2' variant='h3' className={classes.white}>
                              Sobre o projeto
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid item md={6}>
                        <Typography variant='body1' className={classes.white} gutterBottom='true'>
                           <p>Nunca foi tão fácil alugar um carro! Seja a lazer ou a trabalho, com família ou amigos, a melhor experiência começa com a LocaFacil. Nesse projeto desenvolvemos todo o site, incluindo Layout e o Sistema de reserva dos veículos.</p>
                        </Typography>
                        <Typography variant='h6' component="p" className={classes.primary}>
                           Também executamos campanhas de marketing digital, oferecendo Comunicação, Design e Gestão de Tráfego para atrair novos clientes.
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         {/* Identidade visual */}
         <Box mt={{ xs: 24, md: 12 }}>
            <img src={Bg} alt='Locafacil aluguel de automóveis' width='100%' />
         </Box>
         <Box mt={-64} p={{ xs: 6, md: 12 }}>
            <Grid container justifyContent='center'>
               <Grid item md={6} className={classes.box}>
                  <Box px={8} pt={24} pb={24}>
                     <Grid container justifyContent='space-around' alignItems='center'>
                        <Grid md={3}>
                           <Box mb={{ xs: 16, md: 0 }}><img src={LogoAntes} alt='Locafacil aluguel de veículos' width='100%' /></Box>
                        </Grid>
                        <Grid md={3}>
                           <img src={LogoDepois} alt='Locafacil aluguel de veículos' width='100%' />
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
            </Grid>
         </Box>
         <Box px={{ xs: 2, md: 12 }} textAlign='center'>
            <Grid container className={classes.section} justifyContent='center' alignContent='center'>
               <Grid item>
                  <Box px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 15 }} textAlign='left'>
                     <Grid container justifyContent='left' alignContent='center' direction='column'>
                        <Grid item md={6}>
                           <Box mb={4}>
                              <Typography component='h2' variant='h3' className={classes.white}>
                                 Identidade Visual
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item md={6}>
                           <Box mb={{ xs: 12, md: 24 }}>
                              <Typography variant='body1' className={classes.white} gutterBottom='true'>
                                 O plano que traçamos demandava uma nova marca. Pensamos na nova identidade visual como uma marca de carros.
                                 Unimos legibilidade com agressividade, unimos símbolo à tipografia e chegamos ao resultado que queríamos:
                                 <Typography variant='h6' component="span" className={classes.primary}> uma marca forte, dinâmica e agressiva.</Typography>
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
               <Grid item md={3}>
                  <img src={Material1} alt='Locafacil aluguel de automóveis' />
               </Grid>
               <Grid item md={3}>
                  <img src={Material2} alt='Locafacil aluguel de automóveis' />
               </Grid>
               <Grid item md={3}>
                  <img src={Material3} alt='Locafacil aluguel de automóveis' />
               </Grid>
               <Grid item md={3}>
                  <img src={Material4} alt='Locafacil aluguel de automóveis' />
               </Grid>
            </Grid>
         </Box>

         {/* Artes para redes sociais */}
         <Grid container className={classes.section} justifyContent='center' alignItems='center'>
            <Container>
               <Box pt={{ xs: 28, md: 48 }} px={4} pb={10} textAlign={{ xs: 'left', md: 'center' }}>
                  <Grid container justifyContent='center' alignItems='center'>
                     <Typography component='h2' variant='h3' className={classes.white}>
                        Artes para redes sociais
                     </Typography>
                  </Grid>
               </Box>
            </Container>
            <Box width='100%' mb={{ xs: 18, md: 48 }}>
               <CarouselLocafacil />
            </Box>
         </Grid>

         {/* Edição de vídeo */}
         <Container>
            <Grid container className={classes.section} justifyContent='center' alignContent='center' alignItems='center'>
               <Grid item md={12}>
                  <Box pt={{ xs: 18, md: 24 }} px={4} pb={10} textAlign={{ xs: 'left', md: 'center' }}>
                     <Grid container justifyContent='center' alignItems='center'>
                        <Typography component='h2' variant='h4' className={classes.white}>
                           Edição de vídeos
                        </Typography>
                     </Grid>
                  </Box>
               </Grid>
               <Box width='100%' mb={{ xs: 18, md: 48 }}>
                  <CarouselLocafacilVideos />
               </Box>
            </Grid>
         </Container>

         {/* Gestão de tráfego */}
         <Box px={{ xs: 2, md: 12 }}>
            <Grid container className={classes.section} justifyContent='center' alignContent='center'>
               <Grid item>
                  <Box px={{ xs: 4, md: 8 }} pb={{ xs: 10, md: 10 }} textAlign='left'>
                     <Grid container justifyContent='left' alignContent='center' direction='column'>
                        <Grid item md={6}>
                           <Box mb={2}>
                              <Typography component='h2' variant='h3' className={classes.white}>
                                 Gestão de tráfego
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item md={6}>
                           <Box mb={{ xs: 12, md: 24 }}>
                              <Typography variant='body1' className={classes.white} gutterBottom='true'>
                                 <p>Após o cenário ajustado, atendimento reformulado e comunicação reposicionada, iniciamos nosso processo de gestão de tráfego com anúncios em Facebook/Instagram e Google.</p>
                              </Typography>
                              <Typography variant='body1' className={classes.white} gutterBottom='true'>
                                 <p><Typography variant='h6' component="span" className={classes.primary}>Desenvolvemos campanhas de distribuição de conteúdo, conversão e geração de lead.</Typography></p>
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
               <Grid item>
                  <Box px={{ xs: 4, md: 8 }} mb={20} width='100%'>
                     <Grid container alignContent='center' justifyContent='space-between' spacing={8}>
                        <CardBorder
                           col='6'
                           cardBorder={classes.cardBorder}
                           classIcon={classes.classIcon}
                           classHighlighter={classes.highlighter2}
                           highlighter='+ R$ 200.000'
                           text='investidos em tráfego'
                        />
                        <CardBorder
                           col='6'
                           cardBorder={classes.cardBorder}
                           classIcon={classes.classIcon}
                           classHighlighter={classes.highlighter2}
                           highlighter='+ 70%'
                           text='de aumento em locações através dos nossos anúncios.'
                        />
                     </Grid>
                  </Box>
               </Grid>
            </Grid>
         </Box>

         {/* Novo site e Landing Page */}
         <Box pt={{ xs: 30, md: 42 }} textAlign='center'>
            <Grid container justifyContent='center' alignItems='center'>
               <Grid item md={12}>
                  <Typography component='h2' variant='h3' className={classes.white}>
                     <Box mb={16}>Site</Box>
                  </Typography>
               </Grid>
               <Grid item md={12}>
                  <img src={Site} alt='Site da Braisl automóveis' width='100%' />
               </Grid>
            </Grid>
         </Box>

         {/* Pronto para impulsionar suas vendas? */}
         <Grid container className={`${classes.section} ${classes.bg1}`}>
            <Container>
               <Box pt={24} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 25 }} textAlign={{ xs: 'left', md: 'center' }}>
                  <Grid container justifyContent='center' alignItems='center' direction='column'>
                     <Grid item md={8}>
                        <Typography variant='h4' className={classes.white}>
                           <Box mb={2}>Pronto para impulsionar suas vendas?</Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={4}>
                        <Typography variant='body2' className={classes.white} gutterBottom='true'>
                           <Box mb={8}>
                              Fale agora com um dos nossos especialistas
                              e nos conte sobre seu negócio.
                           </Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={5}>
                        <Button variant='outlined' className={classes.buttonOutline} href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>Começar agora</Button>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         <Footer />
      </div >
   );
}