import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import { Container, Box } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

// Images
import ImageFHM from '../assets/h-portfolio-fhm.webp';
import ImageLocafacil from '../assets/h-portfolio-locafacil.webp';
import ImagePVN from '../assets/h-portfolio-pvn.webp';
import { Grid } from '@material-ui/core';

const tutorialSteps = [
   {
      label: 'Fundação Homem do Mar',
      imgPath: `${ImageFHM}`,
      description: 'Empresa que atua diretamente na qualificação de profissionais do setor aquaviário e são uma referência global na consultoria e qualificação profissional no setor aquaviário e portuário.',
      tag: ['1º lugar orgânico no Google em todos os cursos', 'MKT de conteúdo para envolvimento com nosso público-alvo', 'Eficiência na conversão em vendas'],
      link: 'http://cwa.digital/fhm.php'
   },
   {
      label: 'Locafacil',
      imgPath: `${ImageLocafacil}`,
      description: 'Nunca foi tão fácil alugar um carro! Seja a lazer ou a trabalho, com família ou amigos, a melhor experiência começa com a LocaFacil. Nesse projeto desenvolvemos todo o site, incluindo Layout e o Sistema de reserva dos veículos. Também executamos campanhas de marketing digital, oferecendo Comunicação, Design e Tráfego pago para atrair novos clientes.',
      tag: ['Midias Sociais', 'Site', 'Gestão de Tráfego'],
      link: 'http://cwa.digital/m/locafacil.php',
   },
   {
      label: 'Projeto de Vilar',
      imgPath: `${ImagePVN}`,
      description: 'O Projeto Vida Nova de Vilar dos Teles é uma de muitas igrejas em todo o Brasil e Mundo do Projeto Vida Nova. Vivenciando um momento de crescimento exponencial, o Projeto Vida Nova de Vilar dos Teles é uma igreja engajada, envolvida e que visa o crescimento nas mais diversas mídias, tendo sempre a palavra de Deus como seu norte.',
      tag: ['Redes sociais', 'Eventos', 'Impressos'],
      link: 'http://cwa.digital/pvnvilar.php',
   },
   // {
   //    label: 'Home Academy',
   //    imgPath: `${ImageHA}`,
   //    description: 'A Home Academy é um projeto desenvolvido pela CWA em parceria com o Frônesis Christian School. Atuamos em todo o desenvolvimento de marca, produção audiovisual e marketing de lançamento. Hoje somos a maior plataforma de Formação em Educação Clássica do Brasil.',
   //    tag: ['Lançamento com quase meio milhão de faturamento', 'Criação de Plataforma EAD exclusiva', 'Gravação e edição de vídeos'],
   //    link: 'http://cwa.digital/m/homeacademy.php',
   // },
   // {
   //    label: 'Frônesis Christian School',
   //    imgPath: `${ImageFronesis}`,
   //    description: 'O Frônesis é uma instituição de ensino que segue os moldes clássicos de educação, com uma filosofia cristocêntrica e sua pedagogia pautada nas Sete Artes Liberais. Com esse foco no Projeto, desenvolvemos a nova Identidade Visual, Site, Redes Sociais, material impresso e toda a sua comunicação interna e externa para engajar os alunos da escola bem como atrair novos interessados em uma Educação qualidade.',
   //    tag: ['Redes sociais', 'Site', 'Identidade Visual'],
   //    link: 'http://cwa.digital/m/fronesis.php',
   // },
];

const useStyles = makeStyles((theme) => ({
   root: {
      // maxWidth: 400,
      height: 555,
      flexGrow: 1,
   },
   tag: {
      color: '#738292',
      marginTop: '0.5rem',
      marginRight: '0.75rem',
      padding: '0.5rem 1.5rem',
      border: '1px dashed #738292',
      borderRadius: '25px',
      fontSize: '0.85rem',
      display: 'inline-block',
   },
   mobileStepper: {
      backgroundColor: 'transparent',
      color: theme.palette.common.white,
      marginTop: '4rem',
      maxWidth: '300px',
      [theme.breakpoints.down('sm')]: {
         marginTop: '1rem',
      },
   },
   white: {
      color: theme.palette.common.white,
   },
   button: {
      padding: '0.75rem 4rem',
      marginTop: '3rem',
      color: 'white',
      borderRadius: '4px',
      fontWeight: 'bold',
      boxShadow: '0px 6px 30px rgba(85, 51, 255, 0.4)',
      backgroundColor: '#5533FF',
      '&:hover': {
         backgroundColor: '#5533FF',
         opacity: '0.9'
      },
   },
   img: {
      [theme.breakpoints.down('sm')]: {
         width: '100%',
      },
   },
}));

export default function TextMobileStepper() {
   const classes = useStyles();
   const theme = useTheme();
   const [activeStep, setActiveStep] = React.useState(0);
   const maxSteps = tutorialSteps.length;

   const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   };

   const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };

   return (
      <div className={classes.root}>
         <Container>
            <Grid container justifyContent='space-between'>
               <Box display='flex' flexDirection={{ xs: 'column-reverse', sm: 'row' }} justifyContent='space-between'>
                  <Grid item md={5}>
                     <Box color='white' mt={{ xs: 8, md: 32}} mb={8}>
                        <Typography variant='h4'>{tutorialSteps[activeStep].label}</Typography>
                     </Box>
                     <Box color='white' mb={8}>
                        <Typography variant='body1'>
                           {tutorialSteps[activeStep].description}
                        </Typography>
                     </Box>
                     <Box mb={4}>
                        <span className={classes.tag}>{tutorialSteps[activeStep].tag[0]}</span>
                        <span className={classes.tag}>{tutorialSteps[activeStep].tag[1]}</span>
                        <span className={classes.tag}>{tutorialSteps[activeStep].tag[2]}</span>
                     </Box>
                     {/* <Box mb={4}>
                        <Grid container justifyContent={{ xs: 'center', sm: 'center'}}>
                           <Button variant='contained' display='flex' alignSelf='center' className={classes.button} href={tutorialSteps[activeStep].link}>
                              Ver Projeto
                           </Button>
                        </Grid>
                     </Box> */}
                     <MobileStepper
                        className={classes.mobileStepper}
                        steps={maxSteps}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                           <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} className={classes.white}>
                              Próximo
                              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                           </Button>
                        }
                        backButton={
                           <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={classes.white}>
                              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                              Voltar
                           </Button>
                        }
                     />
                  </Grid>
                  <Grid item md={6}>
                     <Box mt={{ xs: 16, md: 32 }}>
                        <img
                           className={classes.img}
                           src={tutorialSteps[activeStep].imgPath}
                           alt={tutorialSteps[activeStep].label}
                        />
                     </Box>
                  </Grid>
               </Box>
            </Grid>
         </Container>
      </div>
   );
}
