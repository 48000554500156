import React from 'react';
// import Lottie from 'lottie-react';
import { Container, Grid, makeStyles, Typography, Box, Divider, Button } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CardBorder from '../components/CardBorder';
import BgImage2 from '../assets/lsd-bg-2.webp';
import BgImage3 from '../assets/lsd-bg-3.webp';
import Image from '../assets/tp-header.webp';
import Image3 from '../assets/tp-loja-roupa.webp';
import Image4 from '../assets/tp-barbearia.webp';
import Image5 from '../assets/tp-prof-liberal.webp';
import Image6 from '../assets/tp-loja-virtual.webp';
import ImageBorder from '../assets/icon-border.webp';
// import Icon from '@mdi/react'
// import { mdiRocket } from '@mdi/js';
// import Report from '../assets/animations/report.json';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    backgroundColor: '#000413',
  },
  section: {
    paddingTop: '4rem',
    position: 'relative',
  },
  appBarColor: {
    background: 'linear-gradient(135deg, rgba(64,199,0,1) 0%, rgba(64,175,0,1) 50%, rgba(64,164,0,1) 75%, rgba(64,121,0,1) 100%);',
  },
  // bgImage1: {
  //    backgroundImage: `url(${BgImage1})`,
  //    backgroundRepeat: 'no-repeat',
  //    backgroundSize: 'contain',
  //    backgroundPosition: 'center 3rem',
  // },
  bgImage2: {
    backgroundImage: `url(${BgImage2})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
  bgImage3: {
    backgroundImage: `url(${BgImage3})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
  title: {
    fontSize: '56px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '46px',
    },
  },
  titleFontReverse: {
    textShadow: '-1px -1px 0 #40C700, 1px -1px 0 #40C700, -1px 1px 0 #40C700, 1px 1px 0 #40C700',
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
  classSubtitle: {
    fontSize: '1.75rem',
    fontWeight: '700',
    lineHeight: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  },
  classBoxText: {
    marginTop: '-25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  classBoxImage: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  button: {
    backgroundColor: '#40C700',
    color: 'white',
    borderRadius: '4px',
    marginTop: '3rem',
    fontWeight: 'bold',
    padding: '1rem',
    marginRight: 'auto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#40C700',
      boxShadow: '0px 6px 30px #40c700',
    }
  },
  buttonOutline: {
    border: '2px solid #40C700',
    color: '#40C700',
    borderRadius: '4px',
    marginTop: '3rem',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: '1rem',
    '&:hover': {
      backgroundColor: '#40C700',
      color: 'white',
      boxShadow: '0px 6px 30px #40c700',
    }
  },
  white: {
    color: theme.palette.common.white,
  },
  grey: {
    color: theme.palette.grey.main,
  },
  cardBorder: {
    textAlign: 'center',
    width: '100%',
  },
  highlighter: {
    color: '#40c700',
    marginBottom: '0.5rem',
  },
  highlighter2: {
    color: '#40c700',
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  classTop: {
    display: 'none',
  },
  iconCheck: {
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    marginTop: '-0.3rem',
  },
  imageBorder: {
    maxWidth: '55px',
    maxHeight: '55px',
    backgroundImage: `url(${ImageBorder})`,
    padding: '0.65rem 0.85rem',
    color: theme.palette.primary.main,
  },
  imageRocket: {
    position: 'absolute',
    top: '-5rem',
    right: '6rem',
  },
  divider: {
    height: '2px',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    backgroundColor: '#13151E',
  },
  lineBorder: {
    background: 'linear-gradient(90deg, #FF0155 0%, rgba(255, 1, 85, 0) 100%)',
    borderRadius: '0px 0px 4px 4px',
    width: '100%',
    height: '4px',
    position: 'absolute',
    left: '0',
    bottom: '0',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '1rem',
  },
  textGreen: {
    color: '#40c700',
  }
}));

function TrafegoPago() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar
        appBarColor={classes.appBarColor}
      />
      <Header
        classTitleFontReverse={classes.titleFontReverse}
        titleFontReverse='Tráfego Pago'
        classSubtitle={classes.classSubtitle}
        subtitle='A Gestão de Tráfego Pago é o combustível fundamental para acelerar os seus
        resultados na internet.'
        sup='Mas afinal, o que é o Tráfego Pago?'
        description='Sabe aqueles anúncios que aparecem nos sites e redes sociais que você acessa? É sobre isso que estamos falando. Agora imagine seus serviços e produtos aparecendo para o publico certo.'
        sub='Anunciar na internet é estratégia que mais gera retorno sobre investimento na atualidade. '
        classBoxText={classes.classBoxText}
        // animation={Report}
        image={Image}
        imageAlt='Tráfego Pago'
        classBoxImage={classes.classBoxImage}
        button={classes.buttonOutline}
        buttonLabel='Agende agora uma consultoria gratuita para seu negócio'
        linkButton='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20Tráfego%20pago.'
      />
      {/* <Box className={classes.question}>
        <Lottie animationData={Question} className={classes.questionImage} />
      </Box> */}
      {/* mas afinal, o que é tráfego pago */}
      {/* <Grid container className={`${classes.section} ${classes.bg1}`}>
        <Container>
          <Box pt={10} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 25 }} textAlign={{ xs: 'left', md: 'center' }}>
            <Typography variant='h3' className={classes.white}>
              <Box mb={2}>Mas afinal, o que é o Tráfego Pago</Box>
            </Typography>
            <Grid container justifyContent='center' alignItems='center' direction='column'>
              <Grid item lg={6}>
                <Typography variant='body2' className={classes.white} gutterBottom='true'>
                  Sabe aqueles anúncios que aparecem nos sites e redes sociais que você acessa? É sobre isso que estamos falando. Agora imagine seus serviços e produtos aparecendo para o publico certo.
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography variant='body2' className={classes.white}>
                  Anunciar na internet é estratégia que mais gera retorno sobre investimento na atualidade.
                </Typography>
              </Grid>
              <Grid item lg={5}>
                <Button variant='outlined' className={classes.button2} href='#'> Agende agora uma consultoria gratuita para seu negócio
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid> */}

      {/* Divulgue seus serviços e produtos para a pessoa certa */}
      <Grid container className={`${classes.section} ${classes.bg1}`}>
        <Container>
          <Box pt={10} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 25 }} textAlign={{ xs: 'left', md: 'center' }}>
            <Grid container justifyContent='center' alignItems='center' direction='column'>
              <Grid item md={8}>
                <Typography variant='h3' className={classes.white}>
                  <Box mb={2}>Divulgue seus serviços e produtos para a pessoa certa, nos locais certos e na hora certa.</Box>
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography variant='body2' className={classes.white} gutterBottom='true'>
                  A versatilidade dos anúncios permite você divulgar seus produtos e serviços de forma certeira e pontual, de acordo com interesses, idade, localização, gênero e comportamento dos usuários. Se você não anuncia na internet está perdendo dinheiro.
                </Typography>
              </Grid>
              <Grid item lg={5}>
                <Button variant='outlined' className={classes.buttonOutline} href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20Tráfego%20pago.'>Quero anunciar na internet</Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid>

      {/* O que oferecemos em nosso pacote? */}
      <Grid container>
        <Container>
          <Box pt={10} mt={{ xs: 0, lg: 20 }} px={{ xs: 4, md: 8 }} pb={16} textAlign={{ xs: 'left', md: 'center' }}>
            <Typography variant='h3' className={classes.white}>
              <Box mb={2}>Perfeito para o seu negócio</Box>
            </Typography>
          </Box>
          <Box px={4}>
            <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
              <CardBorder
                col='5'
                number='01'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Lojas Físicas'
                text='Os anúncios segmentados por localização e interesses podem levar mais pessoas à sua loja, salão, restaurante, clínica e etc.'
                image={Image3}
                imageAlt='Lojas Físicas'
              />
              <CardBorder
                col='5'
                number='02'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Serviços e agendamento'
                text='Empresas que atuam com prestação de serviços podem lotar sua agenda ao alcançar os primeiros lugares no Google através dos nossos anúncios.'
                image={Image4}
                imageAlt='Serviços e agendamento'
              />
              <CardBorder
                col='5'
                number='03'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Profissionais Liberais'
                text='Seu conteúdo vale ouro e pode alcançar muito mais pessoas. Nossos anúncios de alcance e conversão são capazes de transformar a realidade de médicos, nutricionistas, dentistas, psicologos, professores e demais profissionais liberais.'
                image={Image5}
                imageAlt='Site Versão Desktop e Mobile'
              />
              <CardBorder
                col='5'
                number='04'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Lojas virtuais'
                text='Potencialize suas vendas online através de campanhas de tráfego e remarketing. Alavanque seus resultados e dê força à sua marca com anúncios perfeitos para a sua audiência.'
                image={Image6}
                imageAlt='Lojas Virtuais'
              />
            </Grid>
            <Box item direction='row' justifyContent='center' textAlign='center'>
                <Button variant='outlined' className={classes.button} href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20Tráfego%20pago.'> Eu quero alavancar meus resultados com a internet
              </Button>
            </Box>
          </Box>
        </Container>
      </Grid>

      {/* Nossos resultados */}
      <Grid container>
        {/* <Container> */}
          <Box pt={20} mt={{ xs: 0, lg: 20 }} px={{ xs: 4, md: 8 }} pb={8} mb={2} textAlign={{ xs: 'left', md: 'center' }} width='100%'>
            <Typography variant='h3' className={classes.white}>
              Nossos resultados
            </Typography>
          </Box>
          <Box px={{ xs: 4, md: 8 }} mb={20} width='100%'>
            <Grid container alignContent='center' justifyContent='space-between' spacing={8}>
              <CardBorder
                col='3'
                cardBorder={classes.cardBorder}
                classTop={classes.classTop}
                classHighlighter={classes.highlighter2}
                highlighter='+ R$ 700.000'
                text='investidos em tráfego'
              />
              <CardBorder
                col='3'
                cardBorder={classes.cardBorder}
                classTop={classes.classTop}
                classHighlighter={classes.highlighter2}
                highlighter='+ R$ 1,5 milhão'
                text='em vendas mensais através dos nossos anúncios.'
              />
              <CardBorder
                col='3'
                cardBorder={classes.cardBorder}
                classTop={classes.classTop}
                classHighlighter={classes.highlighter2}
                highlighter='+ 200'
                text='anúncios rodando'
              />
              <CardBorder
                col='3'
                cardBorder={classes.cardBorder}
                classTop={classes.classTop}
                classHighlighter={classes.highlighter2}
                highlighter='+ 50 milhões'
                text='de pessoas impactadas com nossos anúncios.'
              />
            </Grid>
          </Box>
        {/* </Container> */}
      </Grid>

      {/* Perguntas frequentes */}
      <Box pt={15} px={{ xs: 4, md: 7 }} pb={8}>
        <Divider variant='middle' className={classes.divider} />
        <Grid container className={classes.section} justifyContent='space-between' alignItems='stretch' spacing={8}>
          <Grid item md={12}>
            <Box px={7} mb={2} textAlign='center'>
              <Typography variant='h3' className={classes.white}>
                Perguntas Frequentes
              </Typography>
            </Box>
          </Grid>
          <CardBorder
            col='4'
            classHighlighter={classes.highlighter}
            number=''
            highlighter='Trafego pago é essencial para minha empresa crescer na internet?'
            text='Sinceramente? Não! É totalmente possível você conquistar um bom posicionamento na internet e aumentar suas vendas apenas com estratégias de tráfego orgânico. Contudo há um fator que faz toda a diferença para qualquer empresário: o tempo. O tráfego pago é o fator que turbina seus resultados e acelera seu crescimento. Tempo é dinheiro.'
          />
          <CardBorder
            col='4'
            classHighlighter={classes.highlighter}
            number=''
            highlighter='Eu não tenho site, posso fazer tráfego pago?'
            text='Pode sim. Existem diversas campanhas e estratégias que utilizam as redes sociais e o WhatsApp da sua empresa como canais diretos de conversão. Entretanto é válido ressaltar que, dependendo do tipo de serviço ou produto que você venda, um site pode potencializar significativamente seus resultados. Mas fique tranquilo, nossos consultores entenderão seu negócio e objetivos e indicarão os melhores caminhos.'
          />
          <CardBorder
            col='4'
            classHighlighter={classes.highlighter}
            number=''
            highlighter='Os anúncios são no Google o nas Redes Sociais?'
            text='Depende do valor investido e do seu nicho de atuação. Alguns serviços e produtos venderão mais em anúncios na rede de pesquisa e display do Google, enquanto outros terão resultados bem maiores somente nas redes sociais. Os dados extraídos das nossas pesquisas, análises e testes serão os guias para nossas ações'
          />
        </Grid>
      </Box>

      {/* Footer */}
      <Footer />

    </div>
  );
}

export default TrafegoPago;