import React from 'react';
import Lottie from "lottie-react";
import Marquee from "react-simple-marquee";
import {
   makeStyles, Grid, Box, Typography, Container, Button
} from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import TabServicos from '../components/TabServicos';
import CarouselPortfolio from '../components/CarouselPortfolio';
import CarouselBlog from '../components/CarouselBlog';

// Images
import BgImage from '../assets/h-bg.webp';
import BgApps from '../assets/h-bg-apps.webp';
import LogoPurple from '../assets/logo-purple.webp';
import ImageEmpresa from '../assets/h-empresa.webp';
import ImageClientes from '../assets/h-clientes.webp';

// Animations
import Laptop from '../assets/animations/laptop.json';
import Mouse from '../assets/animations/mouse.json';

// Icons
import { NavigateNextOutlined, ArrowDownwardOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
      width: '100%',
      minHeight: '100vh',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top',
      backgroundImage: `url(${BgImage})`,
      backgroundColor: '#000413',
      overflow: 'hidden',
   },
   section: {
      paddingTop: '4rem',
      position: 'relative',
   },
   appBarColor: {
      background: 'linear-gradient(135deg, rgba(85,51,255,1) 0%, rgba(76,46,229,1) 50%, rgba(71,43,216,1) 75%, rgba(66,40,201,1) 100%);',
   },
   title: {
      fontSize: '3.5rem',
      [theme.breakpoints.down('sm')]: {
         fontSize: '2.5rem',
      },
   },
   header: {
      width: '100%',
      height: '100vh',
   },
   primary: {
      color: '#5533FF',
   },
   white: {
      color: theme.palette.common.white,
   },
   dark: {
      color: theme.palette.primary.dark,
   },
   lineBorder: {
      background: 'linear-gradient(90deg, #5533FF 0%, rgba(255, 1, 85, 0) 100%)',
      borderRadius: '0px 0px 4px 4px',
      width: '100%',
      height: '4px',
      position: 'absolute',
      left: '0',
      bottom: '0',
   },
   marquee: {
      width: '100%',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      color: '#738292',
      fontSize: '6rem',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '800',
      [theme.breakpoints.down('sm')]: {
         fontSize: '3rem',
      },
   },
   icon: {
      fontSize: '6rem',
      color: '#5533FF',
      [theme.breakpoints.down('sm')]: {
         fontSize: '4rem',
      }
   },
   iconSpan: {
      margin: '2rem 3rem 0 3rem',
      [theme.breakpoints.down('sm')]: {
         margin: '1rem 3rem 0 3rem',
      }
   },
   logoPurple: {
      width: '150px',
      margin: '0rem 5rem 0 7rem',
   },
   mouse: {
      width: '45px',
      color: 'red !important',
      // margin: '9rem auto 0rem auto',
   },
   bgApps: {
      minHeight: '300px',
      width: '100%',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom center',
      backgroundImage: `url(${BgApps})`,
      backgroundColor: '#000413',
      [theme.breakpoints.down('sm')]: {
         backgroundImage: 'none',
      }
   },
   tabPanel: {
      // marginBottom: '5rem',
   },
   tag: {
      color: '#738292',
      marginRight: '0.75rem',
      padding: '0.5rem 1.5rem',
      border: '1px dashed #738292',
      borderRadius: '25px',
      fontSize: '0.85rem'
   },
   citacao: {
      fontStyle: 'italic',
   },
   clientes: {
      background: 'linear-gradient(135deg, rgba(85,51,255,1) 0%, rgba(76,46,229,1) 50%, rgba(71,43,216,1) 75%, rgba(66,40,201,1) 100%);',
   },
   portfolio: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
   },
   portfolioImg: {
      height: 255,
      maxWidth: 400,
      overflow: 'hidden',
      display: 'block',
      width: '100%',
   },
   button: {
      padding: '0.75rem 2rem',
      marginTop: '3rem',
      borderRadius: '4px',
      fontWeight: 'bold',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 6px 30px rgba(255, 1, 85, 0.4)',
      '&:hover': {
         opacity: '0.9',
         backgroundColor: theme.palette.primary.main,
         boxShadow: '0px 6px 30px rgba(255, 1, 85, 0.4)',
      }
   },
   imageEmpresa: {
      marginLeft: '-8rem',
      [theme.breakpoints.down('sm')]: {
         marginLeft: '0',
         width: '100%',
      },
   },
}));

function Home() {
   const classes = useStyles();

   return (
      <div className={classes.root}>
         <TopBar
            appBarColor={classes.appBarColor}
         />

         {/* Header - Olá! */}
         <Grid container className={classes.header} justifyContent='center' alignContent='center' alignItems='center'>
            {/* <Grid container justifyContent='center' alignContent='center' alignItems='center'> */}
               <Grid item md='8'>
                  <Typography variant='h1'>
                     <Box color='white' textAlign={{ xs: 'left', sm: 'center', }} className={classes.title} px={6} pt={12} gutterBottom>
                        Olá! Nós somos a <span className={classes.primary}>Cwa Digital</span>,<br /> a sua agência.
                     </Box>
                  </Typography>
               </Grid>
               <Grid item md='6'>
                  <Typography variant='body1'>
                     <Box color='white' textAlign={{ xs: 'left', sm: 'center', }} mt={8} px={6}>
                        É muito bom receber você em nossa casa. Fique a vontade, lhe convido a ler nossa proposta e conhecer um pouco sobre nossa
                        filosofia de trabalho e soluções que oferecemos. Precisando de qualquer ajuda, é só chamar.
                     </Box>
                  </Typography>
               </Grid>
               <Grid item md='12' selfItem='center' alignItem='center'>
                  <Box className={classes.mouse} mt={{ xs: 12, sm: 12, md: 20 }} mb={{ xs: 12, sm: 12, md: 0 }} mx={'auto'}>
                     <Lottie animationData={Mouse} />
                  </Box>
               </Grid>
            {/* </Grid> */}
         </Grid>

         {/* Bloco 2 - Nós acreditamos no trabalho... */}
         <Container>
            <Grid container alignItems='center' justifyContent='space-between'>
               <Grid item md='5'>
                  <Box mx={{ xs: 6 }}>
                     <Box color='white' mb={16} pr={{ xs: 0, md: 16 }} textAlign={{ xs: 'center', md: 'left'}}>
                        <Typography variant='h3' className={classes.white}>
                           Acreditamos no trabalho, na constância, em resultados.
                        </Typography>
                     </Box>
                  </Box>
               </Grid>
            </Grid>
            <Box mb={{ xs: 12, md: 0 }} display="flex" flexDirection={{ xs: 'column-reverse', sm: 'row' }} alignItems='' justifyContent='space-between'>
               <Grid item md='4'>
                  <Box mx={{ xs: 4 }}>
                     <Box color='white' mb={4} pl={0} pr={0}>
                        <Typography variant='body1'>
                           Acredite, não existe uma receita para o sucesso, mas sim métodos onde
                           <Typography variant='h6' component="span" className={classes.primary}> a estratégia guia, o conteúdo move e o relacionamento converte.</Typography>
                        </Typography>
                     </Box>
                     <Box color='white' mb={4} pl={0} pr={0}>
                        <Typography variant='body1'>
                           Os mais de 10 anos de atuação a frente de diversos negócios nos proporcionou a experiência e
                           versatilidade necessárias para entendermos seu negócio e buscarmos as soluções mais ágeis e
                           criativas para o crescimento da sua marca através do <Typography variant='h6' component="span" className={classes.primary}> Marketing Digital.</Typography>
                        </Typography>
                     </Box>
                     <Box mb={4} pl={0} pr={0} textAlign={{ xs: 'center', md: 'center' }}>
                        <Button
                           variant='container'
                           className={classes.button}
                           href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>
                           Transforme a realidade do seu negócio agora mesmo
                        </Button>
                     </Box>
                  </Box>
               </Grid>
               <Grid item md='7'>
                  <Grid container justifyContent='center'>
                     <Box mt={{ xs: 0, md: -52 }}>
                        <Lottie animationData={Laptop} />
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>

         {/* Marquee */}
         <Marquee speed={2.5}>
            <Box py={{ sm: 10, md: 15 }} mb={15} className={`${classes.marquee}`}>
               Diagnóstico <span className={classes.iconSpan}><NavigateNextOutlined className={classes.icon} /></span>
               Estratégia <span className={classes.iconSpan}><NavigateNextOutlined className={classes.icon} /></span>
               Planejamento <span className={classes.iconSpan}><NavigateNextOutlined className={classes.icon} /></span>
               Táticas <span className={classes.iconSpan}><NavigateNextOutlined className={classes.icon} /></span>
               Execução <img className={classes.logoPurple} src={LogoPurple} alt='Logo CWA' />
            </Box>
         </Marquee>

         {/* Bloco 3 - Serviços */}
         <Box className={classes.bgApps} mb={0} pb={{ md: 20 }}>
            <Box mx={{ xs: 4 }}>
               <Container>
                  <Grid container alignItems='center' justifyContent='center'>
                     <Box color='white' textAlign='center' mb={0}>
                        <Typography variant='h3' className={classes.white}>
                           Nossas soluções
                        </Typography>
                     </Box>
                  </Grid>
                  <TabServicos />
                  <Box mt={4} mb={4} textAlign={{ xs: 'center', md: 'center' }}>
                     <Button
                        variant='container'
                        className={classes.button}
                        href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>
                        Eu quero saber mais sobre as soluções ideais para meu negócio
                     </Button>
                  </Box>
               </Container>
            </Box>
         </Box>

         {/* Bloco 4 - Zig Ziglar */}
         <Box className={`${classes.section} ${classes.bgZigZiglar}`} mt={10} mb={20}>
            {/* <FormatQuoteOutlined className={classes.quote} /> */}
            <Grid container alignItems='center' justifyContent='center'>
               <Grid item md='6'>
                  <Box mx={6} mb={4} textAlign={{ xs: 'left', sm: 'center' }}>
                     <Box color='white'>
                        <Typography variant='h4'>
                           "Você pode ter tudo o que quiser na vida, se ajudar os outros a conquistarem o que eles querem."
                        </Typography>
                     </Box>
                     <Box>
                        <Typography variant='h4' className={classes.primary}>
                           Zig Ziglar
                           {/* <Link href='https://www.ziglar.com/why-ziglar/ziglar-key-influencers/' rel="noreferrer" target='_blank' underline='none' color='#5533FF'>Zig Ziglar <OpenInNewOutlined /></Link> */}
                        </Typography>
                        {/* <Box mt={-6}>
                           <Typography variant='body2'>
                           Escritor, vendedor e orador motivacional americano.
                           </Typography>
                        </Box> */}
                     </Box>
                  </Box>
               </Grid>
               {/* <Grid item md='4'>
                  <Grid container justifyContent='flex-start'>
                     <Box display={{ xs: 'none', md: 'inline-block' }}>
                        <img src={ImageZigZiglar} className={classes.zigZiglar} alt='Zig Ziglar' width='100%' />
                     </Box>
                  </Grid>
               </Grid> */}
            </Grid>
         </Box>

         {/* Bloco 5 - A sua Empresa é do tamanho... */}
         <Container className={classes.section}>
            <Box mb={32} mx={{ xs: 4, md: 8 }}>
               <Grid container alignItems='center' justifyContent='space-between'>
                  <Grid item md='7'>
                     <Grid container justifyContent='center'>
                        <img src={ImageEmpresa} className={classes.imageEmpresa} alt='Sua empresa é do tamanho que você mostra na internet' />
                     </Grid>
                  </Grid>
                  <Grid item md='5'>
                     <Box color='white' mt={4} mb={4} pr={{ xs: 0, md: 8 }}>
                        <Typography variant='h3' className={classes.white}>
                           "Minha empresa é pequena, o Marketing Digital é para mim?"
                        </Typography>
                     </Box>
                     <Box color='white' mb={4} pl={0} pr={{ sm: 0, md: 16 }}>
                        <Typography variant='body2'>
                           Eu preciso ser bem direto com você agora: <Typography variant='h6' component="span" className={classes.primary}> sua empresa é do tamanho que você a mostra na maior vitrine do mundo - a internet.</Typography>
                        </Typography>
                     </Box>
                     <Box color='white' mb={4} pl={0} pr={{ sm: 0, md: 16 }}>
                        <Typography variant='body2'>
                           Pouco importa o tamanho da sua sede ou o número de colaboradores que você emprega.
                           A percepção das pessoas sobre o tamanho da sua empresa se construirá através da forma que você a posiciona na internet.
                        </Typography>
                     </Box>
                     <Box color='white' mb={4} pl={0} pr={{ sm: 0, md: 16 }}>
                        <Typography variant='body2'>
                           Agora me diga: qual o tamanho da sua empresa?
                        </Typography>
                     </Box>
                     <Box mt={4} mb={4} pl={0} pr={0} textAlign={{ xs: 'center', md: 'center' }}>
                        <Button
                           variant='container'
                           className={classes.button}
                           href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>
                           Quero crescer minha empresa agora com o Marketing Digital
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>

         {/* Bloco 6 - Portolio */}
         <Box className={classes.clientes} px={{ xs: 4, md: 8 }} py={12}>
            <Container>
               <Grid container alignItems='center' justifyContent='center' alignContent='center'>
                  <Grid item md='8'>
                     <Box color='white' textAlign={{ xs: 'left', sm: 'center', }} mb={4}>
                        <Typography variant='h3' className={classes.white}>
                           Conheça alguns clientes que alavancaram seus resultados com a CWA Digital <ArrowDownwardOutlined />
                        </Typography>
                     </Box>
                     <Box color='white' textAlign={{ xs: 'left', sm: 'center', }}>
                        <Typography variant='body2'>
                           O próximo case de sucesso que queremos expor é o seu.
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>
         <Container>
            <Box px={{ xs: 4, md: 0 }}>
               <CarouselPortfolio />
            </Box>
         </Container>

         {/* Bloco 8 - Clientes e parceiros */}
         <Container>
            <Box pt={{ xs: 86, md: 50 }} pb={40} px={{ xs: 6, md: 0 }}>
               <Grid container alignItems='center' justifyContent='space-around'>
                  <Grid item md='5'>
                     <Grid container justifyContent='center'>
                        <img src={ImageClientes} alt='Clientes e Parceiros da CWA DIgital' width='100%' />
                     </Grid>
                  </Grid>
                  <Grid item md='3' >
                     <Box color='white' mt={{ xs: 12, md: 4 }} mb={4} pl={0} textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography variant='h4' className={classes.white}>
                           Pronto para impulsionar suas vendas?
                        </Typography>
                     </Box>
                     <Box color='white' mb={4} pl={0} pr={0} textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography variant='body2'>
                           Fale agora com um dos nossos especialistas sobre seu negócio.
                        </Typography>
                     </Box>
                     <Box mb={4} pl={0} pr={0} textAlign={{ xs: 'center', md: 'left' }}>
                        <Button
                           variant='container'
                           className={classes.button}
                           href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>
                           Eu quero
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>

         {/* Bloco 7 - Blog */}
         <Box mt={{ xs: 8, md: 16 }} mb={32} px={0}>
            <Grid container justifyContent='space-between'>
               <Grid item md='12'>
                  <CarouselBlog />
               </Grid>
            </Grid>
         </Box>

         <Footer />

      </div>
   );
}

export default Home;