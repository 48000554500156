import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   box: {
      backgroundColor: '#13151E',
      borderRadius: '4px',
      position: 'relative',
      height: '100%',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
   },
   white: {
      color: theme.palette.common.white,
   },
   icon: {
      color: theme.palette.primary.main,
      fontSize: '1rem',
   },
}));

function Card(props) {
   const classes = useStyles();
   return (
      <Grid item xs={12} md={props.col} className={props.customizeBox}>
         <Grid container className={classes.box}>
            <Box p={8} textAlign='left'>
               <Box>
                  <Typography variant='h5' className={props.classHighlighter}>
                     <Grid container alignItems='center'>
                        <Box display='flex' pr={4}>{props.icon}</Box>
                        <Box display='flex'>
                           {props.text}
                        </Box>
                     </Grid>
                  </Typography>
               </Box>
               <Box pt={3}>
                  <Typography variant='body2' className={classes.white}>{props.text2}</Typography>
               </Box>
               <Box className={props.lineBorder}>&nbsp;</Box>
            </Box>
         </Grid>
      </Grid>
   );
}

export default Card;