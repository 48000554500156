import React from 'react';
import { makeStyles, Container, IconButton, Link, Typography,  Grid, Box } from '@material-ui/core';
import { Instagram, LinkedIn, WhatsApp } from '@material-ui/icons';

import Logo from '../assets/logo.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      height: '100vh',
   },
   appBar: {
      boxShadow: 'none',
      backgroundColor: '#000413',
      padding: '6rem 0 3rem 0',
      position: 'static',
   },
   grow: {
      flexGrow: 1,
   },
   icons: {
      paddingRight: theme.spacing(0),
      color: 'white',
      fontSize: '1.25rem',
   },
   buttonOutline: {
      marginRight: theme.spacing(8),
      padding: '0.5rem 1.5rem',
      border: '1px solid white',
   },
   buttonLabel: {
      color: 'white',
      textTransform: 'none',
      fontWeight: 'bold',
   },
   white: {
      color: theme.palette.common.white,
   },
}));

function Footer() {
   const classes = useStyles();
   return (
      <div color='inherit' className={classes.appBar}>
         <Container>
            <Box textAlign={{ xs: 'center', md: 'left' }}>
               <Grid container justifyContent='space-between' alignItems='center' alignContent='center'>
                  <Grid item xs={12} md={2}>
                     <Box mb={{ xs: 8, md: 0 }}><Link href='/'><img src={Logo} alt='Logo' /></Link></Box>
                  </Grid>
                  <Grid container xs={12} md={8} justifyContent='center'>
                     <Typography variant='span' className={classes.white}>
                        © 2023 CWA Digital - Todos os direitos reservados
                     </Typography>
                  </Grid>
                  <Grid container xs={12} md={2} justifyContent={{ xs: 'center', md: 'flex-end' }}>
                     <Box display={'block'} mx={'auto'}>
                        <IconButton href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!' target='_blank' className={classes.icons}>
                           <WhatsApp />
                        </IconButton>
                        <IconButton href='https://www.instagram.com/cwa.digital/' target='_blank' className={classes.icons}>
                           <Instagram />
                        </IconButton>
                        <IconButton href='https://www.linkedin.com/company/cwadigital/' target='_blank' className={classes.icons}>
                           <LinkedIn />
                        </IconButton>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>
      </div>
   );
}

export default Footer;