import React from 'react';
import Lottie from "lottie-react";
import { makeStyles, Container, Typography, Grid, Box, Button } from '@material-ui/core';

import TopBar from '../components/TopBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CardBorder from '../components/CardBorder';
// import CardPrice from '../components/CardPrice';

import cwaVector from '../assets/cwa-vector-orange.webp';
import Bg1 from '../assets/nl-bg.webp';
import Bg2 from '../assets/nl-bg-2.webp';
import Image from '../assets/nl-ilustracao.webp';
import Image2 from '../assets/nl-ilustracao-2.webp';
import ImageSite from '../assets/nl-site.webp';
import ImageGoogle from '../assets/nl-google.webp';
import ImageSociais from '../assets/nl-sociais.webp';
import ImageAds from '../assets/nl-ads.webp';
import ImageDesign from '../assets/nl-design.webp';
import ImageConsultoria from '../assets/nl-consultoria.webp';
import ImageSelo from '../assets/nl-selo-180dias.webp';
import Question from '../assets/animations/question.json';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 0,
      backgroundColor: '#000413',
   },
   section: {
      paddingTop: '4rem',
      position: 'relative',
   },
   primary: {
      color: '#FE6000',
   },
   white: {
      color: theme.palette.common.white,
   },
   appBarColor: {
      background: 'linear-gradient(135deg, rgba(254,96,0,1) 0%, rgba(209,80,4,1) 50%, rgba(187,72,5,1) 75%, rgba(163,63,7,1) 100%);',
   },
   bgVector: {
      backgroundImage: `url(${cwaVector})`,
   },
   bg1: {
      backgroundImage: `url(${Bg1})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center 2rem',
   },
   bg2: {
      backgroundImage: `url(${Bg2})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center 12rem',
   },
   title: {
      fontSize: '2.25rem',
      [theme.breakpoints.down('sm')]: {
         fontSize: '2rem',
      },
   },
   titleFontReverse: {
      textShadow: '-1px -1px 0 #FE6000, 1px -1px 0 #FE6000, -1px 1px 0 #FE6000, 1px 1px 0 #FE6000',
      fontSize: '4rem',
      [theme.breakpoints.down('sm')]: {
         fontSize: '2.5rem',
      },
   },
   button: {
      backgroundColor: '#FE6000',
      color: 'white',
      borderRadius: '4px',
      marginTop: '3rem',
      fontWeight: 'bold',
      padding: '0.75rem 4rem',
      marginRight: 'auto',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#FE6000',
        boxShadow: '0px 6px 30px #FE6000',
      }
    },
   icon: {
      color: '#FE6000',
      fontSize: '1rem',
   },
   question: {
      width: '500px',
      height: '300px',
      margin: 'auto',
      overflow: 'hidden',
      position: 'relative',
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
         height: '170px',
      },
   },
   questionImage: {
      position: 'absolute',
      top: '-7rem',
      [theme.breakpoints.down('sm')]: {
         top: '-5rem',
      },
   },
   lineBorder: {
      background: 'linear-gradient(90deg, #FE6000 0%, rgba(254, 96, 0, 0) 100%)',
      borderRadius: '0px 0px 4px 4px',
      width: '100%',
      height: '4px',
      position: 'absolute',
      left: '0',
      bottom: '0',
   },
}));

function NegociosLocais() {
   const classes = useStyles();
   return (
      <div className={classes.root}>
         <TopBar
            appBarColor={classes.appBarColor}
         />
         <Header
            bgVector={classes.bgVector}
            classTitle={classes.title}
            title='Marketing Digital para'
            classTitleFontReverse={classes.titleFontReverse}
            titleFontReverse='Negócios Locais'
            description='Um projeto desenvolvido para levar a você, micro empresário e 
            profissional liberal, todas as ferramentas e meios fundamentais para fazer seu 
            negócio crescer até 300% em 180 dias com a internet.'
            image={Image}
            imageAlt='Negócios locais'
            button={classes.button}
            buttonLabel='Eu quero'
            linkButton='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20o%20Projeto%20para%20negócios%20locais.'
         />

         {/* Bloco 1 - O que realmente é este projeto? */}
         <Grid container className={`${classes.section} ${classes.bg1}`}>
            <Container>
               <Box pt={20} px={{ xs: 4, md: 8 }} pb={25} textAlign={{ xs: 'left', md: 'center' }}>
                  <Typography variant='body2'>
                     <Box mb={2}>Você deve estar se perguntando:</Box>
                  </Typography>
                  <Typography variant='h3' className={classes.white}>
                     <Box mb={2}>O que realmente é este projeto?</Box>
                  </Typography>
                  <Grid container justifyContent='center' alignItems='center' direction='column'>
                     <Grid item lg={6}>
                        <Typography variant='body2' className={classes.white}>
                           Nós desenvolvemos um método que une as ações que mais geraram resultados para nossos
                           clientes nestes mais de 10 anos de agência a um modelo de ação ágil que visa triplicar
                           seus ganhos em até 180 dias.
                        </Typography>
                     </Grid>
                     <Grid item md={6}>
                        <Box pt={10}>
                           <img src={Image2} alt="Estrutura e tecnologia" width='100%' />
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         {/* Bloco 2 - Como funciona? */}
         <Grid container>
            <Container>
               <Box pt={15} px={{ xs: 4, md: 8 }} pb={16} textAlign={{ xs: 'left', md: 'center' }}>
                  <Typography variant='h3' className={classes.white}>
                     <Box mb={2}>Como funciona?</Box>
                  </Typography>
                  <Grid container>
                     <Grid item lg={3}></Grid>
                     <Grid item lg={6}>
                        <Typography variant='body2' className={classes.white}>
                           Seu negócio terá toda a estrutura e tecnologias necessárias para você obter o máximo que a internet pode lhe oferecer.
                           Do sistema de busca as redes sociais, dos anúncios no Google às campanhas no Facebook.
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
               <Box px={4}>
                  <Grid container justifyContent='space-between' alignItems='stretch' spacing={8}>
                     <CardBorder
                        col='4'
                        number='01'
                        classIcon={classes.icon}
                        classHighlighter={classes.primary}
                        highlighter='Site Institucional'
                        text='Um site rápido, moderno, responsivo e com os pixels do Google e Facebook instalados.'
                        image={ImageSite}
                        imageAlt='Site Versão Desktop e Mobile'
                     />
                     <CardBorder
                        col='4'
                        number='02'
                        classIcon={classes.icon}
                        classHighlighter={classes.primary}
                        highlighter='Google meu negócio'
                        text='Criaremos ou otimizaremos sua ficha
                        para que seus clientes encontrem você sempre que precisarem.'
                        image={ImageGoogle}
                        imageAlt='Seu site visto por todo o mundo'
                     />
                     <CardBorder
                        col='4'
                        number='03'
                        classIcon={classes.icon}
                        classHighlighter={classes.primary}
                        highlighter='Análise de perfil nas Redes Sociais'
                        text='Analisaremos seu perfil e otimizaremos
                        os textos e visual da sua página.'
                        image={ImageSociais}
                        imageAlt='Saiba qual o perfil dos seus clientes'
                     />
                     <CardBorder
                        col='4'
                        number='04'
                        classIcon={classes.icon}
                        classHighlighter={classes.primary}
                        highlighter='Facebook e Google Ads'
                        text='Criaremos as contas de anúncios para seu negócio.'
                        image={ImageAds}
                        imageAlt='Escale suas vendas em até 300%.'
                     />
                     <CardBorder
                        col='4'
                        number='05'
                        classIcon={classes.icon}
                        classHighlighter={classes.primary}
                        highlighter='Consultoria'
                        text='Uma hora para você tirar todas as dúvidas sobre o marketing digital e 
                        entender como essa ferramenta pode transformar seu negócio.'
                        image={ImageDesign}
                        imageAlt='Se comunique de forma eficiente.'
                     />
                     <CardBorder
                        col='4'
                        number='06'
                        classIcon={classes.icon}
                        classHighlighter={classes.primary}
                        highlighter='Design para redes sociais'
                        text='Enviaremos 15 artes em feed e storie para você movimentar suas redes sociais da maneira certa.'
                        image={ImageConsultoria}
                        imageAlt='Pule etapas e dispare na frente.'
                     />
                  </Grid>
               </Box>
            </Container>
         </Grid>

         {/* Bloco 3 - Será que é para mim? */}
         <Grid container>
            <Container>
               <Box pt={35} px={8} pb={5} textAlign='center'>
                  <Typography variant='h3' className={classes.white}>
                     <Box my={6}>"Será que é para mim?"</Box>
                  </Typography>
                  <Box className={classes.question}>
                     <Lottie animationData={Question} className={classes.questionImage} />
                  </Box>
               </Box>
               <Grid container justifyContent='space-between'>
                  <Grid item md={4}>
                     <Typography variant='body2' className={classes.white}>
                        <Box fontWeight='fontWeightBold' textAlign='center' py={{ xs: 4, md: 12 }} px={12}>
                           Se você é um <span className={classes.primary}>comerciante ou um profissional liberal</span> como um dentista ou cabeleireiro, esse projeto é para você.
                        </Box>
                     </Typography>
                  </Grid>
                  <Grid item md={4}>
                     <Typography variant='body2' className={classes.white}>
                        <Box fontWeight='fontWeightBold' textAlign='center' py={{ xs: 4, md: 12 }} px={12}>
                           Se você deseja alavancar seus resultados em até <span className={classes.primary}>300% em 180 dias</span>, esse projeto é para você.
                        </Box>
                     </Typography>
                  </Grid>
                  <Grid item md={4}>
                     <Typography variant='body2' className={classes.white}>
                        <Box fontWeight='fontWeightBold' textAlign='center' py={{ xs: 4, md: 12 }} px={12}>
                           Se você quer <span className={classes.primary}>colocar sua empresa na internet</span> mas não faz ideia de como, esse projeto é para você.
                        </Box>
                     </Typography>
                  </Grid>
               </Grid>
            </Container>
         </Grid>

         {/* Bloco 4 - Projeto Especial... */}
         <Grid container className={`${classes.section} ${classes.bg2}`}>
            <Container>
               <Box pt={25} px={{ xs: 4, md: 8 }} pb={25}>
                  <Grid container justifyContent='center' alignItems='center'>
                     <Grid item md={2}>
                        <Box textAlign='right' px={{ xs: 0, md: 8 }}>
                           <img src={ImageSelo} alt="Selo 180 dias" width='100%' />
                        </Box>
                     </Grid>
                     <Grid item md={6}>
                        <Box pt={{ xs: 8, md: 0 }} px={{ xs: 0, md: 8 }}>
                           <Typography variant='h3' className={classes.white}>
                              <Box mb={2}>Projeto Especial para Pequenas Empresas</Box>
                           </Typography>
                           <Typography variant='body2' className={classes.white}>
                              Agora é a hora de você tirar o seu projeto do papel da maneira mais fácil possível. Adquira agora mesmo!
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid item md={12}>
                        {/* <Grid container justifyContent='center' alignItems='center'> */}
                           <Box item direction='row' justifyContent='center' textAlign='center'>
                              <Button variant='outlined' className={classes.button} href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20o%20Projeto%20para%20negócios%20locais.'>
                                 Eu quero
                              </Button>
                           </Box>
                        {/* </Grid> */}
                     </Grid>
                     {/* <CardPrice
                        col='5'
                        sup=''
                        installments='A partir de 6x'
                        price='R$ 499,00'
                        description='sem juros'
                        button={classes.button}
                        linkButton='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20o%20Projeto%20para%20negócios%20locais.'
                        lineBorder={classes.lineBorder}
                     /> */}
                  </Grid>
               </Box>
            </Container>
         </Grid>

         <Footer />
      </div>

   );
}

export default NegociosLocais;

