import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import { StarOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
   grid: {
      // minHeight: '500px',
   },
   box: {
      border: '2px solid #13151E',
      borderRadius: '4px',
      height: '100%',
   },
   white: {
      color: theme.palette.common.white,
   },
   image: {
      // position: 'absolute',
      // bottom: '0',
   },
}));

function CardBorder(props) {
   const classes = useStyles();
   return (
      <Grid item xs={12} md={props.col}>
         <Grid container className={classes.box}>
            <Grid item className={props.cardBorder}>
               <Box p={7}>
                  <Box display='flex' justifyContent='space-between' className={props.classTop}>
                     <Typography variant='h2' className={classes.white}>{props.number}</Typography>
                     <StarOutline className={props.classIcon} />
                  </Box>
                  <Box>
                     <Typography variant='h6' className={props.classHighlighter}>{props.highlighter}</Typography>
                  </Box>
                  <Box pt={3}>
                     <Typography variant='body2' className={`${classes.white} ${props.classText}`}>{props.text}</Typography>
                  </Box>
               </Box>
            </Grid>
            <Grid container alignItems='flex-end'>
               <img src={props.image} alt={props.imageAlt} className={classes.image} width='100%' />
            </Grid>
         </Grid>
      </Grid>
   );
}

export default CardBorder;