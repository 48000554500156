import React, { useState } from 'react';
import { makeStyles, AppBar, Toolbar, IconButton, Link, Grid, Box } from '@material-ui/core';
import { Instagram, LinkedIn, WhatsApp } from '@material-ui/icons';
// import Menu from './Menu';
import FadeMenu from './FadeMenu';

import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

import Logo from '../assets/logo.webp';

const useStyles = makeStyles((theme) => ({
   appBar: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      padding: '1rem 0',
      zIndex: '99999',
   },
   grow: {
      flexGrow: 1,
   },
   logo: {
      [theme.breakpoints.down('sm')]: {
         width: '100%',
      },
   },
   icons: {
      paddingRight: theme.spacing(0),
      color: 'white',
      fontSize: '1.25rem',
   },
   buttonOutline: {
      marginRight: theme.spacing(8),
      padding: '0.5rem 1.5rem',
      border: '1px solid white',
   },
   buttonLabel: {
      color: 'white',
      textTransform: 'none',
      fontWeight: 'bold',
   },
   slide: {
      backgroundColor: '#000',
   },
}));

function HideOnScroll(props) {
   const { children, window } = props;
   // Note that you normally won't need to set the window ref as useScrollTrigger
   // will default to window.
   // This is only being set here because the demo is in an iframe.
   const trigger = useScrollTrigger({ 
      target: window ? window() : undefined 
   });
 
   return (
      <Slide appear={false} direction="down" in={!trigger}>
         {children}
      </Slide>
   );
}
 
HideOnScroll.propTypes = {
   children: PropTypes.element.isRequired,
   /**
    * Injected by the documentation to work in an iframe.
    * You won't need it on your project.
    */
   window: PropTypes.func,
};

function Header(props) {
   const classes = useStyles();
   const [appbar, setAppbar] = useState(false);
   
   const changeBackground = () => {
      if (window.scrollY >= 300) {
         setAppbar(true);
      } else {
         setAppbar(false);
      }
   };
   window.addEventListener('scroll', changeBackground);

   return (
      <React.Fragment>
         <HideOnScroll {...props} className={classes.slide}>
            <AppBar color='inherit' className={`${classes.appBar} ${appbar ? props.appBarColor : ''}`}>
               <Box mx={{ xs: 4, md: 14 }}>
                  <Toolbar>
                  {/* <Container> */}
                     <Grid container justifyContent='space-between' alignItems='flex-end'>
                        <Grid item xs={3} md={8}>
                           <Link href='/'><img src={Logo} alt='Logo' className={classes.logo} /></Link>
                        </Grid>
                        <Grid item xs={4} md={3}>
                           <Grid container justifyContent='flex-end'>
                              <Box display={{ xs: 'none', md: 'block' }}>
                                 <IconButton href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!' target='_blank' className={classes.icons}>
                                    <WhatsApp />
                                 </IconButton>
                                 {/* <IconButton href='https://facebook.com/cwadigital' target='_blank' className={classes.icons}>
                                    <Facebook />
                                 </IconButton> */}
                                 <IconButton href='https://www.instagram.com/cwa.digital/' target='_blank' className={classes.icons}>
                                    <Instagram />
                                 </IconButton>
                                 <IconButton href='https://www.linkedin.com/company/cwadigital/' target='_blank' className={classes.icons}>
                                    <LinkedIn />
                                 </IconButton>
                              </Box>
                           </Grid>
                        </Grid>
                        <Grid item xs={2} md={1}>
                           <Grid container justifyContent='flex-end'>
                              <FadeMenu />
                              {/* <Menu /> */}
                           </Grid>
                        </Grid>
                     </Grid>
                  {/* </Container> */}
                  </Toolbar>
               </Box>
            </AppBar>
         </HideOnScroll>
      </React.Fragment>
   );
}

export default Header;