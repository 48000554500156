import React from 'react';
// import Lottie from 'lottie-react';
import { Container, Grid, makeStyles, Typography, Box, Button } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Card from '../components/Card';
import CardBorder from '../components/CardBorder';
import CarouselArtes from '../components/CarouselArtes';
import Image from '../assets/rs-redes-sociais.webp';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    backgroundColor: '#000413',
    overflowX: 'hidden',
  },
  section: {
    paddingTop: '4rem',
    position: 'relative',
  },
  appBarColor: {
    background: 'linear-gradient(90deg, #f09433 0%, #dc2743 50%, #cc2366 75%, #bc1888 100%);',
  },
  // bgFooter: {
  //   backgroundImage: `url(${bgFooter})`,
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center 12rem',
  // },
  title: {
    fontSize: '48px',
    lineHeight: '48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '48px',
    },
  },
  titleFontReverse: {
    textShadow: '-1px -1px 0 #f09433, 1px -1px 0 #f09433, -1px 1px 0 #f09433, 1px 1px 0 #f09433',
    fontSize: '3.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  classSubtitle: {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  classBoxText: {
    marginTop: '-20rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  classBoxImage: {
    marginTop: '0rem',
    marginRight: '0rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  classImage: {
    width: '140%',
    marginLeft: '-3rem',
    [theme.breakpoints.down('sm')]: {
      width: '200%',
      marginLeft: '-9rem',
    },
  },
  button: {
    backgroundColor: '#f09433',
    color: 'white',
    borderRadius: '4px',
    marginTop: '3rem',
    fontWeight: 'bold',
    padding: '1rem',
    marginRight: 'auto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#f09433',
      boxShadow: '0px 6px 30px #f09433',
    }
  },
  buttonOutline: {
    border: '2px solid #f09433',
    color: '#f09433',
    borderRadius: '4px',
    marginTop: '3rem',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: '1rem',
    '&:hover': {
      backgroundColor: '#f09433',
      color: 'white',
      boxShadow: '0px 6px 30px #f09433',
    }
  },
  white: {
    color: theme.palette.common.white,
  },
  grey: {
    color: theme.palette.grey.main,
  },
  cardBorder: {
    textAlign: 'center',
    width: '100%',
  },
  highlighter: {
    color: '#f09433',
    marginBottom: '0.5rem',
  },
  highlighter2: {
    color: '#f09433',
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  iconCheck: {
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    marginTop: '-0.3rem',
  },
  imageBorder: {
    maxWidth: '55px',
    maxHeight: '55px',
    padding: '0.65rem 0.85rem',
    color: theme.palette.primary.main,
  },
  imageRocket: {
    position: 'absolute',
    top: '-5rem',
    right: '6rem',
  },
  divider: {
    height: '2px',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    backgroundColor: '#13151E',
  },
  lineBorder: {
    background: 'linear-gradient(90deg, #FF0155 0%, rgba(255, 1, 85, 0) 100%)',
    borderRadius: '0px 0px 4px 4px',
    width: '100%',
    height: '4px',
    position: 'absolute',
    left: '0',
    bottom: '0',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '1rem',
  },
  textGreen: {
    color: '#f09433',
  }
}));

function RedesSociais() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar
        appBarColor={classes.appBarColor}
      />
      <Header
        classTitle={classes.title}
        title='Gestão de'
        classTitleFontReverse={classes.titleFontReverse}
        titleFontReverse='Redes sociais'
        classSubtitle={classes.classSubtitle}
        description='As Redes Sociais pavimentam o caminho entre seu cliente e você.'
        sub='É preciso estar pronto para captar o ativo mais valioso do mundo: a atenção das pessoas.'
        classBoxText={classes.classBoxText}
        image={Image}
        classImage={classes.classImage}
        imageAlt='Redes sociais'
        classBoxImage={classes.classBoxImage}
        button={classes.buttonOutline}
        buttonLabel='Agende uma consultoria gratuita para suas redes sociais'
        linkButton='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20Redes%20sociais.'
      />

      {/* Nossas soluções */}
      <Grid container>
        <Container>
          <Box pt={10} mt={{ xs: 0, lg: 20 }} px={{ xs: 4, md: 8 }} pb={8} textAlign={{ xs: 'left', md: 'center' }}>
            <Typography variant='h3' className={classes.white}>
              <Box mb={2}>Nossas soluções</Box>
            </Typography>
          </Box>
          <Box px={4}>
            <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
              <CardBorder
                col='4'
                number='01'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Tenha uma equipe completa para as redes sociais do seu negócio'
                text='Redator, Diretor de Arte e um Social Media pensando na sua empresa. '
              />
              <CardBorder
                col='4'
                number='02'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Pesquisa e Produção de Conteúdo'
                text='Definimos a persona do seu cliente e definimos estratégias personalizadas para criarmos 
                conteúdos que destaquem seu negócio.'
              />
              <CardBorder
                col='4'
                number='03'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Campanhas e Lançamentos'
                text='Desenvolvemos ações sazonais visando o posicionamento da sua marca e o aumento dos seus resultados.'
              />
              <CardBorder
                col='4'
                number='04'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Análise e Planejamento'
                text='Cada postagem conta, nada é por acaso. Movimento não é nada sem direção.'
              />
              <CardBorder
                col='4'
                number='05'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Estamos onde seu cliente está'
                text='Atuamos em todas as redes sociais e adaptamos seu conteúdo e linguagem para cada meio.'
              />
              <CardBorder
                col='4'
                number='06'
                classIcon={classes.icon}
                classHighlighter={classes.highlighter}
                highlighter='Campanhas que convertem'
                text='Distribuir seus anúncios, ofertas e conteúdos para o público certo, no local e na hora certa, 
                podem multiplicar seu faturamento.'
              />
            </Grid>
            <Box item direction='row' justifyContent='center' textAlign='center'>
              <Button variant='outlined' className={classes.buttonOutline} 
                href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20Tráfego%20pago.'>
                Solicite sua proposta agora mesmo
              </Button>
            </Box>
          </Box>
        </Container>
      </Grid>

      {/* Nós desenvolvemos um método... */}
      <Grid container className={classes.section}>
        <Container>
          <Box pt={25} px={4} pb={10} textAlign={{ xs: 'left', md: 'center' }}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item lg={8}>
                <Typography variant='h4' className={classes.white}>
                  Nós desenvolvemos um método que revolucionou a forma como as marcas se relacionam com pessoas.
                </Typography>
              </Grid>
              <Grid item>
                <Box mt={{ xs: 10, md: 12 }} mb={0}>
                  <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
                    <Card
                      col='4'
                      classHighlighter={classes.highlighter}
                      text='Diagnóstico'
                      text2='Vamos entender qual a posição da sua empresa nas redes sociais e traçar onde queremos chegar.'
                    />
                    <Card
                      col='4'
                      classHighlighter={classes.highlighter}
                      text='Estratégia'
                      text2='Já sabemos onde queremos chegar, agora definiremos quais caminhos mais eficientes para chegarmos lá.'
                    />
                    <Card
                      col='4'
                      classHighlighter={classes.highlighter}
                      text='Planejamento'
                      text2='A estratégia nos dá um mapa, o planejamento nos dá uma rota. 
                      Saber quando e como acelerar é tão importante quanto saber para onde ir.'
                    />
                    <Card
                      col='4'
                      classHighlighter={classes.highlighter}
                      text='Tática'
                      text2='É preciso entender o momento, o conteúdo, os meios e os gatilhos certos para cada objetivo. 
                      É aqui que o jogo começa.'
                    />
                    <Card
                      col='4'
                      classHighlighter={classes.highlighter}
                      text='Execução'
                      text2='Hora de transformar teoria em prática, validar as estratégias e executar as táticas.'
                    />
                    <Card
                      col='4'
                      classHighlighter={classes.highlighter}
                      text='Análise'
                      text2='Toda ação gera transformação. Analisar os resultados de forma clara nos dará as ferramentas 
                      para novas ações de crescimento.'
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid>

      {/* Conheça alguns de nossos trabalhos */}
      <Grid container className={classes.section} justifyContent='center' alignItems='center'>
        <Container>
          <Box pt={25} px={4} pb={10} textAlign='center'>
            <Grid container justifyContent='center' alignItems='center'>
              <Typography variant='h4' className={classes.white}>
                Conheça alguns de nossos trabalhos
              </Typography>
            </Grid>
          </Box>
        </Container>
        <Box width='100%'>
          <CarouselArtes />
        </Box>
        <Box item direction='row' justifyContent='center' textAlign='center' mt={25} mb={16}>
          <Button variant='outlined' className={classes.buttonOutline}
            href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20Tráfego%20pago.'>
            Faça uma cotação agora mesmo
          </Button>
        </Box>
      </Grid>

      {/* Footer */}
      <Footer />

    </div>
  );
}

export default RedesSociais;