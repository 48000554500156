import React from 'react';
import { Grid, makeStyles, Typography, Box, Container, Button } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import CarouselPvn from '../components/CarouselPvn';
import CarouselPvnVideos from '../components/CarouselPvnVideos';

// Images
import CwaVector from '../assets/cwa-vector-white.webp';
import Notebook from '../assets/pvn-note-size.webp';
import Bg from '../assets/port-pvn.webp';
// import LogoAmor from '../assets/pvn-amor.webp';
// import Tablet from '../assets/pvn-tablet.webp';
// import Ecommerce from '../assets/pvn-loja.webp';
import Livro from '../assets/pvn-livro.webp';
import Panfletos from '../assets/pvn-panfletos.webp';
import Flyer from '../assets/pvn-flyer.webp';
import Site from '../assets/pvn-site.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 0,
      backgroundColor: '#000413',
      overflowX: 'hidden',
   },
   section: {
      paddingTop: '4rem',
      position: 'relative',
   },
   primary: {
      color: '#C9A300',
   },
   white: {
      color: theme.palette.common.white,
   },
   appBarColor: {
      background: 'linear-gradient(135deg, rgba(103,74,141,1) 0%, rgba(94,68,130,1) 50%, rgba(90,65,125,1) 75%, rgba(85,61,119,1) 100%);',
   },
   header: {
      background: 'linear-gradient(135deg, rgba(103,74,141,1) 0%, rgba(94,68,130,1) 50%, rgba(90,65,125,1) 75%, rgba(85,61,119,1) 100%);',
      minHeight: '500px',
      [theme.breakpoints.down('sm')]: {
         minHeight: '350px',
      },
   },
   bgVector: {
      backgroundImage: `url(${CwaVector})`,
      minHeight: '500px',
      [theme.breakpoints.down('sm')]: {
         minHeight: '350px',
      },
   },
   buttonOutline: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: '#5533FF',
      borderRadius: '4px',
      padding: '1rem',
      color: '#5533FF',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '1rem',
      '&:hover': {
         backgroundColor: '#5533FF',
         color: 'white',
         boxShadow: '0px 6px 30px #5533FF',
      }
   },
   highlighter: {
      color: '#fff',
      marginBottom: '0.5rem',
   },
   highlighter2: {
      color: '#C9A300',
      fontSize: '2rem',
      marginBottom: '1rem',
   },
   cardBorder: {
      textAlign: 'center',
      width: '100%',
   },
   classIcon: {
      display: 'none',
   },
   customizeBox: {
      height: '120px !important',
   },
   box: {
      backgroundColor: '#fff',
      borderRadius: '4px',
      position: 'relative',
      height: '100%',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      textAlign: 'center',
   },
   h1: {
      fontSize: '2rem',
      lineHeight: '3rem',
   },
   livro: {
      [theme.breakpoints.down('sm')]: {
         width: '50%',
         margin: '3rem auto 0 auto',
         display: 'flex',
      },
   }
}));


export default function BrasilAutomoveis() {
   const classes = useStyles();
   return (
      <div className={classes.root}>
         <TopBar appBarColor={classes.appBarColor} />

         {/* Header */}
         <Box className={`${classes.header}`}>
            <Box className={`${classes.bgVector}`} px={{ xs: 4, md: 8 }} pt={{ xs: 36, md: 48 }}>
               <Container>
                  <Grid container alignItems='center' justifyContent='center' alignContent='center'>
                     <Grid item md='8'>
                        <Box color='white' textAlign={{ xs: 'left', sm: 'center', }} mb={4}>
                           <Typography component='h1' variant='h2' className={`${classes.white} ${classes.h1}`}>
                              Projeto Vida Nova de Vilar dos Teles
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </Box>

         {/* Notebook */}
         <Grid container alignContent='center' justifyContent='center'>
            <Grid item>
               <Box mt={{ xs: -18, md: -48 }} width={{ xs: '100%', md: '1000px' }}>
                  <img src={Notebook} alt='Projeto Vida Nova de Vilar dos Teles' width='100%' />
               </Box>
            </Grid>
         </Grid>

         {/* Sobre o projeto */}
         <Grid container>
            <Container>
               <Box pt={20} px={{ xs: 4, md: 8 }} pb={{ xs: 0, md: 15 }} textAlign='left'>
                  <Grid container alignContent='center' direction='column'>
                     <Grid item md={6}>
                        <Box mb={2}>
                           <Typography component='h2' variant='h4' className={classes.white}>
                              Sobre o projeto
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid item md={6}>
                        <Typography variant='body1' className={classes.white} gutterBottom='true'>
                           <p>O Projeto Vida Nova de Vilar dos Teles é uma de muitas igrejas em todo o Brasil e Mundo do Projeto Vida Nova. Vivenciando um momento de crescimento exponencial, o Projeto Vida Nova de Vilar dos Teles é uma igreja engajada, envolvida e que visa o crescimento nas mais diversas mídias, tendo sempre a palavra de Deus como seu norte.</p>
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         {/* Evento "O Amor jamais acaba"  */}
         <Box mt={12} mb={{ xs: 12, md: 24 }}>
            <img src={Bg} alt='Brasil Automóveis' width='100%' />
         </Box>
         {/* <Box mt={-140} mb={24} p={{ xs: 6, md: 12 }} textAlign='center'>
            <Grid container justifyContent='center'>
               <Grid item md={8}>
                  <Box pb={36} px={8}>
                     <Typography component='h2' variant='h3' className={classes.white}>
                        Evento "O Amor jamais acaba"
                     </Typography>
                  </Box>
               </Grid>
               <Grid item md={12}>
                  <Box px={8}>
                     <Grid container justifyContent='space-around' alignItems='flex-start'>
                        <Grid item md={3}>
                           <img src={LogoAmor} alt='O Amor jamais acaba' width='100%' />
                        </Grid>
                        <Grid item md={8}>
                           <img src={Tablet} alt='O Amor jamais acaba' width='100%' />
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
            </Grid>
         </Box> */}

         {/* Materiais impressos */}
         <Container>
            <Box px={{ xs: 4, md: 8 }} >
               <Grid container className={classes.section} justifyContent='center' alignContent='center' alignItems='center'>
                  <Grid item md={12}>
                     <Box pb={{ xs: 15, md: 15 }} textAlign='left'>
                        <Grid container justifyContent='left' alignContent='center' direction='column'>
                           <Grid item>
                              <Box mb={4}>
                                 <Typography component='h2' variant='h4' className={classes.white}>
                                    Materiais impressos
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>
                     </Box>
                  </Grid>
                  <Grid item md={7}>
                     <img src={Flyer} alt='Projeto Vida Nova de Vilar dos Teles' width='100%' />
                  </Grid>
                  <Grid item md={1}></Grid>
                  <Grid item md={3}>
                     <img src={Livro} alt='Projeto Vida Nova de Vilar dos Teles' width='100%' className={classes.livro} />
                  </Grid>
                  <Grid item md={10}>
                     <Box mt={16}>
                        <img src={Panfletos} alt='Projeto Vida Nova de Vilar dos Teles' width='100%' />
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>

         {/* Artes para redes sociais */}
         <Grid container className={classes.section} justifyContent='center' alignItems='center'>
            <Container>
               <Box pt={{ xs: 18, md: 24 }} px={4} pb={10} textAlign={{ xs: 'left', md: 'center' }}>
                  <Grid container justifyContent='center' alignItems='center'>
                     <Typography component='h2' variant='h4' className={classes.white}>
                        Artes para redes sociais
                     </Typography>
                  </Grid>
               </Box>
            </Container>
            <Box width='100%' mb={{ xs: 18, md: 30 }}>
               <CarouselPvn />
            </Box>
         </Grid>

         {/* Edição de vídeo */}
         <Container>
            <Grid container className={classes.section} justifyContent='center' alignContent='center' alignItems='center'>
               <Grid item md={12}>
                  <Box pt={{ xs: 18, md: 24 }} px={4} pb={10} textAlign={{ xs: 'left', md: 'center' }}>
                     <Grid container justifyContent='center' alignItems='center'>
                        <Typography component='h2' variant='h4' className={classes.white}>
                           Edição de vídeos
                        </Typography>
                     </Grid>
                  </Box>
               </Grid>
               {/* <Grid item md={7}> */}
                  <Box width='100%' mb={{ xs: 18, md: 48 }}>
                     <CarouselPvnVideos />
                  </Box>
               {/* </Grid> */}
            </Grid>
         </Container>

         
         {/* Novo site */}
         <Box pt={{ xs: 30, md: 30 }} textAlign='center'>
            <Grid container justifyContent='center' alignItems='center'>
               <Grid item md={12}>
                  <Typography component='h2' variant='h4' className={classes.white}>
                     <Box mb={16}>Novo site</Box>
                  </Typography>
               </Grid>
               <Grid item md={12}>
                  <img src={Site} alt='Site da Braisl automóveis' width='100%' />
               </Grid>
            </Grid>
         </Box>

         {/* Pronto para impulsionar suas vendas? */}
         <Grid container className={`${classes.section} ${classes.bg1}`}>
            <Container>
               <Box pt={36} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 25 }} textAlign={{ xs: 'left', md: 'center' }}>
                  <Grid container justifyContent='center' alignItems='center' direction='column'>
                     <Grid item md={8}>
                        <Typography variant='h4' className={classes.white}>
                           <Box mb={2}>Pronto para impulsionar suas vendas?</Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={4}>
                        <Typography variant='body2' className={classes.white} gutterBottom='true'>
                           <Box mb={8}>
                              Fale agora com um dos nossos especialistas
                              e nos conte sobre seu negócio.
                           </Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={5}>
                        <Button variant='outlined' className={classes.buttonOutline} href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>Começar agora</Button>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         <Footer />
      </div >
   );
}