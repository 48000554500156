import React from 'react';
import { makeStyles, Grid, Box, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   box: {
      backgroundColor: '#13151E',
      borderRadius: '4px',
      position: 'relative',
   },
   // lineBorder: {
   //    background: 'linear-gradient(90deg, #FF0155 0%, rgba(255, 1, 85, 0) 100%)',
   //    borderRadius: '0px 0px 4px 4px',
   //    width: '100%',
   //    height: '4px',
   //    position: 'absolute',
   //    left: '0',
   //    bottom: '0',
   // },
   white: {
      color: theme.palette.common.white,
   },
   icon: {
      color: theme.palette.primary.main,
      fontSize: '1rem',
   },
   button: {
      padding: '0.75rem 4rem',
      marginTop: '3rem',
      color: 'white',
      borderRadius: '4px',
   },
   buttonLabel: {
      color: 'white',
      textTransform: 'none',
      fontWeight: 'bold',
   },
}));

function CardPrice(props) {
   const classes = useStyles();
   return (
      <Grid item xs={12} md={props.col}>
         <Box my={15} py={12} className={classes.box} textAlign='center'>
            <Box mb={12}>
               <Typography variant='body1' className={classes.white}>{props.sup}</Typography>
            </Box>
            <Box mb={2}>
               <Typography variant='body1' className={classes.white}>{props.installments}</Typography>
            </Box>
            <Box mb={-6}>
               <Typography variant='h2' className={classes.white}>{props.price}</Typography>
            </Box>
            <Box>
               <Typography variant='body1' className={classes.white}>{props.description}</Typography>
            </Box>
            <Button className={props.button} color='secondary' href={props.linkButton}
               classes={{
                  root: classes.button,
                  label: classes.buttonLabel,
               }}>Eu quero
            </Button>
            <Box className={props.lineBorder}>&nbsp;</Box>
         </Box>
      </Grid>
   );
}

export default CardPrice;