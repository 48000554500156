import React from 'react';
import Lottie from 'lottie-react';
import { Container, Grid, makeStyles, Typography, Box, Button } from '@material-ui/core';
import HubspotForm from 'react-hubspot-form';

// Components
import HeaderNoMenu from '../components/HeaderNoMenu';
import Footer from '../components/Footer';
import CardBorder from '../components/CardBorder';

// Images
import BgImage from '../assets/pc-bg.webp';
import BgHeader from '../assets/pc-bg-header.webp';
import ImageHeader from '../assets/pc-header.webp';
import Image8 from '../assets/pc-image-8.webp';
import Piramide from '../assets/pc-piramide-2.webp';
import Fases from '../assets/pc-fases.png';
// import Image7 from '../assets/pc-image-7.webp';

// Icons
import { Icon } from '@iconify/react';
import { ArrowForward, CheckBoxOutlined } from '@material-ui/icons';
import IconSatisfaction from '../assets/icon-satisfaction.webp';
import IconSquad from '../assets/icon-squad.webp';
import IconBank from '../assets/icon-bank.webp';
import IconLeads from '../assets/icon-leads.webp';
import IconSocial from '../assets/icon-social.webp';
import IconAlone from '../assets/icon-alone.webp';
import ImageBorder from '../assets/icon-border.webp';


// Animations
import Laptop from '../assets/animations/laptop.json';
import SocialMedia from '../assets/animations/socialMedia.json';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    backgroundColor: '#000413',
  },
  section: {
    paddingTop: '4rem',
    position: 'relative',
  },
  appBarColor: {
    background: 'linear-gradient(135deg, rgba(240, 201, 0, 1) 0%, rgba(240, 201, 0, 1) 50%, rgba(240, 201, 0, 1) 75%, rgba(240, 201, 0, 1) 100%);',
  },
  bgImage: {
    backgroundImage: `url(${BgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
  title: {
    fontSize: '56px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '46px',
    },
  },
  titleFontReverse: {
    textShadow: '-1px -1px 0 #F0C900, 1px -1px 0 #F0C900, -1px 1px 0 #F0C900, 1px 1px 0 #F0C900',
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
  classBgHeader: {
    position: 'absolute',
    top: '0',
    left: '0',
    // opacity: '0.5',
    backgroundImage: `url(${BgHeader})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% bottom',
    width: '100%',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: '170%',
      backgroundPosition: '70% bottom',
    },
  },
  classSubtitle: {
    fontSize: '1.25rem',
    fontWeight: '400',
    lineHeight: '2rem',
    marginBottom: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  classDescription: {
    fontSize: '1.25rem',
    fontWeight: '700',
    lineHeight: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
  classBoxText: {
    marginTop: '-5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  classBoxImage: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  bold: {
    fontWeight: '700',
  },
  button: {
    backgroundColor: '#F0C900',
    color: 'black',
    borderRadius: '4px',
    marginTop: '3rem',
    fontWeight: 'bold',
    padding: '1rem',
    marginRight: 'auto',
    textTransform: 'none',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#F0C900',
      boxShadow: '0px 6px 30px #F0C900',
    }
  },
  buttonOutline: {
    border: '2px solid #F0C900',
    color: '#F0C900',
    borderRadius: '4px',
    marginTop: '3rem',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: '1rem',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#F0C900',
      color: 'white',
      boxShadow: '0px 6px 30px #F0C900',
    }
  },
  primary: {
    color: '#F0C900',
  },
  white: {
    color: theme.palette.common.white,
  },
  grey: {
    color: theme.palette.grey.main,
  },
  highlighter: {
    color: '#F0C900',
    marginBottom: '0.5rem',
  },
  highlighter2: {
    color: '#F0C900',
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  classTop: {
    display: 'none',
  },
  imageBorder: {
    maxWidth: '55px',
    maxHeight: '55px',
    backgroundImage: `url(${ImageBorder})`,
    padding: '0.65rem 0.85rem',
    color: theme.palette.primary.main,
  },
  divider: {
    height: '2px',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    backgroundColor: '#13151E',
  },
  lineBorder: {
    background: 'linear-gradient(90deg, #FF0155 0%, rgba(255, 1, 85, 0) 100%)',
    borderRadius: '0px 0px 4px 4px',
    width: '100%',
    height: '4px',
    position: 'absolute',
    left: '0',
    bottom: '0',
  },
  textGreen: {
    color: '#F0C900',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '1rem',
  },
  iconArrow: {
    color: '#F0C900',
    fontSize: '1.25rem',
    marginTop: '0',
    marginLeft: '3.3rem',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.2rem',
      marginLeft: '1rem',
    },
  },
  iconCheck: {
    float: 'left',
    color: '#F0C900',
    fontSize: '1.25rem',
    marginTop: '0.1rem',
    marginRight: '0.5rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem',
    }
  },
  iconCheckBig: {
    float: 'left',
    color: '#F0C900',
    fontSize: '1.5rem',
    marginTop: '0rem',
    marginRight: '0rem',
    marginBottom: '1rem',
  },
  iconFases: {
    fontSize: '1.75rem',
    marginTop: '0.15rem',
    marginRight: '0.5rem',
  },
  box: {
    border: '2px solid #13151E',
    borderRadius: '4px',
    height: '100%',
  },
  cardBorderBox: {
    border: '2px solid #13151E',
    borderRadius: '4px',
    height: '100%',
  },
  cardBorder: {
    textAlign: 'center',
    width: '100%',
  },
  card: {
    backgroundColor: '#13151E',
    borderRadius: '4px',
    position: 'relative',
    height: '100%',
    padding: '1rem',
    boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
  },
  cardBig: {
    backgroundColor: '#13151E',
    borderRadius: '4px',
    position: 'relative',
    height: '100%',
    padding: '3rem 2.5rem 3rem 2.5rem',
    boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 1.5rem',
    }
  },
  selo: {
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem'
    }
  },
  image3: {
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  image6: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  hubspot: {
    maxHeight: '570px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '650px',
    },
  },
  base1: {
    color: '#00cdfc',
    fontWeight: '700',
  },
  base2: {
    color: '#1a6fec',
    fontWeight: '700',
  },
  base3: {
    color: '#a574fb',
    fontWeight: '700',
  },
  faseBox: {
    padding: '0 2.75rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fase1: {
    color: '#ED9D00',
  },
  fase2: {
    color: '#D46916',
  },
  fase3: {
    color: '#fb92fc',
  },
  fase4: {
    color: '#a574fb',
  },
  fase5: {
    color: '#1a6fec',
  },
  fase6: {
    color: '#00cdfc',
  },
}));

export default function ProtocoloConcessionarias() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <TopBar
        appBarColor={classes.appBarColor}
      /> */}

      <HeaderNoMenu
        bgAfter={classes.classBgHeader}
        classTitleFontReverse={classes.titleFontReverse}
        titleFontReverse='Protocolo 180x2'
        classSubtitle={classes.classSubtitle}
        subtitle='Dono de Loja de Automóveis,
          O MERCADO MUDOU e você jamais venderá carros como fazia antigamente...'
        classDescription={classes.classDescription}
        description='Mas acredite, com as estratégias, técnicas e ferramentas certas você poderá fazer muito mais.'
        classBoxText={classes.classBoxText}
        image={ImageHeader}
        imageAlt='Tráfego Pago'
        classBoxImage={classes.classBoxImage}
        button={classes.buttonOutline}
        buttonLabel='Quero acelerar as vendas da minha concessionária'
        linkButton='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20o%20Protocolo%20180X2.'
      />

      {/* Olhe para sua loja hoje... */}
      <Grid container>
        <Container>
          <Box px={{ xs: 4, md: 8 }} mt={0} mb={{ xs: 15, md: 20 }} textAlign={{ xs: 'left', md: 'left' }}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item md={12}>
                <Typography variant='h4' className={classes.white}>
                  <Box mb={{ xs: 4, md: 16 }} textAlign={{ xs: 'left', md: 'center' }}>Olhe para sua loja hoje...</Box>
                </Typography>
              </Grid>
              <Grid item md={10}>
                <Grid container justifyContent='center' spacing={6}>
                  <Grid item md={4}>
                    <Box className={classes.card} textAlign='center'>
                      <Box p={3}>
                        <Box mb={3}><img src={IconSatisfaction} width='65px' alt='' /></Box>
                        <Typography variant='body2' className={classes.white}>
                          Você já percebeu que o consumidor está cada vez mais exigente...
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box className={classes.card} textAlign='center'>
                      <Box p={3}>
                        <Box mb={3}><img src={IconSquad} width='65px' alt='' /></Box>
                        <Typography variant='body2' className={classes.white}>
                          Montar e manter uma boa equipe de vendas está cada dia mais desafiador...
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box className={classes.card} textAlign='center'>
                      <Box p={3}>
                        <Box mb={3}><img src={IconBank} width='65px' alt='' /></Box>
                        <Typography variant='body2' className={classes.white}>
                          Os bancos fecharam as torneiras para o financiamento...
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box className={classes.card} textAlign='center'>
                      <Box p={3}>
                        <Box mb={3}><img src={IconLeads} width='65px' alt='' /></Box>
                        <Typography variant='body2' className={classes.white}>
                          Os leads estão cada vez mais caros e menos qualificados...
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box className={classes.card} textAlign='center'>
                      <Box p={3}>
                        <Box mb={3}><img src={IconSocial} width='65px' alt='' /></Box>
                        <Typography variant='body2' className={classes.white}>
                          As redes sociais entregam cada vez menos o seu conteúdo...
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box className={classes.card} textAlign='center'>
                      <Box p={3}>
                        <Box mb={3}><img src={IconAlone} width='65px' alt='' /></Box>
                        <Typography variant='body2' className={classes.white}>
                          E você, empresário, se vê cada dia mais sozinho tentando
                          encontrar as soluções para alavancar seus resultados.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item md={12}>
                <Typography variant='h3' className={classes.primary}>
                  <Box textAlign='center' mt={16}>Mas essa não precisa ser a sua história.</Box>
                </Typography>
              </Grid> */}
            </Grid>
          </Box>
        </Container>
      </Grid>

      {/* Mas essa não precisa ser a sua história. */}
      <Container>
        <Box mt={{ xs: 0, md: 28 }} px={{ xs: 4, md: 8 }} mb={16} textAlign={{ xs: 'left', md: 'center' }}>
          <Typography variant='h3' className={classes.primary}>
            Mas essa não precisa ser a sua história.
          </Typography>
          <Typography variant='body1' className={classes.white}>
            <Box mt={{ xs: 0, md: -4 }} mb={2}>Existe um cenário onde sua concessionária pode ser capaz de:</Box>
          </Typography>
        </Box>
        <Grid container justifyContent='center'>
          <Grid item className={classes.card} xs={11} md={7}>
            <Box px={{ xs: 0, md: 8 }} py={6}>
              <Grid container mb={2} justifyContent='center' alignItems='flex-start' spacing={8}>
                <Grid item xs={9} md={5}>
                  <img src={Piramide} alt='' width='100%' />
                </Grid>
                <Grid item md={6}>
                  <Box px={{ xs: 4, md: 0 }} textAlign={{ xs: 'center', md: 'left' }}>
                    <Grid container alignContent='center' justifyContent='center' direction='column'>
                      <Grid item>
                        <Box mt={{ xs: 0, md: 10 }} ml={{ xs: 0, md: -13 }}>
                          <Typography variant='body1' component='span' className={classes.base1}>
                            Multiplicar suas vendas
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box mt={{ xs: 4, md: 14 }} ml={{ xs: 0, md: -8 }}>
                          <Typography variant='body1' component='span' className={classes.base2}>
                            Organizar seus processos
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={11}>
                        <Box mt={{ xs: 4, md: 15 }} ml={{ xs: 0, md: -2 }}>
                          <Typography variant='body1' component='span' className={classes.base3}>
                            Ter uma equipe comercial forte e uma marca bem posicionada nas redes sociais.
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container mb={2} justifyContent='space-evenly'>
          <Grid item md={6}>
            <Box item direction='row' justifyContent='center' textAlign={{ xs: 'left', md: 'center' }} mt={{ xs: 14, md: 10 }} px={{ xs: 4, md: 0 }}>
              <Typography variant='h5' className={classes.white}>
                Tudo isso enquanto você tem a liberdade para explorar novas possibilidades para seu negócio.
              </Typography>
              {/* <Button
                variant='outlined'
                className={classes.button}
                href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20Tráfego%20pago.'
              >
                Quero aumentar o faturamento da minha concessionária
              </Button> */}
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* E é esse cenário... */}
      <Container>
        <Grid container justifyContent='space-evenly' alignItems='center' spacing={4}>
          <Grid item md={4}>
            <Box mt={{ xs: 16, md: 32 }} px={4} pb={10} textAlign={{ xs: 'left', md: 'left' }}>
              <Grid container justifyContent='center' alignContent='center' alignItems='center' direction='column'>
                <Grid item md={12}>
                  <Box mb={4}>
                    <Typography variant='body1' className={classes.white}>
                      E é esse cenário que nós queremos construir junto com você a partir do...
                      <Box component='em'> Protocolo 180x2, o nosso Programa de Aceleração de Concessionárias. </Box>
                    </Typography>
                  </Box>
                  <Box mb={8}>
                    <Typography variant='h5' className={classes.primary}>
                      São 180 dias de consultoria, imersão e execução das melhores estratégias
                      para duplicarmos os resultados da sua empresa.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  {/* <Box px={8} textAlign={{ xs: 'left', md: 'left' }}> */}
                  <Box mb={8}>
                    <Typography variant='body1' className={classes.white}>
                      Nós vamos trabalhar ao seu lado, fornecendo direção,
                      aceleração e acompanhando de perto todos os <em>indicadores de performance</em> do seu negócio.
                    </Typography>
                  </Box>
                  <Box mb={8}>
                    <Typography variant='body1' className={classes.white}>
                      O nosso Protocolo é um conjunto de práticas e soluções que tem como objetivo multiplicar
                      suas vendas, ajustar sua gestão, criar equipes de vendas envolvidas com sua marca e
                      conquistar uma posição de destaque para sua empresa nas redes sociais.
                    </Typography>
                  </Box>
                  {/* </Box> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={7}>
            <Box mt={{ xs: -18, md: 24 }} mb={{ xs: 12, md: 0 }}>
              <Lottie animationData={Laptop} />
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* O Protocolo 180x2 é perfeito para você que: */}
      <Container>
        <Grid container>
          <Grid item md={12}>
            <Box mt={{ xs: 0, md: 16 }} mb={{ xs: 4, md: 8 }} px={{ xs: 4, md: 8 }} textAlign={{ xs: 'left', md: 'center' }}>
              <Typography variant='body1' className={`${classes.white} ${classes.bold}`}>
                O Protocolo 180x2 é perfeito para você que:
              </Typography>
            </Box>
            <Box px={{ xs: 4, md: 8 }} mb={{ xs: 4, md: 4 }}>
              <Grid container alignContent='center' justifyContent='center' direction='column'>
                <Grid item xs={12} md={5} className={classes.card}>
                  <Box p={4} textAlign='left'>
                    <CheckBoxOutlined className={classes.iconCheck} />
                    <Typography variant='body2' component='span' className={classes.white}>
                      Quer aumentar suas vendas
                    </Typography>
                  </Box>
                  <Box p={4} textAlign='left'>
                    <CheckBoxOutlined className={classes.iconCheck} />
                    <Typography variant='body2' component='span' className={classes.white}>
                      Organizar seus processos
                    </Typography>
                  </Box>
                  <Box p={4} textAlign='left'>
                    <CheckBoxOutlined className={classes.iconCheck} />
                    <Typography variant='body2' component='span' className={classes.white}>
                      Deseja otimizar a qualidade do seu atendimento
                    </Typography>
                  </Box>
                  <Box p={4} textAlign='left'>
                    <CheckBoxOutlined className={classes.iconCheck} />
                    <Typography variant='body2' component='span' className={classes.white}>
                      Quer montar uma equipe de vendas forte e integrada
                    </Typography>
                  </Box>
                  <Box p={4} textAlign='left'>
                    <CheckBoxOutlined className={classes.iconCheck} />
                    <Typography variant='body2' component='span' className={classes.white}>
                      Quer contratar colaboradores alinhados com seus valores
                    </Typography>
                  </Box>
                  <Box p={4} textAlign='left'>
                    <CheckBoxOutlined className={classes.iconCheck} />
                    <Typography variant='body2' component='span' className={classes.white}>
                      Deseja um posicionamento forte na internet
                    </Typography>
                  </Box>
                  <Box p={4} textAlign='left'>
                    <CheckBoxOutlined className={classes.iconCheck} />
                    <Typography variant='body2' component='span' className={classes.white}>
                      Pensa em diversificar seus modelos de receita
                    </Typography>
                  </Box>
                  <Box mb={4} textAlign='center'>
                    <Button
                      variant='outlined'
                      className={classes.buttonOutline}
                      href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20Tráfego%20pago.'
                    >
                      Eu quero fazer parte do Programa de Aceleração
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box px={{ xs: 4, md: 0 }} item direction='row' justifyContent='center' textAlign='center'>

            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Conheça as fases do nosso Programa de Aceleração de Concessionárias */}
      <Box mt={{ xs: 12, md: 48 }} mb={{ xs: 12, md: 24 }}>
        <Grid container>

          {/* Título */}
          <Grid item md={12}>
            <Box px={{ xs: 6, md: 0 }} mb={{ xs: 12, md: 36 }} textAlign={{ xs: 'left', md: 'center' }}>
              <Typography variant='h5' className={classes.white}>
                <Box>Conheça as fases do nosso</Box>
              </Typography>
              <Typography variant='h4' className={classes.white}>
                <Box mb={2}>Programa de Aceleração de Concessionárias</Box>
              </Typography>
              <Typography variant='body2' className={classes.white}>
                <em>Uma metodologia desenvolvida visando gestão, marca e resultados.</em>
              </Typography>
            </Box>
          </Grid>

          {/* Fases 1 a 3 */}
          <Grid container justifyContent='flex-end'>

            {/* Fase 1 */}
            <Grid item md={4} className={`${classes.faseBox} ${classes.fase1}`}>
              <Grid container justifyContent='flex-start'>
                <Grid item>
                  <Icon icon="icon-park-outline:doc-search-two" className={classes.iconFases} />
                </Grid>
                <Grid item>
                  <Typography component='h6' variant='body1' className={classes.bold}>Fase 1<br />OnBoarding</Typography>
                </Grid>
              </Grid>
              <Box mt={3} mb={{ xs: 10, md: 0 }}>
                <Typography variant='body2' className={`${classes.white} ${classes.classText}`}>
                  Conheceremos sua marca, entenderemos seus desafios,
                  levantaremos os indicadores de performance, gestão e
                  marca e daremos o start em nosso workshop co-criativo.
                </Typography>
              </Box>
            </Grid>

            {/* Fase 2 */}
            <Grid item md={3} className={`${classes.faseBox} ${classes.fase2}`}>
              <Grid container justifyContent='flex-start'>
                <Grid item>
                  <Icon icon="tabler:presentation-analytics" className={classes.iconFases} />
                </Grid>
                <Grid item>
                  <Typography component='h6' variant='body1' className={classes.bold}>Fase 2<br />Planejamento e Estratégia</Typography>
                </Grid>
              </Grid>
              <Box mt={4} mb={{ xs: 10, md: 0 }}>
                <Typography variant='body2' className={`${classes.white} ${classes.classText}`}>
                  Apresentaremos nosso plano de ação e
                  apresentaremos a estratégia e assinatura visual
                  que adotaremos em suas mídias.
                </Typography>
              </Box>
            </Grid>

            {/* Fase 3 */}
            <Grid item md={3} className={`${classes.faseBox} ${classes.fase3}`}>
              <Grid container justifyContent='flex-start'>
                <Grid item>
                  <Icon icon="tabler:checklist" className={classes.iconFases} />
                </Grid>
                <Grid item>
                  <Typography component='h6' variant='body1' className={classes.bold}>Fase 3<br />Validação</Typography>
                </Grid>
              </Grid>
              <Box mt={4} mb={{ xs: 10, md: 0 }}>
                <Typography variant='body2' className={`${classes.white} ${classes.classText}`}>
                  Todas as nossas diretrizes de marketing passam
                  por um teste de performance e somente as que atingirem
                  os melhores resultados serão utilizadas no decorrer de
                  nossas ações. A revalidação é feita periodicamente com
                  novos testes para análise.
                </Typography>
              </Box>
            </Grid>

            <Grid item md={1} className={classes.faseBox}></Grid>

          </Grid>

          {/* Caminho */}
          <Grid item md={12}>
            <Box mt={{ xs: 8, md: -14 }} mb={{ xs: -14, md: -56 }}>
              <img src={Fases} width='100%' alt='' />
            </Box>
          </Grid>

          {/* Fases 4 a 6 */}
          <Grid container justifyContent='flex-start'>

            <Grid item md={1} className={classes.faseBox}></Grid>

            {/* Fase 4 */}
            <Grid item md={3} className={`${classes.faseBox} ${classes.fase4}`}>
              <Box mt={{ xs: 0, md: -16 }}>
                <Grid container justifyContent='flex-start'>
                  <Grid item>
                    <Icon icon="eos-icons:content-lifecycle-management" className={classes.iconFases} />
                  </Grid>
                  <Grid item>
                    <Typography component='h6' variant='body1' className={classes.bold}>Fase 4<br />Processos</Typography>
                  </Grid>
                </Grid>
                <Box mt={4} mb={{ xs: 8, md: 0 }}>
                  <Typography variant='body2' className={`${classes.white} ${classes.classText}`}>
                    Analisaremos todos os processos de atendimento ao
                    cliente on e offline. Ajustaremos o que for necessário e
                    desenvolveremos um protocolo de atendimento e treinamento
                    para a equipe visando atingir os maiores índices de NPS.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Fase 5*/}
            <Grid item md={3} className={`${classes.faseBox} ${classes.fase5}`}>
              <Grid container justifyContent='flex-start'>
                <Grid item>
                  <Icon icon="uil:chart-growth" className={classes.iconFases} />
                </Grid>
                <Grid item>
                  <Typography component='h6' variant='body1' className={classes.bold}>Fase 5<br />Gestão</Typography>
                </Grid>
              </Grid>
              <Box mt={4} mb={{ xs: 8, md: 0 }}>
                <Typography variant='body2' className={`${classes.white} ${classes.classText}`}>
                  Nessa fase analisaremos e organizaremos a gestão dos
                  processos, pós venda, nutrição de leads, redes sociais,
                  google business e tráfego pago.
                </Typography>
              </Box>
            </Grid>

            {/* Fase 6 */}
            <Grid item md={3} className={`${classes.faseBox} ${classes.fase6}`}>
              <Box mt={{ xs: 0, md: 8 }}>
                <Grid container justifyContent='flex-start'>
                  <Grid item>
                    <Icon icon="uil:arrow-growth" className={classes.iconFases} />
                  </Grid>
                  <Grid item>
                    <Typography component='h6' variant='body1' className={classes.bold}>Fase 6<br />Escala</Typography>
                  </Grid>
                </Grid>
                <Box mt={4} mb={{ xs: 0, md: 0 }}>
                  <Typography variant='body2' className={`${classes.white} ${classes.classText}`}>
                    Até agora fomos de zero a 100, agora é hora de acelerarmos
                    até onde o motor aguenta, de forma sustentável, segura e fazendo
                    os ajustes necessários.
                  </Typography>
                </Box>
              </Box>
            </Grid>

          </Grid>
        </Grid>
      </Box>

      {/* Você irá receber... */}
      <Grid container>
        <Container>
          <Box px={{ xs: 4, md: 8 }}>
            <Grid container className={classes.section} justifyContent='space-between'>
              <Grid item lg={4}>
                <Box pt={3} px={{ xs: 0, md: 8 }}>
                  <ArrowForward className={classes.icon} />
                  <Typography variant='h4' className={classes.white}>
                    Com o nosso Plano de Aceleração de Concessionárias do Protocolo 180x2 <br /><span className={classes.primary}>você irá receber:</span>
                  </Typography>
                  {/* <img src={Image6} width='100%' alt="Cwa Digital" className={classes.image6} /> */}
                  <Box ml={{ xs: 0, md: -11 }} mt={{ xs: 8, md: 16 }} mb={{ xs: 8, md: 0 }}>
                    <Lottie animationData={SocialMedia} />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={7}>
                <Box className={classes.cardBig}>
                  <Grid container justifyContent='space-between' spacing={8}>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Análise de plataforma com modernização ou reformulação de todo seu site
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Gestão de todas as suas redes sociais - da
                            criação do conteúdo ao design e publicação nos perfis
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Estudo de mercado e definição dos seus cohorts
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Protocolo Instagram para as ações diárias dos stories
                            e atendimento via instagram da sua marca
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Gestão de tráfego
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Design para todos seus materiais impressos
                            institucionais e promocionais
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Produção de ações promocionais para suas redes sociais e anúncios
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container spacing={4} direction="row" justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Copywriting para todos seus materiais promocionais e institucionais
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Branding Content com a definição de missão, visão e valores da sua marca
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Consultoria de Marketing
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Reuniões mensais para análise de resultados e definição de novas ações
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container justifyContent='space-around'>
                        <Grid item xs={1}>
                          <CheckBoxOutlined className={classes.iconCheckBig} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant='body2' className={classes.white}>
                            Automação para pré-atendimento de leads
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid>

      {/* Nosso sucesso em números */}
      <Box mt={{ xs: 24, md: 48 }} mb={{ xs: 12, md: 20 }}>
        <Grid container>

          {/* Título */}
          <Grid item md={12}>
            <Box px={{ xs: 4, md: 0 }} mb={{ xs: 12, md: 12 }} textAlign={{ xs: 'center', md: 'center' }}>
              <Typography variant='h4' className={classes.white}>
                Nosso sucesso em números
              </Typography>
            </Box>
          </Grid>

          <Grid item md={12}>
            <Box px={{ xs: 4, md: 8 }}>
              <Grid container alignContent='center' justifyContent='space-between' spacing={8}>
                <CardBorder
                  col='4'
                  cardBorder={classes.cardBorder}
                  classIcon={classes.classIcon}
                  classHighlighter={classes.highlighter2}
                  highlighter='+ R$ 1.000.000'
                  text='investidos em tráfego'
                />
                <CardBorder
                  col='4'
                  cardBorder={classes.cardBorder}
                  classIcon={classes.classIcon}
                  classHighlighter={classes.highlighter2}
                  highlighter='+ 20 milhões'
                  text='de pessoas impactadas com nossos anúncios.'
                />
                <CardBorder
                  col='4'
                  cardBorder={classes.cardBorder}
                  classIcon={classes.classIcon}
                  classHighlighter={classes.highlighter2}
                  highlighter='+ 70%'
                  text='de aumento em vendas através dos nossos anúncios.'
                />
              </Grid>
            </Box>
          </Grid>

          <Grid item md={12}>
            <Box px={{ xs: 4, md: 8 }}>

            </Box>
          </Grid>

        </Grid>
      </Box>

      {/* Muito mais que um conceito ou um plano... */}
      <Box mt={{ xs: 0, md: 24 }} mb={24} className={`${classes.section}`}>
        <Grid container>
          <Container className={classes.section}>
            <Box px={{ xs: 4 }}>
              <Grid container justifyContent='center' alignItems='flex-start' spacing={8}>
                <Grid item md={2}>
                  <img src={Image8} alt="Cwa Digital" width='' className={classes.selo} />
                </Grid>
                <Grid item md={6}>
                  <Box className={classes.cardBig} pt={0} px={{ xs: 0, md: 16 }}>
                    <Box mb={2}>
                      <Typography variant='h4' className={classes.white}>
                        Muito mais que um conceito ou um plano, nosso Programa de Aceleração
                        <Box component='span' className={`${classes.primary} ${classes.bold}`}> estuda, pensa e executa.</Box>
                      </Typography>
                    </Box>
                    <Typography variant='body2' className={classes.white}>
                      É um setor de marketing, criação e gestão trabalhando para acelerar os resultados do seu negócio.
                    </Typography>
                    <Box className={classes.hubspot} mt={16}>
                      <HubspotForm
                        portalId='24166734'
                        formId='4de6f56d-40d9-4b1b-81ef-427070d5485c'
                        onSubmit={() => console.log('Enviado!')}
                        onReady={(form) => console.log('Formulário pronto!')}
                        loading={<div>Carregando...</div>}
                      />
                      <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
                    </Box>
                    {/* <Button
                      variant='outlined'
                      className={classes.button}
                      href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20Tráfego%20pago.'
                    >
                      <Box component='span' px={16}>Eu quero</Box>
                    </Button> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Grid>
      </Box>

      {/* Footer */}
      <Footer />

    </div >
  );
}