import React from 'react';
import { Grid, makeStyles, Typography, Box, Container, Button } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import CarouselAmparo from '../components/CarouselAmparo';

// Images
import CwaVector from '../assets/cwa-vector-white.webp';
import Notebook from '../assets/amparo-note.webp';
import Bg from '../assets/port-amparo.webp';
import Material1 from '../assets/amparo-material-1.webp';
import Material2 from '../assets/amparo-material-2.webp';
import Material3 from '../assets/amparo-material-3.webp';
import Material4 from '../assets/amparo-material-4.webp';
import Site from '../assets/amparo-site.webp';

// Icons
// import { ThumbUpAlt, Language, PlayCircleFilled, Brush, TrendingUp, Description } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 0,
      backgroundColor: '#000413',
      overflowX: 'hidden',
   },
   section: {
      paddingTop: '4rem',
      position: 'relative',
   },
   primary: {
      color: '#F57B00',
   },
   white: {
      color: theme.palette.common.white,
   },
   appBarColor: {
      background: 'linear-gradient(90deg, rgba(245,123,0,1) 0%, rgba(209,106,3,1) 100%);',
   },
   header: {
      background: 'linear-gradient(90deg, rgba(245,123,0,1) 0%, rgba(209,106,3,1) 100%);',
      minHeight: '500px',
      [theme.breakpoints.down('sm')]: {
         minHeight: '350px',
      },
   },
   bgVector: {
      backgroundImage: `url(${CwaVector})`,
      minHeight: '500px',
      [theme.breakpoints.down('sm')]: {
         minHeight: '350px',
      },
   },
   buttonOutline: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: '#5533FF',
      borderRadius: '4px',
      padding: '1rem',
      color: '#5533FF',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '1rem',
      '&:hover': {
         backgroundColor: '#5533FF',
         color: 'white',
         boxShadow: '0px 6px 30px #5533FF',
      }
   },
   highlighter: {
      color: '#fff',
      marginBottom: '0.5rem',
   },
   highlighter2: {
      color: '#F57B00',
      fontSize: '2rem',
      marginBottom: '1rem',
   },
   cardBorder: {
      textAlign: 'center',
      width: '100%',
   },
   classIcon: {
      display: 'none',
   },
   customizeBox: {
      height: '120px !important',
   },
   box: {
      backgroundColor: '#fff',
      borderRadius: '4px',
      position: 'relative',
      height: '100%',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      textAlign: 'center',
   },
}));


export default function AmparoSeguros() {
   const classes = useStyles();
   return (
      <div className={classes.root}>
         <TopBar appBarColor={classes.appBarColor} />

         {/* Header */}
         <Box className={`${classes.header}`}>
            <Box className={`${classes.bgVector}`} px={{ xs: 4, md: 8 }} pt={{ xs: 36, md: 48 }}>
               <Container>
                  <Grid container alignItems='center' justifyContent='center' alignContent='center'>
                     <Grid item md='8'>
                        <Box color='white' textAlign={{ xs: 'left', sm: 'center', }} mb={4}>
                           <Typography component='h1' variant='h2' className={classes.white}>
                              Amparo Seguros
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </Box>

         {/* Notebook */}
         <Grid container alignContent='center' justifyContent='center'>
            <Grid item>
               <Box mt={{ xs: -32, md: -48 }}>
                  <img src={Notebook} alt='Amparo Seguros' width='100%' />
               </Box>
            </Grid>
         </Grid>

         {/* Sobre o projeto */}
         <Grid container>
            <Container>
               <Box pt={20} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 15 }} textAlign='left'>
                  <Grid container alignContent='center' direction='column'>
                     <Grid item md={6}>
                        <Box mb={2}>
                           <Typography component='h2' variant='h3' className={classes.white}>
                              Sobre o projeto
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid item md={6}>
                        <Typography variant='body1' className={classes.white} gutterBottom='true'>
                           <p>Não é sobre vender qualquer plano, é sobre entender o seu plano de vida e oferecer o melhor para seu lar, sua empresa e seu patrimônio.</p>
                           <p>Aqui na Amparo Consultoria em Seguros nós iremos entender seus sonhos, planos e necessidades para oferecermos os melhores serviços para você, sua empresa e sua família. Totalmente personalizado, feito para você.</p>
                           <p>Desenvolvemos um método único que visa buscar soluções sob medida para você. Uma consultoria em seguros pode mudar os planos da sua vida.</p>
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         {/* Material institucional */}
         <Box mt={{ xs: 24, md: 4 }}>
            <img src={Bg} alt='Amparo Seguros' width='100%' />
         </Box>
         <Box mt={{ xs: 8, md: 16 }} px={{ xs: 2, md: 12 }} textAlign='center'>
            <Grid container className={classes.section} justifyContent='center' alignContent='center'>
               <Grid item md={3}>
                  <img src={Material1} alt='Amparo Seguros' />
               </Grid>
               <Grid item md={3}>
                  <img src={Material2} alt='Amparo Seguros' />
               </Grid>
               <Grid item md={3}>
                  <img src={Material3} alt='Amparo Seguros' />
               </Grid>
               <Grid item md={3}>
                  <img src={Material4} alt='Amparo Seguros' />
               </Grid>
            </Grid>
         </Box>

         {/* Artes para redes sociais */}
         <Grid container className={classes.section} justifyContent='center' alignItems='center'>
            <Container>
               <Box pt={{ xs: 28, md: 48}} px={4} pb={10} textAlign={{ xs: 'left', md: 'center' }}>
                  <Grid container justifyContent='center' alignItems='center'>
                     <Typography component='h2' variant='h3' className={classes.white}>
                        Artes para redes sociais
                     </Typography>
                  </Grid>
               </Box>
            </Container>
            <Box width='100%' mb={{ xs: 18, md: 48 }}>
               <CarouselAmparo />
            </Box>
         </Grid>

         {/* Novo site e Landing Page */}
         <Box pt={{ xs: 30, md: 30 }} textAlign='center'>
            <Grid container justifyContent='center' alignItems='center'>
               <Grid item md={12}>
                  <Typography component='h2' variant='h3' className={classes.white}>
                     <Box mb={16}>Site e Landing Page</Box>
                  </Typography>
               </Grid>
               <Grid item md={12}>
                  <img src={Site} alt='Site da Move Saúde' width='100%' />
               </Grid>
            </Grid>
         </Box>

         {/* Pronto para impulsionar suas vendas? */}
         <Grid container className={`${classes.section} ${classes.bg1}`}>
            <Container>
               <Box pt={18} px={{ xs: 4, md: 8 }} pb={{ xs: 15, md: 25 }} textAlign={{ xs: 'left', md: 'center' }}>
                  <Grid container justifyContent='center' alignItems='center' direction='column'>
                     <Grid item md={8}>
                        <Typography variant='h4' className={classes.white}>
                           <Box mb={2}>Pronto para impulsionar suas vendas?</Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={4}>
                        <Typography variant='body2' className={classes.white} gutterBottom='true'>
                           <Box mb={8}>
                              Fale agora com um dos nossos especialistas
                              e nos conte sobre seu negócio.
                           </Box>
                        </Typography>
                     </Grid>
                     <Grid item lg={5}>
                        <Button variant='outlined' className={classes.buttonOutline} href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>Começar agora</Button>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         <Footer />
      </div >
   );
}