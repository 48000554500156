import React from 'react';
import { makeStyles, withStyles, Button, Menu, MenuItem, Fade, Link, Grid, Box, Typography, IconButton, Container } from '@material-ui/core';
import { MoreVertOutlined, CloseOutlined } from '@material-ui/icons';
import Logo from '../assets/logo.webp';
import { Instagram, LinkedIn, WhatsApp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
   icon: {
      color: 'white',
      fontWeight: '900',
   },
   menu: {
      backgroundColor: '#000',
      opacity: '0.97',
      zIndex: '9999999999 !important',
      position: 'relative',
   },
   menuItem: {
      display: 'inline-block !important',
      textAlign: 'left !important',
   },
   menuItemLink: {
      lineHeight: '4rem',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '800',
      fontSize: '3rem',
      color: theme.palette.primary.dark,
      '&:focus, &:hover': {
         color: theme.palette.common.white,
         textDecoration: 'none',
      },
      [theme.breakpoints.down('sm')]: {
         fontSize: '1.5rem',
         lineHeight: '2.5rem',
      },
   },
   close: {
      cursor: 'pointer',
      position: 'fixed',
      top: '1.5rem !important',
      right: theme.spacing(20),
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
         right: theme.spacing(5),
      },
   },
   closeIcon: {
      fontSize: '3rem',
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
         fontSize: '2rem',
      },
   },
   logo: {
      cursor: 'pointer',
      position: 'fixed',
      top: '2.25rem !important',
      left: theme.spacing(18),
      [theme.breakpoints.down('sm')]: {
         left: theme.spacing(5),
      },
   },
   buttonOutline: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: '#5533FF',
      borderRadius: '4px',
      padding: '1rem',
      color: '#5533FF',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '1rem',
      '&:hover': {
         backgroundColor: '#5533FF',
         color: 'white',
         boxShadow: '0px 6px 30px #5533FF',
      }
   },
   icons: {
      paddingLeft: theme.spacing(0),
      // paddingRight: theme.spacing(0),
      color: 'white',
      fontSize: '1.25rem',
   },
}));

const StyledMenu = withStyles((theme) => ({
   paper: {
      backgroundColor: 'transparent',
      width: '100%', //700px
      height: '100%', //500px
      top: '50vh !important',
      marginTop: '-274px',
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
      [theme.breakpoints.down('sm')]: {
         marginTop: '-200px',
         paddingLeft: theme.spacing(2),
         paddingRight: theme.spacing(2),
      },
   },
}))(Menu);

const StyledMenuItem = withStyles((theme) => ({
   root: {
      '&:focus': {
         backgroundColor: 'none',
         '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.common.white,
         },
      },
   },
}))(MenuItem);

export default function FadeMenu() {
   const classes = useStyles();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <div>
         <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            <MoreVertOutlined fontSize='large' className={classes.icon} />
         </Button>
         <StyledMenu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className={classes.menu}
         >
            <Container>
               <Grid container justifyContent='space-between'>
                  <Grid md={8}>
                     <StyledMenuItem>
                        <Grid container className={classes.menuItem}>
                           <Link className={classes.menuItemLink} href='/'>Home</Link>
                        </Grid>
                     </StyledMenuItem>
                     <StyledMenuItem>
                        <Grid container className={classes.menuItem}>
                           <Link className={classes.menuItemLink} href='/portfolio'>Portfolio</Link>
                        </Grid>
                     </StyledMenuItem>
                     <StyledMenuItem>
                        <Grid container className={classes.menuItem}>
                           <Link className={classes.menuItemLink} href='/negocios-locais'>Negócios Locais</Link>
                        </Grid>
                     </StyledMenuItem>
                     <StyledMenuItem>
                        <Grid container className={classes.menuItem}>
                           <Link className={classes.menuItemLink} href='/redes-sociais'>Gestão de redes sociais</Link>
                        </Grid>
                     </StyledMenuItem>
                     <StyledMenuItem>
                        <Grid container className={classes.menuItem}>
                           <Link className={classes.menuItemLink} href='/trafego-pago'>Gestão de anúncios</Link>
                        </Grid>
                     </StyledMenuItem>
                     <StyledMenuItem>
                        <Grid container className={classes.menuItem}>
                           <Link className={classes.menuItemLink} href='https://blog.cwa.digital/'>Blog</Link>
                        </Grid>
                     </StyledMenuItem>
                  </Grid>
                  <Grid md={3}>
                     <Box display={{ xs: 'none', md: 'block' }}>
                        <Typography variant='body1' className={classes.white} gutterBottom='true'>
                           <p>Atuamos há mais de 10 anos alavancando resultados e transformando marcas através do Marketing, design e tecnologia.</p>
                        </Typography>
                        <Typography variant='body1' className={classes.white} gutterBottom='true'>
                           <p>Este é o momento perfeito para você revolucionar sua marca e resultados através da internet.</p>
                        </Typography>
                        <Box mt={8}>
                           <Button variant='outlined' className={classes.buttonOutline} href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20meu%20negócio.'>Começar agora</Button>
                        </Box>
                     </Box>
                     <Box mt={16} textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography variant='body2' component='h6' className={classes.white} gutterBottom='true'>
                           Fale com a gente
                        </Typography>
                        <Box>
                           <IconButton href='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!' target='_blank' className={classes.icons}>
                              <WhatsApp />
                           </IconButton>
                           <IconButton href='https://www.instagram.com/cwa.digital/' target='_blank' className={classes.icons}>
                              <Instagram />
                           </IconButton>
                           <IconButton href='https://www.linkedin.com/company/cwadigital/' target='_blank' className={classes.icons}>
                              <LinkedIn />
                           </IconButton>
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
            <Link onClick={handleClose} className={classes.close}><CloseOutlined className={classes.closeIcon} /></Link>
            <Link className={classes.logo} href='/'><img src={Logo} alt='Cwa Digital' /></Link>
         </StyledMenu>
      </div >
   );
}