import React from 'react';
import { Container, Grid, makeStyles, Typography, Box, Divider } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Card from '../components/Card';
import CardBorder from '../components/CardBorder';
import CardPrice from '../components/CardPrice';
import CwaVector from '../assets/cwa-vector.webp';
// import BgImage1 from '../assets/lsd-bg.webp';
import BgImage2 from '../assets/lsd-bg-2.webp';
import BgImage3 from '../assets/lsd-bg-3.webp';
import Image from '../assets/lsd-ilustracao.webp';
import ImageUserCel from '../assets/lsd-user-cel.webp';
import ImageRocket from '../assets/lsd-rocket.webp';
import ImageBorder from '../assets/icon-border.webp';
import ImageVagas from '../assets/lsd-30-vagas.webp';
import { ArrowForward, ArrowDownward, CheckBoxOutlined } from '@material-ui/icons';
// import Icon from '@mdi/react'
// import { mdiRocket } from '@mdi/js';

const useStyles = makeStyles(theme => ({
   root: {
      flexGrow: 0,
      backgroundColor: '#000413',
   },
   section: {
      paddingTop: '4rem',
      position: 'relative',
   },
   appBarColor: {
      background: 'linear-gradient(135deg, rgba(255,1,85,1) 0%, rgba(167,2,62,1) 50%, rgba(124,2,51,1) 75%, rgba(78,3,39,1) 100%);',
   },
   bgVector: {
      backgroundImage: `url(${CwaVector})`,
   },
   // bgImage1: {
   //    backgroundImage: `url(${BgImage1})`,
   //    backgroundRepeat: 'no-repeat',
   //    backgroundSize: 'contain',
   //    backgroundPosition: 'center 3rem',
   // },
   bgImage2: {
      backgroundImage: `url(${BgImage2})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
   },
   bgImage3: {
      backgroundImage: `url(${BgImage3})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
   },
   title: {
      fontSize: '56px',
      [theme.breakpoints.down('sm')]: {
         fontSize: '46px',
      },
   },
   titleFontReverse: {
      textShadow: '-1px -1px 0 #FF0155, 1px -1px 0 #FF0155, -1px 1px 0 #FF0155, 1px 1px 0 #FF0155',
   },
   button: {
      boxShadow: '0px 6px 30px rgba(255, 1, 85, 0.6)',
      backgroundColor: '#FF0155',
      '&:hover': {
         backgroundColor: '#FF0155',
         opacity: '0.9'
      }
   },
   white: {
      color: theme.palette.common.white,
   },
   grey: {
      color: theme.palette.grey.main,
   },
   icon: {
      color: theme.palette.primary.main,
      fontSize: '1rem',
   },
   highlighter: {
      color: theme.palette.primary.main,
   },
   iconCheck: {
      color: theme.palette.primary.main,
      fontSize: '2.5rem',
      marginTop: '-0.3rem',
   },
   imageBorder: {
      maxWidth: '55px',
      maxHeight: '55px',
      backgroundImage: `url(${ImageBorder})`,
      padding: '0.65rem 0.85rem',
      color: theme.palette.primary.main,
   },
   imageRocket: {
      position: 'absolute',
      top: '-5rem',
      right: '6rem',
   },
   divider: {
      height: '2px',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      backgroundColor: '#13151E',
   },
   lineBorder: {
      background: 'linear-gradient(90deg, #FF0155 0%, rgba(255, 1, 85, 0) 100%)',
      borderRadius: '0px 0px 4px 4px',
      width: '100%',
      height: '4px',
      position: 'absolute',
      left: '0',
      bottom: '0',
   },
}));

function LancamentoSemDesculpas() {
   const classes = useStyles();
   return (
      <div className={classes.root}>
         <TopBar
            appBarColor={classes.appBarColor}
         />
         <Header
            bgVector={classes.bgVector}
            classTitle={classes.title}
            title='Lançamento'
            classTitleFontReverse={classes.titleFontReverse}
            titleFontReverse='Sem Desculpas'
            description='Tenha uma estrutura completa para você tirar o seu lançamento
            do papel sem precisar se preocupar com desenvolvimento de páginas,
            instalação de pixel, integração ao CRM e arte de anúncios e todos
            os detalhes chatos que envolvem um lançamento.'
            image={Image}
            imageAlt='Lançamento sem desculpas'
            button={classes.button}
            buttonLabel='Eu quero'
            linkButton='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20o%20Lançamento%20sem%20desculpas.'
         />
         
         {/* Bloco 1 - O que oferecemos em nosso pacote? */}
         <Container>
            <Box pt={15} px={{ xs: 4, md: 0 }}>
               <Grid container className={classes.section} justifyContent='space-between' alignItems='stretch' spacing={8}>
                  <Grid item lg={3}>
                     <Typography variant='h4' className={classes.grey}>
                        <Box fontWeight="900" mb={2}>Direto ao ponto</Box>
                     </Typography>
                     <Typography variant='h3' className={classes.white}>O que oferecemos em nosso pacote?</Typography>
                     <ArrowForward className={classes.icon} />
                  </Grid>
                  <CardBorder
                     col='3'
                     classHighlighter={classes.highlighter}
                     classIcon={classes.icon}
                     number='01'
                     highlighter='Uma landing page'
                     text='completa (página principal, confirmação de cadastro e agradecimento).'
                  />
                  <CardBorder
                     col='3'
                     classHighlighter={classes.highlighter}
                     classIcon={classes.icon}
                     number='02'
                     highlighter='Página de venda'
                     text='do seu produto (página de venda, cadastro, compra e página de agradecimento).'
                  />
                  <CardBorder
                     col='3'
                     classHighlighter={classes.highlighter}
                     classIcon={classes.icon}
                     number='03'
                     highlighter='Instalação dos pixels do google e Facebook'
                     text='em todas as páginas. Um item fundamental para a assertividade dos seus anúncios e campanhas de remarketing para futuros lançamentos.'
                  />
                  <CardBorder
                     col='3'
                     classHighlighter={classes.highlighter}
                     classIcon={classes.icon}
                     number='04'
                     highlighter='Integração com o seu CRM'
                     text='Fundamental para sua comunicação e tratamento do lead.'
                  />
                  <CardBorder
                     col='3'
                     classHighlighter={classes.highlighter}
                     classIcon={classes.icon}
                     number='05'
                     highlighter='15 artes em formato de feed e storie'
                     text='para você utilizar em seus anúncios e divulgação.'
                  />
                  <CardBorder
                     col='3'
                     classHighlighter={classes.highlighter}
                     classIcon={classes.icon}
                     number='06'
                     highlighter='Consultoria para o seu lançamento'
                     text='com os nossos especialistas.'
                  />
                  <CardBorder
                     col='3'
                     classHighlighter={classes.highlighter}
                     classIcon={classes.icon}
                     number='07'
                     highlighter='Suporte de 21 dias com total monitoramento do seu site'
                     text='durante o seu lançamento. Da captação à abertura de carrinho.'
                  />
               </Grid>
            </Box>
         </Container>

         {/* Bloco 2 - Você já conhece o caminho... */}
         <Grid container className={classes.section}>
            <Container>
               <Box pt={15} px={{ xs: 4, md: 8 }} pb={10} textAlign={{ xs:'left', md:'center' }}>
                  <Box pb={6}><ArrowDownward className={classes.icon} /></Box>
                  <Typography variant='h3' className={classes.white}>
                     <Box mb={2}>Você já conhece o caminho, nós facilitamos sua jornada</Box>
                  </Typography>
                  <Grid container>
                     <Grid item lg={3}></Grid>
                     <Grid item lg={6}>
                        <Typography variant='body2' className={classes.white}>
                           Todo coprodutor e/ou especialista deseja tirar o seu lançamento do papel e vender seu produto.
                           Mas não é raro nos depararmos com diversos fatores que simplesmente adiam nossos planos e minam nossa energia.
                        </Typography>
                     </Grid>
                  </Grid>
                  <Box mt={{ xs: 8, md: 16 }} mb={8}>
                     <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
                        <Card
                           col='4'
                           text2='Os processos de um lançamento são naturalmente desgastantes. Semanas planejando e produzindo conteúdo, concepção de produto, detalhamento de oferta, anúncios, copy, cadastros, enfim… você sabe do que estou falando.'
                        />
                        <Card
                           col='4'
                           text2='O que eu quero é que sua energia e tempo estejam focados na produção e gestão do seu lançamento. Nada de ter que lidar com diversos freelas que podem deixar você na mão de uma hora para outra, ou ter que gerir processos que simplesmente lhe dão mais dores de cabeça do que soluções.'
                        />
                        <Card
                           col='4'
                           text2='Nossa equipe já atuou e geriu diversos lançamentos com múltiplos 6 em 7. Ofereceremos o que temos de melhor para que o seu lançamento seja mais um dos nossos casos de sucesso.'
                        />
                     </Grid>
                  </Box>
                  <Box my={4}>
                     <Typography variant='h5' className={classes.white}>
                        Lembre-se, o sucesso do nosso cliente <Typography variant='span' color='primary'>é a nossa maior publicidade.</Typography>
                     </Typography>
                  </Box>
               </Box>
            </Container>
         </Grid>

         {/* Bloco 3 - Como vai funcionar? - VERSÂO 2 */}
         <Grid container className={classes.bgImage2}>
            <Container className={classes.section}>
               <Box px={{ xs: 4, md: 0 }}>
                  <img src={ImageRocket} alt='Foguete' className={classes.imageRocket} />
                  <Grid container className={classes.section} justifyContent='space-between' alignItems='stretch' spacing={8}>
                     <Grid item md={12}>
                        <Box pt={{ xs: 16, md: 0 }} px={4} pb={10} textAlign={{ xs:'left', md:'center' }}>
                           <Box pb={6}><ArrowDownward className={classes.icon} /></Box>
                           <Typography variant='h3' className={classes.white}>
                              <Box mb={2}>Como vai funcionar?</Box>
                           </Typography>
                           <Grid container>
                              <Grid item lg={3}></Grid>
                              <Grid item lg={6}>
                                 <Typography variant='body2' className={classes.white}>
                                    Esse produto tem duas missões primordiais: entregar uma estrutura profissional para o seu lançamento e te dar a 
                                    tranquilidade para que você se dedique aos processos do seu lançamento. Assim sendo, desenvolvemos uma operação 
                                    eficiente e direta para a implementação de todo o projeto.
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                     <CardBorder
                        col='3'
                        classHighlighter={classes.highlighter}
                        number='01'
                        classIcon={classes.icon}
                        highlighter='Um especialista falará com você sobre seu Projeto'
                        text='Após você garantir seu produto, nosso especialista irá entrar em contato com você. 
                        Ele intermediará a execução das suas demandas junto à nossa equipe.'
                     />
                     <CardBorder
                        col='3'
                        classHighlighter={classes.highlighter}
                        number='02'
                        classIcon={classes.icon}
                        highlighter='Organizaremos os conteúdos do seu site'
                        text='Para desenvolvermos suas páginas, solicitaremos os conteúdos do seu site: copy, fotos, vídeos, etc.. 
                        Entregaremos seu site, com os pixels do google e Facebook instalados e 
                        integrado ao seu CRM em até 2 dias úteis após a entrega dos conteúdos.'
                     />
                     <CardBorder
                        col='3'
                        classHighlighter={classes.highlighter}
                        number='03'
                        classIcon={classes.icon}
                        highlighter='Desenvolveremos artes de divulgação e anúncios'
                        text='As artes de divulgação e anúncios são entregues em até 24h uteis após a sua solicitação. 
                        Entregaremos no formato de stories e post. Geralmente entregamos bem antes do prazo estipulado.'
                     />
                     <CardBorder
                        col='3'
                        classHighlighter={classes.highlighter}
                        number='04'
                        classIcon={classes.icon}
                        highlighter='Ficaremos à disposição para te orientar'
                        text='Você poderá tirar dúvidas e até solicitar uma call com nosso especialista a qualquer momento. 
                        Nosso objetivo é compartilhar nossa experiência de 10 anos como agência e de inúmeros lançamentos executados.'
                     />
                  </Grid>
               </Box>
            </Container>
         </Grid>
      
         {/* Bloco 4 - Não sei se é pra mim... */}
         <Grid container className={classes.section}>
            <Container>
               <Box pt={15} px={4} pb={10} textAlign={{ xs:'left', md:'center' }}>
                  <Typography variant='body2'>
                     <Box mb={2}>Talvez você possa se perguntar…</Box>
                  </Typography>
                  <Typography variant='h3' className={classes.white}>
                     <Box mb={2}>"Não sei se é para mim. Nunca fiz um lançamento"</Box>
                  </Typography>
                  <Grid container>
                     <Grid item lg={3}></Grid>
                     <Grid item lg={6}>
                        <Typography variant='body2' className={classes.white}>
                           Acredite, o Lançamento sem Desculpas é perfeito para você. Entendemos que executar o primeiro lançamento é um 
                           processo envolto de inseguranças e descobertas. Estaremos ao seu lado para clarear seus caminhos e facilitar sua jornada.
                        </Typography>
                     </Grid>
                  </Grid>
                  <Box mt={{ xs: 10, md: 16 }} mb={8}>
                     <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
                        <Card
                           col='4'
                           classHighlighter={classes.highlighter}
                           text='Eu não tenho um CRM, vocês me ajudam nisso?'
                           text2='Sim! Temos uma boa experiência com alguns CRMs e podemos indicar as mais eficazes plataformas, sejam
                           elas pagas ou gratuitas.'
                        />
                        <Card
                           col='4'
                           classHighlighter={classes.highlighter}
                           text='Eu não vou lançar agora, posso comprar o Lançamento sem Desculpas?'
                           text2='Sim! Após comprar o produto você tem até 6 meses para executar seu lançamento. Poderíamos até prolongar o prazo para um ou dois anos. 
                           Mas nossa intenção primária é que você tire o seu lançamento do papel. Oferecermos todo o necessário para isso.'
                        />
                        <Card
                           col='4'
                           classHighlighter={classes.highlighter}
                           text='E se acontecer algum imprevisto com meu lançamento?'
                           text2='Nada muda. Você continuara tendo até
                           6 meses para utilizar nossa estrutura e ajustar os pontos para executar o lançamento da forma que preferir.'
                        />
                     </Grid>
                  </Box>
               </Box>
            </Container>
         </Grid>

         {/* Bloco 5 - Ok, agora vamos aos negócios? */}
         <Grid container className={`${classes.section} ${classes.bgImage3}`}>
            <Container>
               <Box px={{ xs: 4, md: 8 }}>
                  <Grid container className={classes.section} justifyContent='space-between'>
                     <Grid item lg={4}>
                        <Box pt={3} px={{ xs: 0, md: 8 }}>
                           <ArrowForward className={classes.icon} />
                           <Typography variant='h3' className={classes.white}>Ok, agora vamos aos negócios?</Typography>
                           <Typography variant='body2' className={classes.white}>
                              A minha proposta é bem direta - eu vou te oferecer absolutamente TUDO o que você precisa para estruturar o seu lançamento. Sem desculpas. Sem enrolação. Simples assim.
                           </Typography>
                           <img src={ImageUserCel} alt="Usuário usando o celular" width='100%' />
                        </Box>
                     </Grid>
                     <Grid item lg={7}>
                        <Box pt={9} pb={15}>
                           <Typography variant='body2' className={classes.white}>
                              Com o Lançamento sem Desculpas eu vou te oferecer:  
                           </Typography>
                        </Box>
                        <Grid container justifyContent='space-between'>
                           <Grid item lg={5}>
                              <Box mb={12}>
                                 <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                       <CheckBoxOutlined className={classes.iconCheck} />
                                    </Grid>
                                    <Grid item sm>
                                       <Typography variant='h5' className={classes.white}>
                                          Landing page completa
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={12}>
                                 <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                       <CheckBoxOutlined className={classes.iconCheck} />
                                    </Grid>
                                    <Grid item xs={10}>
                                       <Typography variant='h5' className={classes.white}>
                                          Instalação do Pixel do Facebook nas suas páginas
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={12}>
                                 <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                       <CheckBoxOutlined className={classes.iconCheck} />
                                    </Grid>
                                    <Grid item xs={10}>
                                       <Typography variant='h5' className={classes.white}>
                                          Página de venda do seu produto
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={12}>
                                 <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                       <CheckBoxOutlined className={classes.iconCheck} />
                                    </Grid>
                                    <Grid item xs={10}>
                                       <Typography variant='h5' className={classes.white}>
                                          Pack com 15 artes para divulgação e anúncio do seu produto (storie e feed)
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={12}>
                                 <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                       <CheckBoxOutlined className={classes.iconCheck} />
                                    </Grid>
                                    <Grid item xs={10}>
                                       <Typography variant='h5' className={classes.white}>
                                          Alta qualidade de layout das páginas e design das artes
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                           </Grid>
                           <Grid item lg={5}>
                              <Box mb={12}>
                                 <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                       <CheckBoxOutlined className={classes.iconCheck} />
                                    </Grid>
                                    <Grid item xs={10}>
                                       <Typography variant='h5' className={classes.white}>
                                          Instalação do Pixel do Google nas suas páginas
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={12}>
                                 <Grid container spacing={4} direction="row">
                                    <Grid item xs={2}>
                                       <CheckBoxOutlined className={classes.iconCheck} />
                                    </Grid>
                                    <Grid item xs={10}>
                                       <Typography variant='h5' className={classes.white}>
                                          Integração com o CRM da sua escolha
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={12}>
                                 <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                       <CheckBoxOutlined className={classes.iconCheck} />
                                    </Grid>
                                    <Grid item xs={10}>
                                       <Typography variant='h5' className={classes.white}>
                                          Integração com o método de pagamento da sua preferência
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={12}>
                                 <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                       <CheckBoxOutlined className={classes.iconCheck} />
                                    </Grid>
                                    <Grid item xs={10}>
                                       <Typography variant='h5' className={classes.white}>
                                          Consultoria com nosso especialista em coprodução
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>
         
         {/* Bloco 6 - Eu te garanto... */}
         <Grid container>
            <Container className={classes.section}>
               <Box px={{ xs: 4 }}>
                  <Grid container justifyContent='center' alignItems='center'>
                     <Grid item md={3}>
                        <img src={ImageVagas} alt="Total de vagas" width='100%' />
                     </Grid>
                     <Grid item md={6}>
                        <Box pt={0} px={{ md: 16 }}>
                           <Typography variant='h3' className={classes.white}>
                              <Box mb={2}>Eu te garanto que este é o melhor pacote de serviços para lançamentos já feito.</Box>
                           </Typography>
                           <Typography variant='body2' className={classes.white}>
                              Para atendermos ao prazo proposto sem perdermos a qualidade que primamos, limitamos este produto à 30 vagas. 
                              Então agora é a hora de você tirar o seu lançamento do papel da maneira mais fácil possível. 
                           </Typography>
                        </Box>
                     </Grid>
                     <CardPrice
                        col='4'
                        sup='A partir de'
                        price='R$ 1.297,00'
                        description='ou em até 6x de R$ 239,34'
                        button={classes.button}
                        linkButton='https://api.whatsapp.com/send?phone=5521981031917&text=Ol%C3%A1,%20CWA%20Digital!%20Gostaria%20de%20conversar%20sobre%20o%20Lançamento%20sem%20desculpas.'
                        lineBorder={classes.lineBorder}
                     />
                  </Grid>
               </Box>
            </Container>
         </Grid>

         {/* Bloco 7 - Perguntas frequentes */}
         <Box pt={0} px={{ xs: 4, md: 7 }} pb={8}>
            <Divider variant='middle' className={classes.divider} />
            <Grid container className={classes.section} justifyContent='space-between' alignItems='stretch' spacing={8}>
               <Grid item md={6}>
                  <Typography variant='h3' className={classes.white}>
                     <Box px={7} mb={2}>Perguntas Frequentes</Box>
                  </Typography>
               </Grid>
               <Grid item md={4}>
                  <Typography variant='body2' className={classes.white}>
                     <Box px={7} textAlign='left'>
                        Caso tenha outra dúvida, envie um e-mail para 
                        <Typography variant='span' color='primary'> thiago@cwa.digital</Typography>
                     </Box>
                  </Typography>
               </Grid>
               <CardBorder
                  col='4'
                  classHighlighter={classes.highlighter}
                  number=''
                  highlighter='Serve para mim que estou no meio do lançamento?'
                  text='Com certeza! Por termos uma operação e atendimento bem organizados, conseguimos oferecer prazos bem atrativos 
                  que vão fazer o seu lançamento atingir os resultados que deseja.'
               />
               <CardBorder
                  col='4'
                  number=''
                  classHighlighter={classes.highlighter}
                  highlighter='Vocês me dão algum tipo de garantia?'
                  text='Sim! Ao comprar o Lançamento Sem Desculpas nós garantimos a entrega de todos os itens listados dentro do prazo proposto. 
                  Caso contrário, efetuaremos a devolução de 100% do seu dinheiro. Simples assim.'
               />
               <CardBorder
                  col='4'
                  classHighlighter={classes.highlighter}
                  number=''
                  highlighter='As artes de divulgação são feitas no Canva?'
                  text='Não! Nossa equipe de designers utilizam o Adobe Photoshop. 
                  Software padrão para oferecermos os criativos com a melhor qualidade.'
               />    
            </Grid>
         </Box>

         {/* Footer */}
         <Footer />

      </div>
   );
}

export default LancamentoSemDesculpas;