import React from 'react';
import Slider from 'react-slick';
import '../assets/styles/slick.css';
import '../assets/styles/slick-theme.css';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import Lottie from "lottie-react";
import HandSwipe from '../assets/animations/handSwipe.json';

const useStyles = makeStyles((theme) => ({
   white: {
      color: theme.palette.common.white,
   },
   card: {
      borderRadius: '4px',
      backgroundColor: '#13151E',
      minHeight: '550px',
   },
   cardContent: {
      padding: '2rem',
      minHeight: '280px',
   },
   excerpt: {
      color: '#738292',
      paddingTop: '1rem',
   },
   handSwipe: {
      width: '85px',
      marginTop: '2rem',
   },
   boxHeader: {
      [theme.breakpoints.down('sm')]: {
         width: '100vw !important',
      },
   },
   cardHeader: {
      borderRadius: '5px',
      backgroundColor: '#13151E',
      padding: theme.spacing(12),
      minHeight: '550px',
      [theme.breakpoints.down('sm')]: {
         
      },
   },
   boxWrapper: {
      // width: '396px',
      [theme.breakpoints.down('sm')]: {
         width: '100vw !important',
      },
   },
}));

export default function Blog() {

   const classes = useStyles();
   var settings = {
      className: "slider variable-width",
      dots: true,
      arrows: false,
      lazyLoad: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      variableWidth: true,
   };

   return (
      <Slider {...settings}>
               
         <Box color='white' mb={4} pl={{ xs: 4, md: 10 }} pr={{ xs: 4, md: 4 }} className={classes.boxHeader} style={{ width: 500 }}>
            <Card className={classes.cardHeader}>
               <Typography variant='h3' className={classes.white}>
                  {/* Acompanhe as mais relevantes novidades sobre marketing digital em nosso blog. */}
                  Quer saber mais como podemos te ajudar?
                  <Box pt={8}>Leia nossos <br />artigos.</Box>
                  <Box className={classes.handSwipe}>
                     <Lottie animationData={HandSwipe} />
                  </Box>
               </Typography>
            </Card>
         </Box>
         <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://blog.cwa.digital/informacao-e-poder/">
                  <CardMedia
                     component="img"
                     alt="Informação é poder!"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/09/241014138_413174650399456_1915294721300730991_n.jpg"
                     title="Informação é poder!"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        Informação é poder!
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        A forma mais fácil de vender algo é oferecendo o produto certo, na hora certa, para a pessoa certa. O que parece inimaginável para muitos é uma realidade comum para àqueles que trabalham de forma séria o Marketing Digital da sua empresa.
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>
         <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://blog.cwa.digital/design-e-fundamental/">
                  <CardMedia
                     component="img"
                     alt="Design é fundamental"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/09/240502254_571527953978471_6324805236118256926_n.jpg"
                     title="Design é fundamental"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        Design é fundamental
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        Quando você coloca o design em segundo lugar, o seu cliente coloca sua empresa em último. Acredite, o design é um elemento fundamental em cada tomada de decisão do seu cliente, e ignorar esse fato é um erro que você simplesmente não pode cometer.
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>
         {/* <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://blog.cwa.digital/vender-online-e-ter-uma-loja-online-qual-a-diferenca/">
                  <CardMedia
                     component="img"
                     alt="Vender online e ter uma loja online. Qual a diferença?"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/08/236253828_4329753683734376_5852090625474648482.jpg"
                     title="Vender online e ter uma loja online. Qual a diferença?"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        Vender online e ter uma loja online. Qual a diferença?
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        A principal diferença está em onde você quer chegar e quais meios pode usar para facilitar o seu caminho. Calma, vem comigo que no texto abaixo eu explico.
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box> */}
         <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://blog.cwa.digital/trafego-sem-conteudo-e-panfletagem/">
                  <CardMedia
                     component="img"
                     alt="Tráfego sem conteúdo é panfletagem"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/08/236349254_877289503210766_5147659108246567132.jpg"
                     title="Tráfego sem conteúdo é panfletagem"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        Tráfego sem conteúdo é panfletagem
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        Anunciar na internet é quase que fundamental para o crescimento de qualquer empresa, entretanto, é necessário entender que o trabalho se inicia dois passos antes das campanhas começarem a rodar.
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>
         <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://blog.cwa.digital/como-seus-clientes-encontram-voce-na-internet/">
                  <CardMedia
                     component="img"
                     alt="Como seus clientes encontram você na internet?"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/08/235361109_4767928789888038_251210293232529815a.jpg"
                     title="Como seus clientes encontram você na internet?"
                     link="https://blog.cwa.digital/como-seus-clientes-encontram-voce-na-internet/"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                     Como seus clientes encontram você na internet?
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                     Pare, pense e me diga, além do como eles tem um porquê?
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>
         <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://blog.cwa.digital/sua-empresa-e-tao-grande-quanto-voce-mostra-na-internet/">
                  <CardMedia
                     component="img"
                     alt="Sua empresa é tão grande quanto você mostra na internet"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/08/236623797_221040286603722_4632647721300925824_n.jpg"
                     title="Sua empresa é tão grande quanto você mostra na internet"
                     link="https://blog.cwa.digital/sua-empresa-e-tao-grande-quanto-voce-mostra-na-internet/"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        Sua empresa é tão grande quanto você mostra na internet
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        Para o seu cliente pouco importa o número de colaboradores que você possui em uma linda sala ou o faturamento que registrou nos últimos três anos.
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>
         {/* <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://blog.cwa.digital/a-melhor-hora-para-trabalhar-o-digital-da-sua-empresa-e-agora/">
                  <CardMedia
                     component="img"
                     alt="A melhor hora para trabalhar o digital da sua empresa é agora!"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/08/235828679_893905681214176_1863025400986510979_n.jpg"
                     title="A melhor hora para trabalhar o digital da sua empresa é agora!"
                     link="https://blog.cwa.digital/a-melhor-hora-para-trabalhar-o-digital-da-sua-empresa-e-agora/"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        A melhor hora para trabalhar o digital da sua empresa é agora!
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        A internet é fonte de pesquisa, informação e principal meio na tomada de decisão por uma compra. Não sou eu que digo isso, são as pesquisas.
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box> */}
         <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://blog.cwa.digital/transformar-segundos-em-minutos-com-uma-finalidade-clara-e-direta-vender/">
                  <CardMedia
                     component="img"
                     alt="Transformar segundos em minutos com uma finalidade clara e direta: vender!"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/08/239109377_353348126272842_1951192636695232759_n.jpg"
                     title="Transformar segundos em minutos com uma finalidade clara e direta: vender!"
                     link="https://blog.cwa.digital/transformar-segundos-em-minutos-com-uma-finalidade-clara-e-direta-vender/"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        Transformar segundos em minutos com uma finalidade clara e direta: vender!
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        Em meio a um mar de possibilidades existem as que se encaixam perfeitamente com seu negócio, seu objetivo e sua realidade.
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>
         <Box color='white' mb={4} py={5} pl={{ xs: 8, md: 10 }} pr={{ xs: 8, md: 20 }} className={classes.boxHeader} style={{ width: 500 }}>
            <Grid container direction='column' justifyContent='flex-end'>
               <Grid item>
                  <Typography variant='h3' className={classes.white}>
                     {/* Acompanhe as mais relevantes novidades sobre marketing digital em nosso blog. */}
                     Curtiu?
                     <Box pt={8}>
                        Então confira mais em nosso <Link href='https://blog.cwa.digital/' className={classes.primary}>Blog.</Link>
                     </Box>
                  </Typography>
               </Grid>
            </Grid>
         </Box>
         {/* <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="">
                  <CardMedia
                     component="img"
                     alt="Nenhuma mudança é permanente, mas mudar sim!"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/08/e2f273d0-bxz9ulqqzlqhyzbiydwqt9xtu-700x394.jpg"
                     title="Nenhuma mudança é permanente, mas mudar sim!"
                     link="https://blog.cwa.digital/nenhuma-mudanca-e-permanente-mas-mudar-sim/"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                     Nenhuma mudança é permanente, mas mudar sim!
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                     A internet mudou a forma como nos comportamos e interagimos, não há mais volta. Estar conectado é um estado natural do ser humano.
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>
         <Box px={4} className={classes.boxWrapper} style={{ width: 396 }}>
            <Card className={classes.card}>
               <CardActionArea href="">
                  <CardMedia
                     component="img"
                     alt="Se você não anuncia no Google e Facebook, está deixando dinheiro em cima da mesa"
                     height="254"
                     image="https://blog.cwa.digital/wp-content/uploads/2021/08/ads-beverage-black-coffee-33972-700x467.jpg"
                     title="Se você não anuncia no Google e Facebook, está deixando dinheiro em cima da mesa"
                     link="https://blog.cwa.digital/se-voce-nao-anuncia-no-google-e-facebook-esta-deixando-dinheiro-em-cima-da-mesa/"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                     Se você não anuncia no Google e Facebook, está deixando dinheiro em cima da mesa
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                     As vendas e novos clientes são apenas os benefícios primários de uma boa estratégia de comunicação e anúncios.
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box> */}

      </Slider>
   );

}
